import {createRouter,createWebHashHistory} from 'vue-router';
import Home from "@/views/components/Home";
import Login from "@/views/login/userLogin";
import Register from "@/views/register/userRegister";
import Complaint from "@/views/complaint/userComplaint";
import Index from "@/views/index/indexPc";
import HotNews from "@/views/article/hotNews";
import Industry from "@/views/article/industryResearch";
import Investor from "@/views/article/investorEducate";
import ArticleShow from "@/views/article/articleShow";
import Setting from "@/views/users/setting";
import MyComplaint from "@/views/users/myComplaint";
import ComplaintInfo from "@/views/complaint/complaintInfo";
import AboutUS from "@/views/components/common/AboutUS";
import ContactUS from "@/views/components/common/ContactUS";
import ForgetPwd from "@/views/forgetPwd/forgetPwd";
import workOrder from "@/views/workOrders/workOrder";
import webStorage from '@/utils/webStorage.js';

const router = createRouter({
    history: createWebHashHistory(),
    routes:[
        {
            title:"北互金投诉",
            name:"pc",
            path: "/",
            component:Home,
            meta: {type : 'pc'},
            children:[
                {
                  title:"北互金投诉",
                  name: "p_index",
                  path: "/p_index",
                  component: Index,
                  meta: {type : 'pc'},
                },
                {
                  name: "p_hotNews",
                  path: "/p_hotNews",
                  component: HotNews,
                  meta: {type : 'pc'},
                },
                {
                    name: "p_industry",
                    path: "/p_industry",
                    component: Industry,
                    meta: {type : 'pc'},
                },
                {
                    name: "p_investor",
                    path: "/p_investor",
                    component: Investor,
                    meta: {type : 'pc'},
                },
                {
                    name: "p_articleShow",
                    path: "/p_article",
                    component: ArticleShow,
                    meta: {type : 'pc'},
                },
                {
                    name:"p_complaint",
                    path:"/p_complaint",
                    component: Complaint,
                    meta: {type : 'pc'},
                },
                {
                    name:"p_setting",
                    path:'/p_setting',
                    component: Setting,
                    meta: {type : 'pc'},
                },
                {
                    name:"p_myComplaint",
                    path:"/p_myComplaint",
                    component: MyComplaint,
                    meta: {type : 'pc'},
                },
                {
                    name:"p_complaintInfo",
                    path:"/p_complaintInfo",
                    component: ComplaintInfo,
                    meta: {type : 'pc'},
                },
                {
                    name:"p_aboutUS",
                    path:"/p_aboutUS",
                    component: AboutUS,
                    meta: {type : 'pc'},
                },
                {
                    name:"p_contactUS",
                    path:"/p_contactUS",
                    component: ContactUS,
                    meta: {type : 'pc'},
                },
                {
                    name:"p_workOrder",
                    path:"/p_workOrder",
                    component: workOrder,
                    meta:{type:'pc'}
                }
            ]
        },
        {
            title:"忘记密码",
            name:"p_forgetPwd",
            path:"/p_forgetPwd",
            component: ForgetPwd,
            meta:{ type: 'pc'}
        },
        {
            title:"登录",
            name:"p_login",
            path:"/p_login",
            component: Login,
            meta: {type : 'pc'},
        },
        {
            name:"p_register",
            path:"/p_register",
            component: Register,
            meta: {type : 'pc'},
        }
    ]
})
router.beforeEach((to, from, next) => {
    if(!/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(navigator.userAgent) && to.meta.type !=='pc'){
        const routers = router.options.routes.filter(v => v.name === 'pc')[0].children;
        const next_route = routers.filter(v => v.name.split('_')[1] === to.path.split('_')[1])[0];
        if(next_route){
            const path = next_route.path;
            if(path){
                next(path);
            }else{
                next('/p_index')
            }
        }else{
            next('/p_index')
        }
    }
    let needLogin = ['/p_complaint','/p_setting','/p_myComplaint','/p_workOrder']
    if (needLogin.findIndex((v)=>(v===to.path)) !== -1) {
        const token = webStorage.getItem('localStorage','token')
        if (token) {
            next()
        } else {
            next('/p_login');
        }
    }else if(to.path === '/') {
        next('/p_index')
    }else{
        next()
    }

})
export default router
