<template>
    <div class="index-container">
            <div style="background-color: #f8f8f8;">
                <div class="notification el-row">
                    <el-icon size="large"><Promotion/></el-icon>
                    <p>系统公告:</p>
                    <div class="top-slider">
                        <el-carousel height="30px" direction="vertical" :autoplay="true" indicator-position="none">
                            <el-carousel-item v-for="item in notifList" :key="item">
                                <a href="javascript:;" @click="showArticle(item.article_id)"><h3 text="2xl" justify="center">{{ item.title }}</h3></a>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
            <div class="bg-color"/>
            <div class="hot-index">
                <h2 class="name">热点跟踪</h2>
                <ul class="list" >
                    <template v-for="(item,index) in hotnews" :key="item">
                        <li :class="{select : currentIndex === index}"  @mouseover="addActive(index)" @click="showArticle(item.article_id)">
                            <el-image :src="item.thumb">
                                <template #error>
                                    <div class="image-slot">
                                        <el-icon><Picture /></el-icon>
                                    </div>
                                </template>
                            </el-image>
                            <div class="right">
                                <p class="title"><span>曝光台</span>{{item.title}}</p>
                                <p class="info">{{item.infodesc}}</p>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="bg-color"/>
            <div class="complaints-tab" style="margin-bottom: 20px;">
                <el-tabs v-model="tousu_tabs" class="index-tab-tousu"  stretch  @tab-change="handleChange">
                    <el-tab-pane label="最新投诉" name="topnew" >
                        <div class="tousu_con_parent"  >
                            <div class="tousu_con" v-for="item in tousu_new" :key="item" @click="showComplaintInfo(item.complaint_id)">
                            <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                            <a class="box" :href="item.url" target="_blank">
                                <div class="img">
                                    <el-avatar
                                            class="avatar"
                                            shape="circle"
                                            fit="cover"
                                            :size="60"
                                    >
                                        <img :src="item.imgURL" @error="setDefaultUser"/>
                                    </el-avatar>
                                </div>
                                <div class="tit">
                                    <span class="name" v-if="item.is_anon === 1">匿名</span>
                                    <span class="name" v-else>{{item.userName}}</span>
                                    <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                                </div>
                                <h1 class="">{{item.title}}</h1>
                                <p>{{item.desc}}</p>
                            </a>
                            <ul class="list">
                                <li>
                                    <span>[投诉对象]</span>{{item.tousu_target}}
                                </li>
                                <li>
                                    <span>[投诉要求]</span>{{item.tousu_appeal}}
                                </li>
                            </ul>
                            <template v-if="item.is_help === 1">
                                <div class="law-help" >
                                    <span class="font-circle">帮</span>
                                    <span >律师已参与</span>
                                </div>
                            </template>
                            </div>
<!--                            <share-component :Title="aaaaaa" :Url="item.complaint_id"></share-component>-->
                        </div>
                        <div class="loading" v-if="newTabLoading">
                            <span >{{newTabLoadText}}</span>
                        </div>

                    </el-tab-pane>
                    <el-tab-pane label="已回复" name="replied" >
                        <div class="tousu_con_parent">
                            <div class="tousu_con" v-for="item in tousu_replied" :key="item" @click="showComplaintInfo(item.complaint_id)">
                                <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                                <a class="box" :href="item.url" target="_blank">
                                    <div class="img">
                                        <el-avatar
                                                class="avatar"
                                                shape="circle"
                                                fit="cover"
                                                :size="60"
                                        >
                                            <img :src="item.imgURL" @error="setDefaultUser"/>
                                        </el-avatar>
                                    </div>
                                    <div class="tit">
                                        <span class="name" v-if="item.is_anon === 1">匿名</span>
                                        <span class="name" v-else>{{item.userName}}</span>
                                        <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                                    </div>
                                    <h1 class="">{{item.title}}</h1>
                                    <p>{{item.desc}}</p>
                                </a>
                                <ul class="list">
                                    <li>
                                        <span>[投诉对象]</span>{{item.tousu_target}}
                                    </li>
                                    <li>
                                        <span>[投诉要求]</span>{{item.tousu_appeal}}
                                    </li>
                                </ul>
                                <template v-if="item.is_help === 1">
                                    <div class="law-help" >
                                        <span class="font-circle">帮</span>
                                        <span >律师已参与</span>
                                    </div>
                                </template>
                            </div>
<!--                            <share-component :Title="aaaaaa" :Url="item.complaint_id"></share-component>-->
                        </div>
                        <div class="loading" v-if="replyTabLoading">
                            <span >{{replyTabLoadText}}</span>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="已完成" name="finished">
                        <div class="tousu_con_parent" >
                            <div class="tousu_con" v-for="item in tousu_finish" :key="item" @click="showComplaintInfo(item.complaint_id)">
                                <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                                <a class="box" :href="item.url" target="_blank">
                                    <div class="img">
                                        <el-avatar
                                                class="avatar"
                                                shape="circle"
                                                fit="cover"
                                                :size="60"
                                        >
                                            <img :src="item.imgURL" @error="setDefaultUser"/>
                                        </el-avatar>
                                    </div>
                                    <div class="tit">
                                        <span class="name" v-if="item.is_anon === 1">匿名</span>
                                        <span class="name" v-else>{{item.userName}}</span>
                                        <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                                    </div>
                                    <h1 class="">{{item.title}}</h1>
                                    <p>{{item.desc}}</p>
                                </a>
                                <ul class="list">
                                    <li>
                                        <span>[投诉对象]</span>{{item.tousu_target}}
                                    </li>
                                    <li>
                                        <span>[投诉要求]</span>{{item.tousu_appeal}}
                                    </li>
                                </ul>
                                <template v-if="item.is_help === 1">
                                    <div class="law-help" >
                                        <span class="font-circle">帮</span>
                                        <span >律师已参与</span>
                                    </div>
                                </template>
                            </div>
<!--                            <share-component :Title="aaaaaa" :Url="item.complaint_id"></share-component>-->
                        </div>
                        <div class="loading" v-if="finishTabLoading">
                            <span >{{finishTabLoadText}}</span>
                        </div>
                    </el-tab-pane>
                </el-tabs>

            </div>
    </div>
</template>

<script>
    import {complaintList} from '@/api/complaint';
    import {articleList} from '@/api/article';
    import {ElMessage} from 'element-plus';
    import defaultUser from '@/assets/icons/default-user.png';
    import defaultPic from '@/assets/icons/default-pic.png';
    import {ref,onMounted,onUnmounted} from 'vue';
    import {useRouter} from 'vue-router';
    // import ShareComponent from "@/views/components/ShareComponent";
    export default {
        components: {},
        name: "Index_pc",
        data(){
            return{
                currentIndex: 0,
                // notifList:[],
                // hotnews:[],
                // tousu_tabs: "topnew",
                tousu_all:[],
                allTagQueryParams:{
                    page: -1,
                    pageSize: 10,
                    totalPage: 0,
                    queryDataStatus:'1,3,4,5,6'
                },
                tophotDisabled: false,
                // topnewDisabled: false,

            }
        },
        setup(){
            const notifList = ref([]);
            const hotnews = ref([]);
            const tousu_tabs = ref('topnew');
            const router = useRouter();
            const loading = ref(false);
            const loadText = ref('正在加载...');
            const newTabLoading = ref(false);
            const newTabLoadText = ref('正在加载...');
            const tousu_new = ref([]);
            const topnewDisabled = ref(false);
            const newTagQueryParams = ref({
                    page: -1,
                    pageSize: 10,
                    totalPage: 0,
                    queryDataStatus:'1,3,4,5,6'
            });
            const listNewComplaint = () =>{
                newTagQueryParams.value.page += 1;
                if(newTagQueryParams.value.page > newTagQueryParams.value.totalPage){
                    return;
                }
                newTabLoading.value = true;
                setTimeout(()=>{
                    complaintList(newTagQueryParams.value).then(res =>{
                        if(res.status === 200 && res.data.code=== 200001) {
                            let listData = res.data.data.complaintData;
                            let curPageInfo = res.data.data.pageInfo;
                            let len = tousu_new.value.length;

                            if (listData && listData.length === 0 && !len) {
                                newTabLoadText.value = '没有找到相关数据'

                            } else if (listData && listData.length < 10) {
                                newTabLoadText.value = '暂无更多数据';
                                tousu_new.value = tousu_new.value.concat(listData);
                                newTagQueryParams.value.page = curPageInfo.page;
                                newTagQueryParams.value.totalPage = curPageInfo.totalPage;
                                topnewDisabled.value = true;
                            } else {
                                newTabLoading.value = false;
                                tousu_new.value = tousu_new.value.concat(listData)
                                newTagQueryParams.value.page = curPageInfo.page
                                newTagQueryParams.value.totalPage = curPageInfo.totalPage
                                if (newTagQueryParams.value.page === newTagQueryParams.value.totalPage) {
                                    topnewDisabled.value = true
                                }
                            }
                        }
                    }).catch(err =>{
                        ElMessage({message:err,type:'error'})
                    })
                },1000);
            };

            const replyTabLoading = ref(false);
            const replyTabLoadText = ref('正在加载...');
            const finishTabLoading = ref(false);
            const finishTabLoadText = ref('正在加载...');
            const tousu_replied = ref([]);
            const tousu_finish = ref([]);
            const repliedTagQueryParams = ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                queryDataStatus:'4'
            });
            const finishedTagQueryParams =ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                queryDataStatus:'6'
            });
            const repliedDisabled = ref(false);
            const finishedDisabled = ref(false);
            const listReplyComplaint = () =>{
                repliedTagQueryParams.value.page += 1
                if(repliedTagQueryParams.value.page > repliedTagQueryParams.value.totalPage){
                    return
                }
                replyTabLoading.value = true
                complaintList(repliedTagQueryParams.value).then(res =>{
                    if(res.status === 200 && res.data.code=== 200001) {
                        let listData = res.data.data.complaintData
                        let curPageInfo = res.data.data.pageInfo
                        let len = tousu_replied.value.length

                        if (listData && listData.length === 0 && !len) {
                            replyTabLoadText.value = '没有找到相关数据'
                        } else if (listData && listData.length < 10) {
                            replyTabLoadText.value = '暂无更多数据'
                            tousu_replied.value = tousu_replied.value.concat(listData)
                            repliedTagQueryParams.value.page = curPageInfo.page
                            repliedTagQueryParams.value.totalPage = curPageInfo.totalPage
                            repliedDisabled.value = true
                        } else {
                            replyTabLoading.value.value = false
                            tousu_replied.value = tousu_replied.value.concat(listData)
                            repliedTagQueryParams.value.page = curPageInfo.page
                            repliedTagQueryParams.value.totalPage = curPageInfo.totalPage
                            if (repliedTagQueryParams.value.page === repliedTagQueryParams.value.totalPage) {
                                repliedDisabled.value = true
                            }
                        }
                    }
                }).catch(err =>{
                    ElMessage({message:err,type:'error'})
                })
            };
            const listFinishComplaint = () =>{
                finishedTagQueryParams.value.page += 1
                if(finishedTagQueryParams.value.page > finishedTagQueryParams.value.totalPage){
                    return
                }
                finishTabLoading.value = true
                complaintList(finishedTagQueryParams.value).then(res =>{
                    if(res.status === 200 && res.data.code=== 200001) {
                        let listData = res.data.data.complaintData
                        let curPageInfo = res.data.data.pageInfo
                        let len = tousu_finish.value.length

                        if (listData && listData.length === 0 && !len) {
                            finishTabLoadText.value = '没有找到相关数据'
                        } else if (listData && listData.length < 10) {
                            finishTabLoadText.value = '暂无更多数据'
                            tousu_finish.value = tousu_finish.value.concat(listData)
                            finishedTagQueryParams.value.page = curPageInfo.page
                            finishedTagQueryParams.value.totalPage = curPageInfo.totalPage
                            finishedDisabled.value = true
                        } else {
                            finishTabLoading.value = false
                            tousu_finish.value = tousu_finish.value.concat(listData)
                            finishedTagQueryParams.value.page = curPageInfo.page
                            finishedTagQueryParams.value.totalPage = curPageInfo.totalPage
                            if (finishedTagQueryParams.value.page === finishedTagQueryParams.value.totalPage) {
                                finishedDisabled.value = true
                            }
                        }
                    }
                }).catch(err =>{
                    ElMessage({message:err,type:'error'})
                })
            };

            const showComplaintInfo = (val) =>{
                let url = router.resolve({
                    path:'/p_complaintInfo',
                    query:{
                    complaintId : val
                    }
                });
                // const route = this.$router.resolve({
                //     path:'/p_complaintInfo',
                //     query:{
                //         complaintId : val
                //     }
                // });
                window.open(url.href,'_blank')
            };
            const showBanners = () =>{
                articleList({'notice':1,'page':1,'pageSize':5}).then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        notifList.value = res.data.data.articleData
                    }
                })
            };
            const showHotNews = () =>{
                articleList({'hot':1,'page':1,'pageSize':5}).then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        hotnews.value = res.data.data.articleData.slice(0,4)
                    }
                })
            };
            const showArticle = (val) =>{
                let url = router.resolve({path:'/p_article',
                    query:{
                        articleId : val
                    }
                });
                window.open(url.href,'_blank')
            };

            //获取当前可视范围的高度
            const getClientHeight = () => {
                var clientHeight = 0;
                if (document.body.clientHeight && document.documentElement.clientHeight) {
                    clientHeight = Math.min(document.body.clientHeight, document.documentElement.clientHeight)
                } else {
                    clientHeight = Math.max(document.body.clientHeight, document.documentElement.clientHeight)
                }
                return clientHeight
            };
            //获取文档完整的高度
            const getScrollHeight = () => {
                return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight)
            };
            //获取当前滚动条的位置
            const getScrollTop = () => {
                var scrollTop = 0;
                //window.pageYOffset = document.documentElement.scrollTop
                if (document.documentElement && document.documentElement.scrollTop) {
                    scrollTop = document.documentElement.scrollTop
                } else if (document.body) {
                    scrollTop = document.body.scrollTop
                }
                return scrollTop
            };
            const windowScroll = () => {
                //获取三个值
                var scrollTop = getScrollTop()
                var clientHeight = getClientHeight()
                var scrollHeight = getScrollHeight()
                //如果满足公式则，确实到底了
                if(scrollTop+clientHeight == scrollHeight){
                    setTimeout(()=>{
                        if(tousu_tabs.value == "topnew"){
                            listNewComplaint();
                        }else if(tousu_tabs.value  == "replied"){
                            listReplyComplaint();
                        }else if(tousu_tabs.value  == "finished"){
                            listFinishComplaint();
                        }
                    },1000);

                }
            };
            onMounted(()=>{
                tousu_tabs.value = 'topnew';
                window.addEventListener('scroll', windowScroll,true)
            });
            onUnmounted(() =>{
                window.removeEventListener("scroll", windowScroll,true);//销毁滚动事件
            });
            return {notifList,hotnews,tousu_tabs,windowScroll,getScrollTop,getScrollHeight,getClientHeight,showArticle,showHotNews,showBanners,showComplaintInfo,listFinishComplaint,listReplyComplaint,finishedDisabled,repliedDisabled,finishedTagQueryParams,repliedTagQueryParams,tousu_finish,tousu_replied,finishTabLoadText,finishTabLoading,replyTabLoading,replyTabLoadText,loading,loadText,newTabLoading,newTabLoadText,tousu_new,topnewDisabled,newTagQueryParams,listNewComplaint}
        },
        created(){
            this.listNewComplaint();
            this.showBanners();
            this.showHotNews();
            let activeMenuEvent = document.createEvent('CustomEvent');
            activeMenuEvent.initCustomEvent('changeActiveMenu',false,false,'index');
            window.dispatchEvent(activeMenuEvent);
        },
        methods:{
            setDefaultPic(e){
                e.target.src = defaultPic
            },
            setDefaultUser(e){
                e.target.src = defaultUser
            },
            handleChange(val){
                this.tousu_tabs = val
                switch (val) {
                    // case 'tophot':
                    //     if(this.tousu_all.length<=0){
                    //         this.listAllComplaint();
                    //     }
                    //     break;
                    case 'topnew':
                        if(this.tousu_new.length<=0){
                            this.listNewComplaint();
                        }
                        break;
                    case 'replied':
                        if(this.tousu_replied.length<=0){
                            this.listReplyComplaint();
                        }
                        break;
                    case 'finished':
                        if(this.tousu_finish.length<=0){
                            this.listFinishComplaint();
                        }
                        break;
                }
                // this.listAllComplaint()
            },
            addActive(index) {
                this.currentIndex = index;
                // $event.currentTarget.className = 'select'
            },

        },
    }
</script>

<style lang="scss" scoped>
    .defaultImg{
        background: url("@/assets/icons/default-user.png") no-repeat;
    }
    .index-container{
        width: 100%;
        margin: 80px auto;
        background-color: #ffffff;
        /*padding: 2px 10px 0 10px;*/
    }
    .index-container :deep(.el-aside){
        padding: 40px 0;
    }
    .index-container :deep(.el-row){
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
    }
    .notification {
        /*width: 615px;*/
        padding-left: 13px;
        padding-right: 10px;
        height: 30px;
        line-height: 32px;
        border: 1px solid var(--el-color-primary-light-8);
        overflow: hidden;
        background-color: var(--el-color-primary-light-9);
        /*margin-top: 20px;*/
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
    }
    .hot-index{
        /*margin-top: 20px;*/
        display: grid;
        /*justify-content: space-around;*/
        justify-items: start;
        padding: 0 10px 0 10px;
    }
    .hot-index .name {
        height: 24px;
        line-height: 24px;
        padding-left: 9px;
        border-left: 8px solid var(--el-color-primary-light-5);
        font-size: 20px;
        color: #212121;
        font-weight: bold;
        float: left;
    }
    .hot-index .list{
        margin-top:20px;
    }
    .hot-index .list p{
        margin-block-start: 0;
    }
    .hot-index .list .title span {
        display: block;
        position: absolute;
        width: 60px;
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        color: var(--el-color-primary-dark-2);
        border: 1px solid var(--el-color-primary-dark-2);
        border-radius: 2px;
        left: 0;
        top: 5px;
        text-indent: 0px;
        text-align: center;
    }
    .hot-index .list li{
        overflow: hidden;
        /*margin-bottom: 20px;*/
        cursor: pointer;
        margin-bottom: 5px;
    }
    .hot-index .list .right{
        position: relative;
        width: 640px;
        float: left;
    }
    .hot-index .list .select{
        margin-bottom: 15px;
    }
    .hot-index .list .select .right{
        width: 470px;
        height: 100px;
     }
    .hot-index .list .select .title {
        white-space: normal;
    }
    .hot-index .list .title {
        position: relative;
        text-indent: 70px;
        font-size: 20px;
        color: #000000;
        line-height: 30px;
        max-height: 60px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        /*display: flex;*/
        /*justify-content: flex-start;*/
    }

    .hot-index .list .select .info{
        display: block;
    }
    .hot-index .list .info {
        display: none;
        position: absolute;
        font-size: 14px;
        color: #8F8F95;
        line-height: 16px;
        bottom: 0;
    }
    .hot-index .list .el-image{
        display: none;
        float: left;
        padding: 0 5px;
        max-width: 300px;
        max-height: 200px;
        width: 150px;
        height: 100px;
        margin-right: 20px;
    }
    .hot-index .list .select .el-image{
        display: block;
        width: 150px;
    }
    .hot-index .list .image-slot{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: var(--el-fill-color-light);
        color: var(--el-text-color-secondary);
        font-size: 30px;
    }
    :deep(.el-tabs__item){
        font-size: 20px;
    }
    :deep(.el-tabs__active-bar){
        height: 5px;
    }
    .notification .top-slider{
        float: left;
        width: 500px;
        height: 30px;
        overflow: hidden;
        margin-left: 10px;
    }
    .notification :deep(.el-carousel__item h3 ){
        color: #5d5d5d;
        opacity: 0.75;
        line-height: 30px;
        margin: 0;
        text-align: left;
    }
    .bg-color{
        width: 100%;
        height: 10px;
        background-color: #f8f8f8;
        /*margin: 10px auto;*/
    }
    /*.tousu_con:nth-child(even){*/
    /*    background: #f1f1f1;*/
    /*    border-top: 2px dotted #979797;*/
    /*    border-bottom: 2px dotted #979797;*/
    /*}*/
    .complaints-tab{
        padding: 10px;
    }
    .tousu_con {
        /*background: #fff;*/
        position: relative;
        width: 100%;
        cursor: pointer;
        /*padding-bottom: 20px;*/
        padding: 0 0 20px 10px;
        border-bottom: 2px dotted #979797;
    }
    .icon-status {
        position: absolute;
        right: 15px;
        top: 20px;
        background-size: 100%;
        font-size: 14px;
        line-height: 30px;
        color: #fff;
        text-align: center;
    }
    .icontstj{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/daishenhe.png") no-repeat;
        background-size: cover;
    }
    .iconyishenhe{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/yishenhe.png") no-repeat;
        background-size: cover;
    }
    .iconshenheshibai{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/shenheshibai.png") no-repeat;
        background-size: cover;
    }
    .iconyifenpei{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/yifenpei.png") no-repeat;
        background-size: cover;
    }
    .iconDone{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/finished.png") no-repeat;
        background-size: cover;
    }
    .iconAnswer{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/replied.png") no-repeat;
        background-size: cover;
    }

    .iconBuchong{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/yibuchong.png") no-repeat;
        background-size: cover;
    }

    .tousu_con .box {
        display: block;
    }
    a {
        text-decoration: none;
        outline: none;
        color: #333;
    }
    .tousu_con .img {
        width: 60px;
        height: 60px;
        float: left;
        margin: 20px 0;
        border-radius: 50%;
        background-size: 100%;
    }
    .tousu_con .img img {
        border-radius: 100%;
        width: 60px;
        height: 60px;
    }
    .tousu_con .tit {
        overflow: hidden;
        padding: 30px 15px 20px;
        line-height: 22px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    .tousu_con .name {
        font-size: 16px;
        color: #212121;
    }
    .tousu_con .time {
        display: block;
        font-size: 14px;
        color: #84848A;
        margin-top: .04rem;
        line-height: 20px;
    }
    .tousu_con h1 {
        font-size: 20px;
        color: #000;
        line-height: 34px;
        text-align: justify;
        clear: both;
    }
    .tousu_con p {
        width: 640px;
        margin: 10px 0;
        font-size: 14px;
        color: #5D5D5D;
        line-height: 26px;
        text-align: justify;
        -webkit-line-clamp: 4;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .tousu_con .list {
        font-size: 14px;
        color: #5D5D5D;
        line-height: 26px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .tousu_con ul{
        list-style: none;
        padding-inline-start: 0;
    }
    .tousu_con .list span:first-child {
        color: #212121;
        padding-right: 5px;
    }
    .tousu_con .law-help{
        font-size: 14px;
        color: var(--el-color-primary-dark-2);
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }
    .tousu_con .law-help .font-circle{
        width: 23px;
        height: 23px;
        border: 2px solid var(--el-color-primary-dark-2);
        border-radius: 50%;
        margin-right: 5px;
        font-size: 16px;
    }
    .loading span{
        font-size: 12px;
    }

</style>
