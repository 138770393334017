<template>
    <el-tabs v-model="loginData.loginType" class="login-tabs" stretch @tab-change="handleChange">
        <el-tab-pane label="账号密码登录" name="0" >
            <el-form class="loginFrom" :model="loginData" ref="ruleFormUPRef" :rules="formRulesUP">
                <el-form-item prop="username">
                    <el-input size="large" v-model="loginData.username" placeholder="请输入用户名">
                        <template #prefix>
                            <el-icon class="el-input__icon"><UserFilled /></el-icon>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input type="password" size="large" v-model="loginData.password" placeholder="请输入密码">
                        <template #prefix>
                            <el-icon class="el-input__icon"><Unlock /></el-icon>
                        </template>
                    </el-input>
                </el-form-item>
                <!--                    <el-form-item prop="captcha">-->
                <el-row>
                    <el-col :span="16">
                        <el-form-item prop="captcha">
                            <el-input size="large" v-model="loginData.captcha" placeholder="请输入验证码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-image style="height: 38px" :src="captchaUrl"  @click="refresh"/>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item prop="agree">
                            <el-checkbox v-model="loginData.agree">已阅读并同意</el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item>
                            <a href="javascript:;" @click="openfile">《用户协议与隐私政策》</a>
<!--                            <br/><a href="javascript:;" @click="openfile1">《隐私政策》</a>-->
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item>
                    <el-col :span="12">
                        <el-button class="login-btn" type="primary" :loading="loginLoading" :disabled="loginBtnDisabled" @click="onLogin(this.loginData)">登录</el-button>
                    </el-col>
                    <el-col style="display: flex;justify-content: space-around" :span="12">
                        <router-link to="/p_register">立即注册</router-link>
                        <router-link to="/p_forgetPwd">忘记密码</router-link>
                    </el-col>
                </el-form-item>
            </el-form>
        </el-tab-pane>
        <el-tab-pane label="免密登录" name="1">
            <el-form class="loginFrom" ref="ruleFormMRef" :model="loginData" :rules="formRulesM">
                <el-form-item prop="mobile">
                    <el-input size="large" v-model="loginData.mobile" placeholder="请输入手机号" >
                        <template #prefix>
                            <el-icon class="el-input__icon"><Iphone /></el-icon>
                        </template>
                    </el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="16">
                        <el-form-item prop="captcha">
                            <el-input size="large" v-model="loginData.captcha" placeholder="请输入验证码">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-image style="height: 38px" :src="captchaUrl"  @click="refresh"/>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="16">
                        <el-form-item prop="shortMsg">
                            <el-input size="large" v-model="loginData.shortMsg" placeholder="请输入验证码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-button type="primary" size="large" :disabled="smsBtnDis" @click="sendmsg">{{smsBtnContent}}</el-button>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item prop="agree">
                            <el-checkbox v-model="loginData.agree">已阅读并同意</el-checkbox>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item>
                            <a href="javascript:;" @click="openfile">《用户协议与隐私政策》</a>
<!--                            <br/><a href="javascript:;" @click="openfile1">《隐私政策》</a>-->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item>
                    <el-col :span="12">
                        <el-button class="login-btn" type="primary" :loading="loginLoading" :disabled="phoneLoginBtnDisabled" @click="onLogin(loginData)">登录</el-button>
                    </el-col>
                    <el-col  style="display: flex;justify-content: space-around" :span="12">
                        <router-link to="/p_register">立即注册</router-link>
                    </el-col>
                </el-form-item>
            </el-form>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import {ref} from "vue";
    import {ElMessage} from 'element-plus';
    import webStorage from "@/utils/webStorage.js";
    import { getCaptcha ,login,sendmsg,loadUserInfo,checkPhoneRepied} from '@/api/user';

    export default {
        name: "pcLoginComponent",
        props:['redirect'],
        data() {
            return {
                redirectPath:this.redirect,
                agreementDialogVisible:false,
                captchaDisabled: false,
                // agree: false,   //是否同意协议
                ruleFormUPRef:ref(null),
                ruleFormMRef:ref(null),
                captchaUrl: '',
                smsBtnDis: false,
                smsBtnContent: '获取短信',
                timer: null,
                count: '',
                loginData: {
                    username: '',  //用户名
                    password: '',  //密码
                    captcha:'',    //图片验证码
                    hashkey:'',
                    loginType: '0',
                    mobile:'',
                    shortMsg:'',
                    agree: false,
                    requestType:'login'
                },
                formRulesUP: {
                    username: [
                        { required: true, message: '请输入用户名', trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: '请输入密码', trigger: 'blur' },
                    ],
                    captcha: [
                        { required: true, message: '请输入验证码', trigger: 'blur' },
                        { pattern: /^[a-zA-Z]{4}$/, message: '请输入正确的验证码', trigger: 'blur'}
                    ],
                    agree: [
                        {
                            validator: (rule, value, callback) => {
                                if (value) {
                                    // 验证通过
                                    callback()
                                } else {
                                    // 验证失败
                                    callback(new Error('请同意用户协议与隐私政策'))
                                }
                            },
                            trigger: 'blur'
                        }
                    ]
                },
                formRulesM: {
                    mobile: [
                        { required: true, trigger: 'blur' ,validator:(rule, value, callback) =>{
                                var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                                if (!myreg.test(value)) {
                                    callback(new Error('请输入正确的手机号！'));
                                } else {
                                    checkPhoneRepied({mobile:value}).then(res =>{
                                        if(res.status===200 && res.data.code===200001){
                                            callback(new Error("该手机号未绑定或已注销"))
                                        }else{
                                            callback()
                                        }
                                    })
                                }
                            }},
                        // { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
                    ],
                    captcha:[
                        { required: true, message:'图片验证码不能为空', trigger: 'blur'}
                    ],
                    shortMsg: [
                        { required: true, message: '验证码不能为空', trigger: 'blur' },
                        { pattern: /^\d{4}$/, message: '请输入正确的验证码格式', trigger: 'blur' }
                    ],
                    agree: [
                        {
                            validator: (rule, value, callback) => {
                                if (value) {
                                    // 验证通过
                                    callback()
                                } else {
                                    // 验证失败
                                    callback(new Error('请同意用户协议与隐私政策'))
                                }
                            },
                            trigger: 'blur'
                        }
                    ]
                }
            }
        },
        computed: {
            loginBtnDisabled(){
                return !this.loginData.username || !this.loginData.captcha || !this.loginData.password || !this.loginData.agree
            },
            phoneLoginBtnDisabled(){
                return !this.loginData.mobile || !this.loginData.captcha || !this.loginData.shortMsg || !this.loginData.agree
            }

        },
        watch: {},
        created (){
            this.refresh();
        },
        mounted (){
            // window.addEventListener('keydown',keyDown)
        },
        unmounted(){
            // window.removeEventListener('keydown',keyDown,false)
        },

        methods: {
            handleChange(){
                this.loginData.captcha=''
                this.loginData.agree=false
            },
            onLogin(conf){
                let form = this.$refs["ruleFormUPRef"]
                if(conf.loginType === '1'){
                    form = this.$refs["ruleFormMRef"]
                }
                form.validate(valid =>{
                    if(valid){
                        login(conf).then(res => {
                            if ( res.status ===200 && res.data.code===200001){
                                webStorage.setItem('localStorage','token',res.data.data.token,60000*60*2)
                                if(res.data.data.uCompany){
                                    webStorage.setItem('localStorage','uCompany',res.data.data.uCompany,60000*60*2)
                                }
                                loadUserInfo().then(async res1 =>{
                                    if(res1.status === 200 && res1.data.code === 200001){
                                        webStorage.setItem('localStorage','user',JSON.stringify(res1.data.data),60000*60*2)
                                        webStorage.setItem('localStorage','username',res1.data.data.username,60000*60*2)
                                        console.log(this.redirectPath);
                                        this.$emit('loginEnd',this.redirectPath);
                                        // let redirect = decodeURIComponent(this.$route.query.redirect || '/');
                                        // this.$router.push({path:redirect});
                                    }
                                })

                            }else{
                                ElMessage({message: res.data.message,type:'error'})
                            }
                            // this.$router.push({ name: 'home' })
                        }).catch(() => {
                            // 登录失败
                            ElMessage({message:'登录失败', type:'error'})
                            // 关闭 loading
                            this.loginLoading = false
                        })
                    }
                })

            },
            refresh(){
                getCaptcha().then(res => {
                    this.loginData.hashkey=res.data.data.hashkey
                    this.captchaUrl=res.data.data.image_url
                    console.log("captchaUrl:"+res.data.data.image_url)
                }).catch(() => {
                    // 登录失败
                    ElMessage({message:'获取验证码失败', type:'error'})
                    // 关闭 loading
                    this.loginLoading = false
                })
            },
            openfile(){
                window.open('/北京市互联网金融消费者投诉平台用户协议与隐私政策.pdf','用户协议与隐私政策');
            },
            sendmsg(){
                let vm = this
                let reg = /^1[3|5|7|8|9]\d{9}$/
                if(!reg.test(vm.loginData.mobile)){
                    ElMessage({message: "请输入正确的手机号",type:'warning'})
                    return
                }else{
                    checkPhoneRepied({mobile:vm.loginData.mobile}).then(res =>{
                        if(res.status===200 && res.data.code===200001){
                            ElMessage({message: res.data.message,type:'warning'})
                            return
                        }else{
                            if(!this.loginData.captcha){
                                ElMessage({message: "图片验证码不能为空",type:'warning'})
                                return
                            }
                            sendmsg(vm.loginData).then(res =>{
                                if(res.status===200 && res.data.code===200001){
                                    vm.smsBtnDis = true
                                    const TIME_COUNT = 60
                                    vm.count = TIME_COUNT
                                    vm.timer = window.setInterval(() => {
                                        if (vm.count > 0 && vm.count <= TIME_COUNT){
                                            // 倒计时时不可点击
                                            vm.smsBtnDis = true
                                            // 计时秒数
                                            vm.count--
                                            // 更新按钮的文字内容
                                            vm.smsBtnContent = vm.count + 's后重新获取'
                                        } else {
                                            // 倒计时完，可点击
                                            vm.smsBtnDis = false
                                            // 更新按钮文字内容
                                            vm.smsBtnContent = '获取短信'
                                            // 清空定时器!!!
                                            clearInterval(vm.timer)
                                            vm.timer = null
                                        }
                                    }, 1000)
                                }else{
                                    ElMessage({message:res.data.message,type:'error'})
                                }
                            }).catch(() =>{
                                ElMessage({message:'短信验证码发送失败', type:'error'})
                            })
                        }
                    })
                }
            }
        },
    };
    // const keyDown = (e) =>{
    //     if(e.keyCode==13 || e.keyCode==100){
    //         this.onLogin(this.loginData())
    //     }
    // };
</script>

<style scoped>

</style>
