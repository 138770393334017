<template>
    <section>
        <van-swipe class="my-swipe" :autoplay="3000" lazy-render :show-indicators="false">
            <van-swipe-item v-for="item in hotList" :key="item" @click="showArticle(item.article_id)">
                <van-image :src="item.thumb" />
            </van-swipe-item>
        </van-swipe>
    </section>
    <div class="notice-bar-inline">
        <van-col span="20">
            <van-notice-bar left-icon="volume-o" :scrollable="true">
                <van-swipe
                        vertical
                        class="notice-swipe"
                        :autoplay="3000"
                        :touchable="false"
                        :show-indicators="false"
                >
                    <van-swipe-item v-for="item in notifList" :key="item" @click="showArticle(item.article_id)">{{ item.title }}</van-swipe-item>
                </van-swipe>
            </van-notice-bar>
        </van-col>
        <van-col span="4">
            <span class="more-notice" @click="showMoreNotice">更多>></span>
        </van-col>
    </div>

<!--    <section>-->
<!--        <van-grid :column-num="2">-->
<!--            <van-grid-item>-->
<!--                <template #text>-->
<!--                    <div class="show-count">-->
<!--                        <span class="title">24H投诉</span>-->
<!--                        <span>{{count24h}}</span>-->
<!--                    </div>-->
<!--                </template>-->
<!--            </van-grid-item>-->
<!--            <van-grid-item>-->
<!--                <template #text>-->
<!--                    <div class="show-count">-->
<!--                        <span class="title">累计投诉</span>-->
<!--                        <span>{{totalCount}}</span>-->
<!--                    </div>-->
<!--                </template>-->
<!--            </van-grid-item>-->
<!--        </van-grid>-->
<!--    </section>-->
    <van-tabs v-model:active="active" sticky swipeable>
        <van-tab title="最新投诉">
            <van-pull-refresh v-model="newTsRefreshing" @refresh="onRefreshNew" disabled>
                <van-list
                        v-model:loading="newTsLoading"
                        :finished="newTsFinished"
                        finished-text="我是有底线的"
                        offset="100"
                        @load="onLoadNew"
                >
                    <div class="tousu_con" v-for="item in newTsList" :key="item" @click="showComplaintInfo(item.complaint_id)">
                        <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                        <a class="box" :href="item.url" target="_blank">
                            <van-image
                                round
                                width="1rem"
                                height="1rem"
                                :src="item.imgURL"
                            />
                            <div class="tit">
                                <span class="name" v-if="item.is_anon === 1">匿名</span>
                                <span class="name" v-else>{{item.userName}}</span>
                                <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                            </div>
                            <h1 class="">{{item.title}}</h1>
                            <p>{{item.desc}}</p>
                        </a>
                        <ul class="list">
                            <li>
                                <span>[投诉对象]</span>{{item.tousu_target}}
                            </li>
                            <li>
                                <span>[投诉要求]</span>{{item.tousu_appeal}}
                            </li>
                        </ul>
                        <template v-if="item.is_help === 1">
                            <div class="law-help" >
                                <span class="font-circle">帮</span>
                                <span >律师已参与</span>
                            </div>
                        </template>
                    </div>
                </van-list>
            </van-pull-refresh>
        </van-tab>
        <van-tab title="已回复">
            <van-pull-refresh v-model="replaidRefreshing" @refresh="onRefreshReplaid" disabled>
                <van-list
                        v-model:loading="replaidLoading"
                        :finished="replaidFinished"
                        finished-text="我是有底线的"
                        offset="100"
                        @load="onLoadReplaid"
                >
                    <div class="tousu_con" v-for="item in replaidList" :key="item" @click="showComplaintInfo(item.complaint_id)">
                        <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                        <a class="box" :href="item.url" target="_blank">
                            <van-image
                                    round
                                    width="1rem"
                                    height="1rem"
                                    :src="item.imgURL"
                            />
                            <div class="tit">
                                <span class="name" v-if="item.is_anon === 1">匿名</span>
                                <span class="name" v-else>{{item.userName}}</span>
                                <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                            </div>
                            <h1 class="">{{item.title}}</h1>
                            <p>{{item.desc}}</p>
                        </a>
                        <ul class="list">
                            <li>
                                <span>[投诉对象]</span>{{item.tousu_target}}
                            </li>
                            <li>
                                <span>[投诉要求]</span>{{item.tousu_appeal}}
                            </li>
                        </ul>
                        <template v-if="item.is_help === 1">
                            <div class="law-help" >
                                <span class="font-circle">帮</span>
                                <span >律师已参与</span>
                            </div>
                        </template>
                    </div>
                </van-list>
            </van-pull-refresh>
        </van-tab>
        <van-tab title="已完成">
            <van-pull-refresh v-model="finishedRefreshing" @refresh="onRefreshFinished" disabled>
                <van-list
                        v-model:loading="finishedLoading"
                        :finished="finishedFinished"
                        finished-text="我是有底线的"
                        offset="100"
                        @load="onLoadFinished"
                >
                    <div class="tousu_con" v-for="item in finishedList" :key="item" @click="showComplaintInfo(item.complaint_id)">
                        <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                        <a class="box" :href="item.url" target="_blank">
                            <van-image
                                    round
                                    width="1rem"
                                    height="1rem"
                                    :src="item.imgURL"
                            />
                            <div class="tit">
                                <span class="name" v-if="item.is_anon === 1">匿名</span>
                                <span class="name" v-else>{{item.userName}}</span>
                                <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                            </div>
                            <h1 class="">{{item.title}}</h1>
                            <p>{{item.desc}}</p>
                        </a>
                        <ul class="list">
                            <li>
                                <span>[投诉对象]</span>{{item.tousu_target}}
                            </li>
                            <li>
                                <span>[投诉要求]</span>{{item.tousu_appeal}}
                            </li>
                        </ul>
                        <template v-if="item.is_help === 1">
                            <div class="law-help" >
                                <span class="font-circle">帮</span>
                                <span >律师已参与</span>
                            </div>
                        </template>
                    </div>
                </van-list>
            </van-pull-refresh>
        </van-tab>
    </van-tabs>

</template>

<script>
    import {ref} from 'vue';
    import {useRouter} from 'vue-router';
    import {complaintList} from '@/api/complaint'
    import {articleList} from '@/api/article'
    import {staticDate} from "@/api/rightAside"
    export default {
        name: "m_index",
        setup(){
            const active = ref(0);
            const router = useRouter();
            //最新投诉
            const newTsList = ref([])
            const newTsRefreshing = ref(false);
            const newTsFinished = ref(false);
            const newTsLoading = ref(false);
            const newTagQueryParams = ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                queryDataStatus:'1,3,4,5,6'
            });
            //已回复
            const replaidList = ref([])
            const replaidRefreshing = ref(false);
            const replaidFinished = ref(false);
            const replaidLoading = ref(false);
            const replaidQueryParams = ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                queryDataStatus:'4'
            });
            //已完成
            const finishedList = ref([])
            const finishedRefreshing = ref(false);
            const finishedFinished = ref(false);
            const finishedLoading = ref(false);
            const finishedQueryParams = ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                queryDataStatus:'6'
            });
            //公告
            const notifList = ref([])
            //统计数据
            const count24h = ref(0)
            const totalCount = ref(0)

            const onLoadNew = async () =>{
                await setTimeout(()=>{
                    if(newTsRefreshing.value){
                        newTsList.value = [];
                        newTsRefreshing.value = false;
                    }
                    newTagQueryParams.value.page += 1;
                    complaintList(newTagQueryParams.value).then((res) => {
                        if (res.status === 200 && res.data.code === 200001) {
                            let listData = res.data.data.complaintData;

                            let curPageInfo = res.data.data.pageInfo;
                            // let len = this.tousu_new.length;
                            listData.forEach((item) => {
                                newTsList.value.push(item);
                            });
                            newTsLoading.value = false;
                            newTagQueryParams.value.page = curPageInfo.page;
                            newTagQueryParams.value.totalPage = curPageInfo.totalPage;
                            if(listData.length < 10 || newTagQueryParams.value.page === newTagQueryParams.value.totalPage){
                                newTsFinished.value = true;
                            }
                        }
                    });

                },1000);
            };
            const onRefreshNew = () =>{
                newTsFinished.value = false;
                newTsLoading.value = true;
                onLoadNew();
            };
            //已回复
            const onLoadReplaid = async () =>{
                await setTimeout(()=>{
                    if(replaidRefreshing.value){
                        replaidList.value = [];
                        replaidRefreshing.value = false;
                    }
                    replaidQueryParams.value.page += 1;
                    complaintList(replaidQueryParams.value).then(res => {
                        if (res.status === 200 && res.data.code === 200001) {
                            let listData = res.data.data.complaintData;

                            let curPageInfo = res.data.data.pageInfo;
                            // let len = this.tousu_new.length;
                            listData.forEach((item) => {
                                replaidList.value.push(item);
                            });
                            replaidLoading.value = false;
                            replaidQueryParams.value.page = curPageInfo.page;
                            replaidQueryParams.value.totalPage = curPageInfo.totalPage;
                            if(listData.length < 10 || replaidQueryParams.value.page === replaidQueryParams.value.totalPage){
                                replaidFinished.value = true;
                            }
                        }
                    });
                },1000);
            };
            const onRefreshReplaid = () =>{
                replaidFinished.value = false;
                replaidLoading.value = true;
                onLoadReplaid();
            };
            //已完成
            const onLoadFinished = async () =>{
                await setTimeout(()=>{
                    if(finishedRefreshing.value){
                        finishedList.value = [];
                        finishedRefreshing.value = false;
                    }
                    finishedQueryParams.value.page += 1;
                    complaintList(finishedQueryParams.value).then(res => {
                        if (res.status === 200 && res.data.code === 200001) {
                            let listData = res.data.data.complaintData;

                            let curPageInfo = res.data.data.pageInfo;
                            // let len = this.tousu_new.length;
                            listData.forEach((item) => {
                                finishedList.value.push(item);
                            });
                            finishedLoading.value = false;
                            finishedQueryParams.value.page = curPageInfo.page;
                            finishedQueryParams.value.totalPage = curPageInfo.totalPage;
                            if(listData.length < 10 || finishedQueryParams.value.page === finishedQueryParams.value.totalPage){
                                finishedFinished.value = true;
                            }
                        }
                    });
                },1000);
            };
            const onRefreshFinished = () =>{
                finishedFinished.value = false;
                finishedLoading.value = true;
                onLoadReplaid();
            };
            // const images = [
            //     'https://fastly.jsdelivr.net/npm/@vant/assets/apple-1.jpeg',
            //     'https://fastly.jsdelivr.net/npm/@vant/assets/apple-2.jpeg',
            // ];
            const showNotice = () =>{
                articleList({'notice':1}).then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        let noticData = res.data.data.articleData;
                        noticData.forEach((item)=>{
                            notifList.value.push(item);
                        });
                        console.log(notifList.value);
                    }
                })
            };
            const hotList = ref([]);
            const showBanner = () =>{
                articleList({'hot':1}).then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        let hotData = res.data.data.articleData;
                        hotData.forEach((item)=>{
                            hotList.value.push(item);
                        });
                        console.log(hotList.value);
                    }
                })
            };
            const staticData = () =>{
                staticDate().then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        count24h.value = res.data.data.count24h;
                        totalCount.value = res.data.data.totalCount;
                    }
                })
            };
            const showComplaintInfo = (val) =>{
                router.push({
                    path:'/m_complaintInfo',
                    query:{
                        complaintId : val
                    }
                });
            };
            const showArticle = (val) =>{
                router.push({name:'m_articleShow',params:{articleId:val}});
            };
            const showMoreNotice = () =>{
                router.push({name:'m_article'});
            };
            return {showMoreNotice,showArticle,hotList,showBanner,showComplaintInfo,newTsList,newTsRefreshing,newTsFinished,newTsLoading,onRefreshNew,onLoadNew,active,replaidList,replaidFinished,replaidLoading,replaidRefreshing,onLoadReplaid,onRefreshReplaid,finishedFinished,finishedList,finishedLoading,finishedRefreshing,onLoadFinished,onRefreshFinished,newTagQueryParams,notifList,showNotice,staticData,count24h,totalCount}
        },
        mounted(){
            this.showNotice();
            // this.staticData();
            this.showBanner();
        }
    }
</script>

<style scoped>
    .notice-swipe {
        height: 0.5rem;
        line-height: 0.5rem;
    }
    .van-notice-bar .van-swipe-item{
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
    }
    .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        /*line-height: 150px;*/
        text-align: center;
        /*background-color: #39a9ed;*/
    }
    .my-swipe .van-swipe-item img{
        width:98%;
    }
    .show-count{
        display: flex;
        flex-direction: column;
    }
    .show-count .title{
        font-size: var(--van-font-size-md);
        font-weight: bold;
        margin-bottom: 5px;
    }

    .icon-status {
        position: absolute;
        right: 0.2rem;
        top: 0.6rem;
        background-size: 100%;
        /*font-size: 14px;*/
        /*line-height: 30px;*/
        color: #fff;
        text-align: center;
        width: 30px;
        height: 30px;
    }
    .icontstj{
        /*width: 30px;*/
        /*height: 30px;*/
        background: url("@/assets/icons/daishenhe.png") no-repeat;
        background-size: cover;
    }
    .iconyishenhe{
        /*width: 30px;*/
        /*height: 30px;*/
        background: url("@/assets/icons/yishenhe.png") no-repeat;
        background-size: cover;
    }
    .iconshenheshibai{
        /*width: 20px;*/
        /*height: 20px;*/
        background: url("@/assets/icons/shenheshibai.png") no-repeat;
        background-size: cover;
    }
    .iconyifenpei{
        /*width: 20px;*/
        /*height: 20px;*/
        background: url("@/assets/icons/yifenpei.png") no-repeat;
        background-size: cover;
    }
    .iconDone{
        /*width: 20px;*/
        /*height: 20px;*/
        background: url("@/assets/icons/finished.png") no-repeat;
        background-size: cover;
    }
    .iconAnswer{
        /*width: 20px;*/
        /*height: 20px;*/
        background: url("@/assets/icons/replied.png") no-repeat;
        background-size: cover;
    }

    .iconBuchong{
        /*width: 20px;*/
        /*height: 20px;*/
        background: url("@/assets/icons/yibuchong.png") no-repeat;
        background-size: cover;
    }
    .tousu_con {
        background: #fff;
        position: relative;
        /*width: 100%;*/
        padding: 0 0.3rem 0.3rem 0.3rem;
        border-bottom: 2px dotted #979797;
        cursor: pointer;
        /*box-sizing: border-box;*/
    }
    .tousu_con .box {
        display: block;
    }
    a {
        text-decoration: none;
        outline: none;
        color: #333;
    }
    .tousu_con .van-image {
        float: left;
        margin: 20px 0;
        border-radius: 50%;
        background-size: 100%;
    }
    .tousu_con .img img {
        border-radius: 100%;
        width: 60px;
        height: 60px;
    }
    .tousu_con .tit {
        overflow: hidden;
        padding: 0.45rem 0.375rem 0.375rem;
        line-height: 22px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    .tousu_con .name {
        font-size: 0.4rem;
        color: #212121;
    }
    .tousu_con .time {
        display: block;
        font-size: 0.375rem;
        color: #84848A;
        margin-top: .04rem;
        line-height: 20px;
    }
    .tousu_con h1 {
        font-size: 20px;
        color: #000;
        line-height: 34px;
        text-align: justify;
        clear: both;
    }
    .tousu_con p {

        margin: 10px 0;
        font-size: 0.375rem;
        color: #5D5D5D;
        /*line-height: 26px;*/
        text-align: justify;
        overflow: hidden;
        text-overflow: ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp: 4;
    }
    .tousu_con .list {
        font-size: 0.375rem;
        color: #5D5D5D;
        line-height: 0.75rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .tousu_con ul{
        list-style: none;
        padding-inline-start: 0;
    }
    .tousu_con .list span:first-child {
        color: #212121;
        padding-right: 5px;
    }
    .tousu_con .law-help{
        font-size: 0.375rem;
        color: var(--el-color-primary-dark-2);
        display: flex;
        align-items: center;
        margin-bottom: 0.1rem;
    }
    .tousu_con .law-help .font-circle{
        width: 0.7rem;
        height: 0.7rem;
        border: 2px solid var(--el-color-primary-dark-2);
        border-radius: 50%;
        margin-right: 0.2rem;
        font-size: 0.5rem;
    }
    .notice-bar-inline{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .more-notice{
        background-color: var(--van-notice-bar-background);
        color: var(--van-notice-bar-text-color);
        display: flex;
        align-items: center;
        font-size: 14px;
        width: 100%;
        height: 100%;
    }
</style>
