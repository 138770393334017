const webStorage =(function() {
    /**
     * 从storage中读取数据，使用与sessionStorage和localStorage相同的读取数据函数名称
     * @param {*} storageType 存储类型，只能为sessionStorage或localStorage
     * @param {*} key 读取数据的key
     * @returns
     */
    function getItem(storageType, key) {
        // 如果storageType字段不合法，直接返回空
        if (!['sessionStorage', 'localStorage'].includes(storageType)) {
            return null;
        }

        const storeData = window[storageType].getItem(key);
        // 如果根据key没有找到数据，直接返回空
        if (!storeData) {
            return null;
        }

        const parsedData = JSON.parse(storeData);
        const currentTimestamp = new Date().getTime();

        // 将当前的时间戳和保存在storage中的timestamp进行比较
        // 如果时间差小于等于过期时间说明没有过期，直接返回数据
        // 否则，说明数据已经过期，将storage中的key清除
        if (currentTimestamp - parsedData.timestamp <= parsedData.expire) {
            return parsedData.value;
        } else {
            window[storageType].removeItem(key);
        }

        return null;
    }

    function removeItem(storageType, key){
        if (!['sessionStorage', 'localStorage'].includes(storageType)) {
            return null;
        }
        const storeData = window[storageType].getItem(key);
        // 如果根据key没有找到数据，直接返回空
        if (!storeData) {
            return null;
        }else {
            window[storageType].removeItem(key);
            if(key === 'user'){
                let newStorageEvent = document.createEvent('StorageEvent')
                newStorageEvent.initStorageEvent('removeItem')
                window.dispatchEvent(newStorageEvent)
            }
        }
    }
    /**
     * 向storage中添加字段，使用与sessionStorage和localStorage相同的读取数据函数名称
     * @param {*} storageType 存储类型，只能为sessionStorage或localStorage
     * @param {*} key 保存数据的key
     * @param {*} value 保存的数据
     * @param {*} expire 过期时间，默认为1分钟
     */
    function setItem(storageType, key, value, expire = 60000) {
        // 如果storageType字段不合法，直接返回空
        if (!['sessionStorage', 'localStorage'].includes(storageType)) {
            return;
        }

        const obj = {
            value: value,
            expire: expire,
            timestamp: new Date().getTime()
        }

        const stringfiedData = JSON.stringify(obj);
        window[storageType].setItem(key, stringfiedData);
        if(key === 'user'){
            let newStorageEvent = document.createEvent('StorageEvent')
            newStorageEvent.initStorageEvent('setItem',false,false,key,null,stringfiedData,null,null)
            window.dispatchEvent(newStorageEvent)
        }

    }
    return{setItem,getItem,removeItem}
})();
export default webStorage
