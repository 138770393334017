/**
 * 用户相关请求模块
 */
import {request,remoteRequest} from '@/utils/request'
import webStorage from "@/utils/webStorage";

// 用户登录
export const login = data => {
    return request({
        method: 'POST',
        url: 'login/',
        data
    })
};
// 注册
export const register = data =>{
    return request({
        method: 'POST',
        url: 'register/',
        data
    })
};
export const sendmsg = data =>{
    return request({
        method: 'POST',
        url: 'sendMsg/',
        data
    })
};
export const validShortMsg = data =>{
    return request({
        method: 'POST',
        url: 'validShortMsg/',
        data
    })
};
// 获取用户资料
export const loadUserInfo = () => {
    // const user = JSON.parse(window.localStorage.getItem('user'))
    const token = webStorage.getItem('localStorage','token')
    return request({
        method: 'GET',
        url: 'loadUserInfo/',
        headers: {
            //   // 后端要求把需要授权的用户身份放到请求头中
            //   // axios 可以通过 headers 选项设置请求头
            //   // 属性名和值都得看接口的要求
            //   // 属性名：Authorization，接口要求的
            //   // 属性值：Bearer空格token数据
            //   // Bearer 就是持票人的意思，就好比你的学生证上写了学生证三个字
            Authorization: `jwt ${token}`
        }
    })
};
//获取图片验证码
export const getCaptcha = data => {
    return request({
        method: 'GET',
        url: 'getcaptcha/',
        data
    })
};
export const changeAvatar = data =>{
    return request({
        method: 'POST',
        url:'changeAvatar/',
        data
    })
};

export const imageUpload = data =>{
    return request({
        method: 'POST',
        url: 'imageUpload/',
        data,
        headers:{
            'Content-type':'multipart/form-data',
            transformRequest: [data => data]
        }
    })
};

export const changeBaseInfo = data =>{
    return request({
        method: 'POST',
        url: 'changeUserInfo/',
        data
    })
};

export const changeMobile = data =>{
    return request({
        method: 'POST',
        url: 'changeMobile/',
        data
    })
};

export const changePwd = data =>{
    return request({
        method: 'POST',
        url: 'changePwd/',
        data
    })
};

export const myts = data =>{
    return request({
        method: 'POST',
        url: 'myTousu/',
        data
    })
};

export const userComplaintsStatusCount = data =>{
    return request({
        method: 'POST',
        url: 'userComplaintsStatusCount/',
        data
    })
};

export const userCancel = data =>{
    return request({
        method: 'POST',
        url: 'userCancel/',
        data
    })
};

export const notFinishedCount = data =>{
    return request({
        method: 'POST',
        url: 'notFinishedCount/',
        data
    })
};

export const resetPwd = data =>{
    return request({
        method: 'POST',
        url: 'resetPwd/',
        data
    })
};

export const checkUNameRepied = data =>{
    return request({
        method: 'POST',
        url: 'checkUsernameRepied/',
        data
    })
}

export const checkPhoneRepied = data =>{
    return request({
        method: 'POST',
        url: 'checkPhoneRepied/',
        data
    })
}

export const showUserPhoneNo = data =>{
    return request({
        method: 'POST',
        url: 'showUserPhoneNo/',
        data
    })
}

export const validateNameInRules = data =>{
    return remoteRequest({
        method: 'POST',
        url: '/tool/checkSensitiveword',
        data
    })
}
