<template>
    <Header/>
    <div class="login-container">
        <el-steps :active="active" align-center>
            <el-step title="验证身份，发送验证码"/>
            <el-step title="接收验证码"/>
            <el-step title="设置新密码"/>
            <el-step title="完成"/>
        </el-steps>
        <div class="step-c" v-show="showStep1">
            <el-form label-width="100px" :model="fgpwd" ref="step1Ref" :rules="step1formRule">
                <el-form-item label="图片验证码" prop="captcha">
                    <el-input size="large" v-model="fgpwd.captcha" placeholder="请输入验证码">
                        <template #append>
                        <el-image style="height: 38px" :src="captchaUrl"  @click="refresh"/>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="mobile">
                    <el-input size="large" v-model="fgpwd.mobile" placeholder="输入注册时使用的手机号" >
                        <template #prefix>
                            <el-icon class="el-input__icon"><Iphone /></el-icon>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="login-btn" type="primary" @click="toIndex">取消</el-button>
                    <el-button class="login-btn" type="primary" @click="nextStep2">发送短信验证码</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="step-c" v-show="showStep2">
            <el-form label-width="100px" :model="fgpwd" ref="step2Ref" :rules="step2formRule">
                <el-form-item label="短信验证码" prop="shortMsg">
                    <el-input size="large" v-model="fgpwd.shortMsg" placeholder="请输入验证码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-col :span="12">
                        <el-button class="login-btn" type="primary" @click="backStep1">上一步</el-button>
                    </el-col>
                    <el-col  style="" :span="12">
                        <el-button class="login-btn" type="primary" @click="nextStep3">下一步</el-button>
                    </el-col>
                </el-form-item>
            </el-form>
        </div>
        <div class="step-c" v-show="showStep3">
            <el-form label-width="100px" :model="reset" ref="step3Ref" :rules="step3formRule">
                <el-form-item label="新密码" prop="passwd">
                    <el-input size="large" type="password" v-model="reset.passwd" placeholder="8~30位大小字母、数字、特称字符"/>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPasswd">
                    <el-input size="large" type="password" v-model="reset.checkPasswd" placeholder="再次输入密码"/>
                </el-form-item>
                <el-form-item>
                    <el-col :span="12">
                        <el-button class="login-btn" type="primary" @click="backStep2">上一步</el-button>
                    </el-col>
                    <el-col  style="" :span="12">
                        <el-button class="login-btn" type="primary" @click="nextStep4">完成</el-button>
                    </el-col>
                </el-form-item>
            </el-form>
        </div>
        <div class="step-c" v-show="showStep4">

        </div>

    </div>
    <Footer/>
</template>

<script>
    import {ref} from 'vue';
    import {useRouter} from 'vue-router';
    import { getCaptcha ,sendmsg,validShortMsg,resetPwd} from '@/api/user'
    import Header from "../components/common/Header";
    import Footer from "../components/common/Footer";
    import {ElMessage,ElMessageBox} from 'element-plus';
    export default {
        name: "forgetPwd",
        components:{Header,Footer},
        setup(){
            const router = useRouter();
            const active = ref(0);
            const showStep1 = ref(true);
            const showStep2 = ref(false);
            const showStep3 = ref(false);
            const showStep4 = ref(false);
            const captchaUrl = ref('');
            const fgpwd = ref({
                mobile: '',
                captcha:'',
                shortMsg:'',
                hashkey:'',
                actionType:'resetPwd'
            });
            const reset = ref({
                passwd :'',
                checkPasswd :''
            });
            // const loginLoading = false;
            const refresh = () =>{
                getCaptcha().then(res => {
                    fgpwd.value.hashkey=res.data.data.hashkey
                    captchaUrl.value=res.data.data.image_url
                }).catch(() => {
                    ElMessage({message:'获取验证码失败', type:'error'})
                    // 关闭 loading
                    // loginLoading = false
                })
            };
            const smsBtnDis = ref(false);
            const smsBtnContent = ref('获取短信');
            const timer = ref();
            const count = ref(0);
            const sendMsg = () =>{
                // let vm = this
                let reg = /^1[3|5|7|8|9]\d{9}$/
                if(!reg.test(fgpwd.value.mobile)){
                    ElMessage({message: "请输入正确的手机号",type:'warning'})
                    return
                }
                sendmsg(fgpwd.value).then(res =>{
                    if(res.status===200 && res.data.code===200001){
                        smsBtnDis.value = true
                        const TIME_COUNT = 60
                        count.value = TIME_COUNT
                        timer.value = window.setInterval(() => {
                            if (count.value > 0 && count.value <= TIME_COUNT){
                                // 倒计时时不可点击
                                smsBtnDis.value = true
                                // 计时秒数
                                count.value--
                                // 更新按钮的文字内容
                                smsBtnContent.value = count.value + 's后重新获取'
                            } else {
                                // 倒计时完，可点击
                                smsBtnDis.value = false
                                // 更新按钮文字内容
                                smsBtnContent.value = '获取短信'
                                // 清空定时器!!!
                                clearInterval(timer.value)
                                timer.value = null
                            }
                        }, 1000)
                    }else{
                        ElMessage({message:res.data.message,type:'error'})
                    }
                }).catch(() =>{
                    ElMessage({message:'短信验证码发送失败', type:'error'})
                })
            };
            const backStep1 = ()=>{
                active.value -=1;
                showStep2.value = false;
                showStep1.value = true;
            };
            const step1Ref = ref();
            const step2Ref = ref();
            const step3Ref = ref();
            const nextStep2 =()=>{
                step1Ref.value.validate(valid => {
                    if (valid) {
                        sendmsg(fgpwd.value).then(res => {
                            if (res.status === 200 && res.data.code === 200001) {
                                active.value +=1;
                                showStep1.value = false;
                                showStep2.value = true;
                            }
                        })
                    }
                });

            };
            const backStep2 = ()=>{
                active.value -= 1;
                showStep3.value = false;
                showStep2.value = true;
            };
            const nextStep3 =()=>{
                step2Ref.value.validate(valid =>{
                    if(valid){
                        validShortMsg(fgpwd.value).then(res =>{
                            if (res.status === 200 && res.data.code === 200001) {
                                active.value +=1;
                                showStep2.value = false;
                                showStep3.value = true;
                            }
                        })

                    }
                });
            };
            const open = () => {
                ElMessageBox.alert('密码重置成功，点击确定返回首页', '提示', {
                    // if you want to disable its autofocus
                    // autofocus: false,
                    confirmButtonText: '确定',
                    callback: () => {
                        router.push("/p_index");
                    },
                })
            };
            const nextStep4 =()=>{
                step3Ref.value.validate(valid =>{
                    if(valid){
                        resetPwd({mobile:fgpwd.value.mobile,newPasswd:reset.value.passwd,confirePwd:reset.value.checkPasswd}).then(res =>{
                            if (res.status === 200 && res.data.code === 200001) {
                                active.value +=1
                                showStep3.value = false;
                                showStep4.value = true;
                                open();
                            }
                        })
                    }
                })
            };

            const step1formRule = ref({
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
                ],
                captcha:[
                    { required: true, message:'图片验证码不能为空', trigger: 'blur'}
                ],
            });
            const step2formRule = ref({
                shortMsg:[{required: true, message: '短信验证码不能为空', trigger: 'blur'}]
            });
            const validatePass=(rule, value, callback) =>{
                if (value === '') {
                    callback(new Error('请输入密码'));
                }
                // var passwdReg = /^(?![A-z0-9]+$)(?![A-z~@*()_]+$)(?![0-9~@*()_]+$)([A-z0-9~@*()_]{6,15})$/
                var passwdReg= /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}$/
                if(!passwdReg.test(value)){
                    callback(
                        new Error("密码必须为8~30位大小字母、数字、特称字符!")
                    )
                }else{
                    callback()
                }
            };
            const validatePass2 = (rule, value, callback) =>{
                if (value === '') {
                    callback(new Error('请输入确认密码!'));
                }else if(value != reset.value.passwd){
                    callback(new Error('两次输入密码不一致!'));
                }else{
                    callback()
                }
            };
            const step3formRule = ref({
                passwd: [
                    { required: true, trigger: 'blur', validator: validatePass },
                ],
                checkPasswd: [
                    { required: true,  trigger: 'blur', validator: validatePass2 }
                ]
            });
            const toIndex = () =>{
                router.push('/p_index');
            };
            return {toIndex,router,validatePass,validatePass2,step3Ref,step2Ref,step1Ref,step3formRule,step2formRule,step1formRule,backStep1,backStep2,open,active,reset,nextStep2,nextStep3,nextStep4,showStep1,showStep2,showStep3,showStep4,fgpwd,captchaUrl,refresh,smsBtnDis,smsBtnContent,timer,count,sendMsg};
        },
        mounted(){
            this.refresh();
        },
        methods: {

        }
    }
</script>

<style>
    .login-container{
        width: 650px;
        min-height: 650px;
        margin: 80px auto;
        padding-top: 70px;
    }
    .step-c{
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
    .el-input-group__append{
        padding: 0 0;
    }
</style>
