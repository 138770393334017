<template>
    <div class="tousu-info">
        <div class="subnav">
            <a href="/" title="投诉">首页</a>
            <span>> 详情</span>
        </div>
        <div class="ts-info-body">
            <div class="tousu-main">
                <div class="tousu-title paddingleft">
                    <h1>{{tousu.title}}</h1>
                    <div v-show="tousu.status_desc !== 6 && curUser && curUser.user_id === tousu.user_id" class="rgithBtn"><el-button style="color: var(--el-color-primary)" @click="finishDialogVisible = true">确认完成</el-button></div>
                </div>
                <div class="user-info">
                    <el-avatar
                            class="avatar"
                            shape="circle"
                            fit="cover"
                            :size="40"
                    >
                        <img :src="tousu.avatar" @error="setDefaultUser"/>
                    </el-avatar>
                    <span class="user-name paddingleft" v-if="tousu.is_anon === 1">匿名</span>
                    <span class="user-name paddingleft" v-else>{{tousu.userName}}</span>
                    <span class="ts-date paddingleft">{{tousu.date}}</span>
                </div>
                <ul class="ts-list paddingleft">
                    <li>
                        <label>投诉编号：</label>{{tousu.complaintId}}
                    </li>
                    <li>
                        <label>投诉对象：</label>{{tousu.target}}
                    </li>
                    <li>
                        <label>投诉问题：</label>{{tousu.issue}}
                    </li>
                    <li>
                        <label>投诉诉求：</label>{{tousu.appeal}}
                    </li>
                    <li>
                        <label>涉诉金额：</label>{{tousu.amount}}元
                    </li>
                    <li v-if="tousu.is_anon === 1 && curUser && curUser.user_id === tousu.targetUser">
                        <label>联系方式：</label>{{tousu.hidPhone}}
                        <el-popover
                            placement="top-start"
                            :width="200"
                            trigger="click"
                            :content="phoneNo"
                        >
                            <template #reference>
                                <a href="javascript:;" @click="showPhoneNo">查看完整联系方式</a>
                            </template>
                        </el-popover>
                    </li>
                    <li v-if="curUser && curUser.user_id === tousu.targetUser && tousu.troubleOrd">
                        <label>涉诉单号：</label><span class="statu-text">{{tousu.troubleOrd}}</span>
                    </li>
                    <li>
                        <label>投诉进度：</label><span class="statu-text">{{tousu.status}}</span>
                    </li>
                </ul>
                <template v-if="tousu.is_help === 1">
                    <div class="law-help paddingleft" >
                        <span class="font-circle">帮</span>
                        <span>律师已参与</span>
                    </div>
                </template>
    <!--            <share-component :Title="aaaaaa" :Url="complaintId"></share-component>-->
            </div>
            <div class="tousu-steps paddingleft">
                <div class="step-item" v-for="step in tsStep" :key="step">
                    <div class="step-user">
                        <el-avatar
                                :class="{avatar:true,shangjia:step.userType === 3}"
                                shape="circle"
                                fit="cover"
                                :size="40"
                        >
                            <img :src="step.avatar" @error="setDefaultUser"/>
                        </el-avatar>
                        <span class="user-name" v-if="tousu.user_id === step.creator && tousu.is_anon === 1">匿名</span>
                        <span class="user-name" v-else><span v-show="step.userType===5">律师 </span> {{step.userName}}</span>
                        <span class="step-status">{{step.status}}</span>
                        <span class="step-date">{{step.date}}</span>
                    </div>
                    <div class="step-content">
                        <p class="text-item">{{step.content}}</p>
                        <div class="step-img">
                            <li v-for="(atta,index) in step.attachs" :key="atta">
                                <el-image :src="atta.url" :preview-src-list="previewImageList" :initial-index="previewInitIndex" @click="previewImage(step.attachs,index)" >
                                    <template #error>
                                        <div class="image-slot">
                                            <el-icon><Picture /></el-icon>
                                        </div>
                                    </template>
                                </el-image>
                            </li>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="curUser">
                <div class="ts-reply" v-show="tousu.status_desc !== 6 && tousu.status_desc !== 2 && ((curUser.category ===5 && tousu.is_help !== 1) || curUser.user_id === tousu.user_id || curUser.user_id === tousu.targetUser)">
                    <div class="reply-title">
                        <span>回复/补充投诉</span>
                        <el-button @click="replyTS">提交</el-button>
                    </div>
                    <div class="reply-content">
                        <el-col :span="2">
                            <el-avatar
                                    class="avatar"
                                    shape="circle"
                                    fit="cover"
                                    :size="40"
                            >
                                <img :src="curUser.avatar" @error="setDefaultUser"/>
                            </el-avatar>
                        </el-col>
                        <el-col :span="22">
                            <el-input type="textarea" v-model="replyContent" rows="5" maxlength="100" show-word-limit size="large" placeholder="请输入回复/补充内容" >
                                <template #suffix>
                                    <el-icon class="el-input__icon"><Plus/></el-icon>
                                </template>
                            </el-input>
                        </el-col>

                    </div>
                    <div class="reply-upload">
                        <el-upload
                                v-model:file-list="fileList"
                                action="#"
                                list-type="picture-card"
                                accept="image/jpeg,image/png"
                                :auto-upload="false"
                                :limit="5"
                                :on-change="handleChange"
                        >
                            <el-icon><Plus /></el-icon>
                        </el-upload>

                        <el-dialog v-model="dialogVisible">
                            <img w-full :src="dialogImageUrl" alt="Preview Image" />
                        </el-dialog>
                    </div>
                    <div class="pic-tip">
                        <el-space >
                            <el-alert type="info" style="text-align: start" show-icon :closable="false">
                                <p style="line-height: normal">1、图片最多上传5张，单张图片大小不超过1M，支持jpg、png、jpeg格式。</p>
                                <p style="line-height: normal">2、若上传图片中含有身份证号、姓名、手机号、银行卡号、家庭住址等个人隐私信息的，请设置为隐藏或用马赛克遮挡。</p>
                                <p style="line-height: normal">3、如果图片文件大于5M，可在投诉完成后，通过邮件以附件形式发送到：tstjzx@bjifia.com.cn。请在邮件中标明投诉标号。</p>
                            </el-alert>
                        </el-space>
                    </div>
                </div>
            </template>
            <div class="safe-statment">
                <p class="text-item">声明：</p>
                <p class="text-item">1.以上内容仅代表投诉者本人，不代表北京市互联网金融消费者投诉平台立场。</p>
                <p class="text-item">2.未经授权，本平台案例禁止任何转载，违者将被追究法律责任。</p>
                <p class="text-item">3.北京市互联网金融消费者投诉平台处理投诉不收取任何费用，凡以北京市互联网金融消费者投诉平台名义收费的均为冒充、欺骗行为，请及时报警并与北京市互联网金融消费者投诉平台官方反馈。举报邮箱 biz@bjifia.com.cn。</p>
                <p class="text-item">4.请大家选择官方渠道解决消费纠纷，不要轻信第三方机构或个人办理退费以免受骗。请不要随意通过QQ或微信等方式添加陌生人，更不要给陌生人汇款，谨防被骗，如遇诈骗请及时报警。</p>
            </div>
        </div>
        <el-dialog
                v-model="finishDialogVisible"
                title="确认完成投诉"
                width="30%"
        >
            <span>确认完成后该投诉单将自动关闭，请确认已达到您的预期诉求？</span>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="finishDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="finishTS">确认完成</el-button>
              </span>
            </template>
        </el-dialog>
    </div>
    <el-dialog v-model="loginDialogVisible" title="登录" width="400" show-close="false" align-center>
        <pcLoginComponent @loginEnd="loginEnd" />
    </el-dialog>
    <el-dialog v-model="importantTipsVisible" title="重要提示" width="400" show-close="false" align-center>
        登录失效，请点击"确定"按钮重新登录！
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="importantTipsVisible = false">取消</el-button>
            <el-button type="primary" @click="toNext">
              确定
            </el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script>
    import {complaintInfo,replyTS,finishTS} from '@/api/complaint';
    import {showUserPhoneNo} from '@/api/user';
    import webStorage from "@/utils/webStorage";
    import {ElMessage} from "element-plus"
    import defaultUser from '@/assets/icons/default-user.png'
    // import ShareComponent from "@/views/components/ShareComponent";
    export default {
        name: "complaintInfo",
        components:{},
        data(){
            return{
                loginDialogVisible: false,
                importantTipsVisible: false,
                finishDialogVisible:false,
                complaintId:'',
                fileList: [],
                dialogVisible: false,
                dialogImageUrl: '',
                replyContent:'',
                tousu:{
                    title:'',
                    userName:'',
                    avatar:'',
                    date:'',
                    complaintId:'',
                    target:'',
                    issue: '',
                    appeal:'',
                    amount:0,
                    status:'',
                    troubleOrd:''
                },
                phoneNo: '',
                tsStep:[],
                curUser:{},
                previewImageList:[],
                previewInitIndex: 0
            }
        },
        created(){
            this.complaintId = this.$route.query.complaintId;
            this.showComplaintInfo();
            this.curUser = JSON.parse(webStorage.getItem('localStorage','user'));
        },
        methods:{
            handleChange(file){
                if(file.status !== "ready") return;
                if(file.type != "" || file.type != null || file.type != undefined){
                    let fileExt = file.name.replace(/.+\./,"")
                    if(!this.fileType.includes(fileExt)){
                        ElMessage({message:"上传文件格式不正确!",type:'warning'})
                        // fileList = fileList.slice(-1)
                        this.fileList = this.fileList.slice(0,this.fileList.length-1)
                        return false;
                    }
                    let isLt1M = file.size/1024/1024<=1;
                    if(!isLt1M){
                        ElMessage({message:"上传文件大小不能超过1M!",type:'warning'})
                        // fileList = fileList.slice(-1)
                        this.fileList = this.fileList.slice(0,this.fileList.length-1)
                        return false;
                    }
                }
            },
            toNext(){
                this.importantTipsVisible = false;
                this.loginDialogVisible = true;
            },
            loginEnd(){
                this.loginDialogVisible = false;
            },
            showPhoneNo(){
                if(this.phoneNo === ''){
                    showUserPhoneNo({'complaintId':this.complaintId}).then(res =>{
                        if(res.status ===200 && res.data.code === 200001){
                            this.phoneNo = res.data.data
                        }else{
                            ElMessage({type:'warning',message:res.data.message})
                        }
                    }).catch(error =>{
                        if(error.response.status === 401){
                            // ElMessage({message:'登录超时，请重新登录！',type:"warning"});
                            // this.$router.push('/p_login');
                            this.importantTipsVisible = true;
                        }
                    })
                }
            },
            setDefaultUser(e){
                e.target.src = defaultUser
            },
            previewImage(items,index){
                let imageList = []
                for(const item of items){
                    imageList.push(item.url)
                }
                this.previewImageList = imageList
                this.previewInitIndex = index
            },
            showComplaintInfo(){
                complaintInfo({'complaint_id':this.complaintId}).then(res => {
                    this.tousu = res.data.data.complaintData;
                    this.tsStep = res.data.data.stepsData;
                })
            },
            replyTS(){
                if(this.curUser.status === 3){
                    ElMessage({type:'warning',message:'您的帐号由于****已被禁言，不可以进行补充投诉！'});
                    return
                }
                let formData = new FormData();
                this.fileList.forEach(file =>{
                    //multipartFiles 后台接收名字
                    formData.append("multipartFiles",file.raw)
                })
                formData.append('complaintId',this.tousu.complaintId)
                formData.append('replyContent',this.replyContent)
                replyTS(formData).then(res =>{
                    if(res.status ===200 && res.data.code === 200001){
                        ElMessage({message: '数据已提交！',type:'success'})
                        this.replyContent = ''
                        this.fileList = [];
                        this.showComplaintInfo();
                    }else{
                        ElMessage({message:res.data.message,type:'error'})
                    }
                }).catch(error =>{
                    if(error.response.status === 401){
                        // ElMessage({message:'登录超时，请重新登录！',type:"warning"});
                        // this.$router.push('/p_login');
                        this.importantTipsVisible = true;
                    }
                })
            },
            finishTS(){
                finishTS({'complaintId':this.complaintId}).then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        this.finishDialogVisible = false
                        ElMessage({message:'投诉已确认完成',type:'success'})
                    }
                }).catch(error =>{
                    if(error.response.status === 401){
                        // ElMessage({message:'登录超时，请重新登录！',type:"warning"});
                        // this.$router.push('/p_login');
                        this.importantTipsVisible = true;
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .paddingleft{
        padding-left: 10px;
    }
    .tousu-info{
        width: 100%;
        margin: 80px auto;
        padding: 10px 10px;
        /*background: #ffffff;*/
        /*display: flex;*/
        /*flex-direction: column;*/
        /*align-items: flex-start;*/
    }
    .tousu-info .subnav{
        line-height: 20px;
        font-weight: 500;
        /*width: 98%;*/
        border-bottom: 3px solid var(--el-color-primary-light-5);
        /*margin-bottom: 20px;*/
        font-size: 18px;
        display: flex;
        padding: 10px;
    }
    .ts-info-body{
        margin-top: 20px;
        background-color: #ffffff;
        padding: 0 10px 20px 10px;
    }
    .tousu-info .subnav a{
        color: #03111f;
    }
    .tousu-info .tousu-main{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        border-bottom: 1px solid var(--el-color-primary-light-5);
    }
    .tousu-info .tousu-title{
        font-weight: lighter;
        width: 98%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .tousu-info .tousu-title h1{
        font-size: 20px;
        text-align: left;
    }
    .tousu-info .tousu-title .rgithBtn{
        float: right;
    }
    .tousu-info .user-info{
        font-size: 14px;
        display: flex;
        align-items: center;
    }
    .tousu-info .user-info .user-name{
        font-size: 18px;
    }
    .tousu-info .user-info span{
        margin-left: 20px;
    }
    .ts-date{
        color: var(--el-color-info-light-3);
    }
    .ts-list{
        list-style-type: none;
        float: left;
        line-height: 36px;
        font-size: 15px;
        /*font-weight: 500;*/
        /*padding-inline-start: 0px;*/
        display: flex;
        flex-direction: column;
        align-items: flex-start;

    }
    .ts-list li{
        /*float: left;*/
    }
    .ts-list li label{
        float: left;
        font-size: 16px;
        font-weight: bold;
    }
    .ts-list .statu-text{
        color: var(--el-color-primary);
    }

    .tousu-main .law-help{
        font-size: 14px;
        color: var(--el-color-primary-dark-2);
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }
    .tousu-main .law-help .font-circle{
        width: 23px;
        height: 23px;
        border: 2px solid var(--el-color-primary-dark-2);
        border-radius: 50%;
        margin-right: 5px;
        font-size: 16px;
    }

    .tousu-steps{
        margin-top: 10px;
    }
    .step-user{
        font-size: 20px;
        line-height: 40px;
        display: flex;
    }
    .step-user span{
        margin-left: 10px;
    }
    /*.avatar{*/
    /*    width: 40px;*/
    /*    height: 40px;*/
    /*}*/
    /*.avatar{*/
    /*    width: 40px;*/
    /*    height: 40px;*/
    /*}*/
    .step-user .step-status{
        color: var(--el-color-primary);
    }
    .tousu-steps .step-item{
        display: flex;
        flex-direction: column;
    }
    .tousu-steps .step-item .step-date{
        font-size: 14px;
        color: var(--el-color-info-light-5);
    }
    .tousu-steps .step-item:last-child .step-content{
        border-left-color: transparent;
    }
    .step-item .step-content{
        padding: 1px 34px 35px;
        border-left: 1px solid var(--el-color-info-light-5);
        margin-left: 30px;
        font-size: 14px;
    }
    .tousu-steps .step-item:not(:last-child):before {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        background: url("../../assets/icons/xiajiantou.png");
        /*background-color: #212121;*/
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /*left: 70px;*/
        margin-top: 33px;
        margin-left: 21px;
    }
    .step-user .shangjia:before{
        content: '商';
        position: absolute;
        font-size: 10px;
        /*color: var(--el-color-info);*/
        width: 17px;
        height: 17px;
        /*background-position: 0 0;*/
        /*background-repeat: no-repeat;*/
        /*background-size: 100% 100%;*/
        background-color: var(--el-color-primary);
        border: 1px solid var(--el-color-primary);
        border-radius: 50%;
        margin-top: 20px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .step-item .step-content .step-img{
        display: flex;
        flex-direction: row;
    }
    .step-item .step-content .step-img li{
        list-style-type: none;
        padding-right: 10px;
    }
    /*.step-item .step-content li :deep(.el-image){*/
    /*    width: 100px;*/
    /*}*/
    .step-img .el-image{
        padding: 0 5px;
        max-width: 300px;
        max-height: 200px;
        width: 100px;
        height: 100px;
    }
    .step-img .image-slot{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: var(--el-fill-color-light);
        color: var(--el-text-color-secondary);
        font-size: 30px;
    }
    .text-item{
        text-align: left;
    }
    .safe-statment {
        font-size: 14px;
        line-height: 24px;
        color: #acacac;
        margin-bottom: 20px;
        padding-top: 20px;
        border-top: 1px solid var(--el-color-info-light-3);
    }
    .safe-statment span{
        flex: 1;
        word-wrap: break-word;
        white-space: normal;
    }
    .ts-reply{
        width: 100%;
        margin: 10px auto;
        padding: 10px 0;
        border-top: 2px solid var(--el-color-primary-light-5);
        border-left: 1px solid var(--el-color-info-light-3);
        border-right: 1px solid var(--el-color-info-light-3);
        border-bottom: 1px solid var(--el-color-info-light-3);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .ts-reply .reply-title{
        font-size: 20px;
        font-weight: bold;
        color: var(--el-color-primary);
        padding: 0 20px;
        width: 90%;
        display: flex;
        justify-content: space-between;
    }
    .ts-reply .reply-title button{
        background-color: var(--el-color-info-light-5);
    }
    .ts-reply .reply-content{
        display: flex;
        align-items: center;
        padding: 10px 10px;
        width: 90%;
    }
    .ts-reply .reply-content .avatar{
        margin-right: 20px;
    }
    .ts-reply .reply-upload{
        padding: 10px 10px;
        margin-left: 60px;
    }
    .ts-reply .pic-tip{
        padding: 10px 10px;
        margin-left: 60px;
    }
    .ts-reply .pic-tip el-alert{
        align-items: flex-start;
    }
    .ts-reply .pic-tip p{
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
    .dialog-footer button:first-child {
        margin-right: 10px;
    }
    .ts-reply .reply-content .text-area{
        width: 90%;
    }
</style>
