<template>
    <van-nav-bar title="我要投诉" left-text="取消" @click-left="cancelBtn" />
<!--    <section>-->
        <van-cell-group inset>
            <van-steps :active="active">
                <van-step>投诉企业</van-step>
                <van-step>投诉详情</van-step>
                <van-step>完成</van-step>
            </van-steps>
        </van-cell-group>
<!--    </section>-->

    <section v-show="active===0" >
        <van-form ref="companyFormRef" label-width="7em" @submit="toStep2">
            <van-cell-group inset >
                <van-field label="投诉对象"
                           placeholder="请选择投诉对象"
                           input-align="right"
                           right-icon="arrow"
                           v-model="stepOneData.ts_companyTitle"
                           name="ts_companyTitle"
                           @click="showCompanySearch"
                           size="large"
                           error-message-align="right"
                           :rules="[{required:true,message:'请选择投诉对象'}]"/>
                <van-field error-message-align="right" label="投诉问题" placeholder="请选择投诉问题" input-align="right" right-icon="arrow" v-model="stepOneData.ts_issue" name="ts_issue" size="large" @click="showIssue" :rules="[{required:true,message:'请选择投诉问题'}]"/>
                <van-field error-message-align="right" label="投诉要求" placeholder="请选择投诉诉求" input-align="right" right-icon="arrow" v-model="stepOneData.ts_appeal" name="ts_appeal" size="large" @click="showAppeal" :rules="[{required:true,message:'请选择诉求要求'}]"/>
            </van-cell-group>
            <van-cell-group inset>
                <van-field label="涉诉金额" input-align="right" v-model="stepOneData.ts_amount" v name="ts_amount" >
                    <template #extra>
                        <span>元</span>
                    </template>
                </van-field>
                <van-field label="涉诉单号(保密)" placeholder="订单号、账号、手机号、银行卡等" input-align="right" v-model="stepOneData.ts_order_no" size="large" name="ts_order_no" />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                    下一步
                </van-button>
            </div>
        </van-form>

        <van-popup v-model:show="issueShow" position="top" style="height: 50%;">
            <div class="head-popup">
                <a href="javascript:;" @click="issueShow=false">取消</a>
                <span>添加投诉问题</span>
                <a href="javascript:;" @click="chooseConfirm('issue')">确认</a>
            </div>
            <div class="issue-item">
                <a href="javascript:;" class="item" v-for="issue in issueResult" :key="issue" @click="addSelected($event,issue,'issue')">{{issue.issue}}</a>
                <a href="javascript:;" v-for="issue in defineIssues" :key="issue" :class="{item:true,'item-selected': stepOneData.selectedDefineIssues.findIndex(item => item === issue) > -1  }" @click="handleDefineSelected($event,issue,'issue')">{{issue}}</a>
                <a class="item" @click="defineIssueVisible = true">自定义＋</a>
            </div>
<!--            <van-tag  color="#5d5d5d" round plain size="large" v-for="issue in issueResult" :key="issue">{{issue.issue}}</van-tag>-->
        </van-popup>
        <van-dialog v-model:show="defineIssueVisible" title="自定义投诉问题(最多10字)" show-cancel-button @confirm="handleAddIssue" >
            <van-field v-model="newIssue" maxlength="10" placeholder="请输入问题描述"/>
        </van-dialog>
        <van-popup v-model:show="appealShow" position="top" style="height: 50%;">
            <div class="head-popup">
                <a href="javascript:;" @click="appealShow = false">取消</a>
                <span>添加投诉诉求</span>
                <a href="javascript:;" @click="chooseConfirm('appeal')">确认</a>
            </div>
            <div class="appeal-item">
                <a href="javascript:;" class="item" v-for="appeal in appealResult" :key="appeal" @click="addSelected($event,appeal,'appeal')">{{appeal.appeal}}</a>
                <a href="javascript:;" v-for="appeal in defineAppeals" :key="appeal" :class="{item:true,'item-selected': stepOneData.selectedDefineAppeal.findIndex(item => item === appeal) > -1  }" @click="handleDefineSelected($event,appeal,'appeal')">{{appeal}}</a>
                <a class="item" @click="defineAppealVisible = true">自定义＋</a>
            </div>
        </van-popup>
        <van-dialog v-model:show="defineAppealVisible" title="自定义投诉诉求(最多10字)" show-cancel-button @confirm="handleAddAppeal" >
            <van-field v-model="newAppeal" maxlength="10" placeholder="请输入诉求描述"/>
        </van-dialog>
        <van-popup v-model:show="searchCompanyShow" position="top" style="height: 50%;" >
            <van-search v-model="tmpCompanyName" show-action placeholder="请输入搜索关键词" shape="round" @update:model-value="searchCompany">
                <template #left>
                    <van-icon name="arrow-left" @click="closeSearchCompanyShow"/>
                </template>
                <template #action>
                    <div @click="searchCompany">搜索</div>
                </template>
            </van-search>
            <div class="search-result" v-show="resultShow">
                <li v-for="item in searchResult" :key="item" @click="selectItem(item)">
                    {{item.title}}
                </li>
                <div class="nodata" v-show="tmpCompanyName">
                    <div class="nodata-desc">
                        <a>可直接添加“{{inputCompanyName}}”</a>
                    </div>
                    <div class="nodataBtn">
                        <a style="cursor: pointer" @click="addCompanyName"><el-icon color="#409EFC"><Plus/></el-icon>添加</a>
                    </div>
                </div>
            </div>
        </van-popup>
    </section>
    <section v-show="active===1">
        <van-form ref="complaintDetailRef" @submit="userComplaint">
            <van-cell-group inset >
                <van-field v-model="stepTwoData.tsTitle"
                           name="tsTitle" label="投诉标题"
                           rows="2"
                           type="textarea"
                           clearable="true"
                           maxlength="40"
                           show-word-limit
                           @clear="clearTitle"
                           placeholder="概括核心问题，最好包含商家名以便为您解决（最多40个字）"
                           :rules="[{required:true,message:'请输入投诉标题'}]"/>
                <van-field v-model="stepTwoData.tsContent"
                           name="tsContent"
                           label="投诉详情"
                           rows="5"
                           type="textarea"
                           maxlength="1000"
                           show-word-limit
                           clearable="true"
                           @clear="clearContent"
                           placeholder="请您依据实际情况详细描述投诉事由（不少于20字），切勿填写自己的隐私信息，禁止发布违反法律、行政法规及不文明内容。捏造事实、诬陷诽谤他人、泄露他人隐私的投诉单将被驳回。"
                           :rules="[{required:true,message:'请输入投诉详情'}]"/>
            </van-cell-group>
            <van-cell-group inset>
                <van-field label="添加附件" model-value="添加相关截图" readonly/>
                <van-uploader style="float: left;margin: 0.1rem" :before-read="beforeRead" v-model="fileList" accept=".jpg,.jpeg,.png" multiple :max-count="5">
                    <template #preview-cover="{ file }">
                        <div class="preview-cover van-ellipsis">{{ file.name }}</div>
                    </template>
                </van-uploader>
            </van-cell-group>
            <van-cell-group inset>
                <van-field name="hidePic" label="隐藏图片">
                    <template #input>
                        <van-checkbox v-model="stepTwoData.hidePic" shape="square" />
                    </template>
                </van-field>
                <van-field name="anon" label="匿名投诉">
                    <template #input>
                        <van-checkbox v-model="stepTwoData.anon" shape="square" />
                    </template>
                </van-field>
            </van-cell-group>
            <van-row class="btn-row">
                <van-col span="10">
                    <van-button round block plain type="primary" @click="backStepOne">
                        上一步
                    </van-button>
                </van-col>
                <van-col span="10">
                    <van-button round block type="primary" native-type="submit">
                        提交
                    </van-button>
                </van-col>
            </van-row>
        </van-form>
    </section>
    <section v-show="active===2">
        <van-cell-group inset>
            <p>您的投诉已提交成功，待审核成功后商家会与您联系协商。</p>
        </van-cell-group>
    </section>

</template>

<script>
    import {ref} from 'vue';
    import {useRouter} from 'vue-router';
    // import 'vant/es/notify/style';
    import {showNotify,showConfirmDialog} from 'vant';

    import { companyInfo,getIssueAppeal,complaint} from '@/api/complaint';
    export default {
        name: "m_userComplaint",
        setup(){
            const active = ref(0);
            const router = useRouter();
            const stepOneData = ref({
                ts_companyId:'',
                ts_companyTitle:'',
                ts_issue:'',
                selectedIssue:[],
                issueIdex:[],
                ts_appeal:'',
                selectedAppeal: [],
                appealIdex:[],
                selectedDefineIssues:[],
                selectedDefineAppeal:[],
                ts_amount:0,
                ts_order_no:''
            });
            const stepTwoData = ref({
                tsTitle:'',
                tsContent:'',
                hidePic:0,
                anon:0
            });
            const issueResult = ref([]);
            const appealResult = ref([]);
            const searchCompanyShow = ref(false);
            const tmpCompanyName = ref('');
            const inputCompanyName = ref('');

            const backStepOne = () =>{
                active.value -=1;
            };

            const showCompanySearch = () => {
                searchCompanyShow.value = true;
            };
            const closeSearchCompanyShow = () =>{
                searchCompanyShow.value = false;
            };
            const resultShow = ref(true);
            const searchResult = ref([]);
            const searchCompany = () =>{
                if(tmpCompanyName.value){
                    companyInfo({'companyName':tmpCompanyName.value}).then(res => {
                        if(res.status === 200 && res.data.code === 200001){
                            searchResult.value = []
                            let retList = res.data.data;
                            retList.forEach((item)=>{
                                searchResult.value.push(item);
                            });
                        }
                    }).catch(error =>{
                        if(error.response.status === 403 || error.response.status === 401){
                            showNotify({message:"登录超时，请重新登录！",type:'warning'})
                            router.push('/m_login')
                        }else{
                            showNotify({message:error.message,type:'warning'})
                        }
                    });
                    inputCompanyName.value = tmpCompanyName.value;
                }
            };
            const selectItem = (obj) =>{
                stepOneData.value.ts_companyId = obj.companyId;
                stepOneData.value.ts_companyTitle = obj.title;
                searchCompanyShow.value =false;
                getIssuesAndAppeal(obj.companyId);
            };
            const getIssuesAndAppeal = (dataId) =>{
                getIssueAppeal({'companyId':dataId}).then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        res.data.data.issues.forEach((item) =>{
                            issueResult.value.push(item);
                        });
                        res.data.data.appeals.forEach((item) =>{
                            appealResult.value.push(item);
                        });
                    }
                }).catch(error =>{
                    showNotify({message:error.message,type:'warning'})
                })
            };
            const defineIssues = ref([]);
            const defineIssueVisible = ref(false);
            const newIssue = ref('');
            const handleAddIssue = () =>{
                defineIssues.value.push(newIssue.value);
                stepOneData.value.selectedDefineIssues.push(newIssue.value);
                stepOneData.value.selectedIssue.push(newIssue.value);
                newIssue.value = '';
                defineIssueVisible.value = false;
            };
            const defineAppeals = ref([]);
            const defineAppealVisible = ref(false);
            const newAppeal = ref('');
            const handleAddAppeal = () =>{
                defineAppeals.value.push(newAppeal.value);
                stepOneData.value.selectedDefineAppeal.push(newAppeal.value);
                stepOneData.value.selectedAppeal.push(newAppeal.value);
                newAppeal.value = '';
                defineAppealVisible.value = false;
            };
            const addSelected = ($event,item,type)=> {
                if(type === 'issue'){
                    let arrIndex = stepOneData.value.issueIdex.indexOf(item.issueId)
                    if(arrIndex > -1){
                        $event.currentTarget.className = 'item'
                        stepOneData.value.issueIdex.splice(arrIndex,1)
                        stepOneData.value.selectedIssue.splice(arrIndex,1)
                    }else{
                        $event.currentTarget.className = 'item item-selected'
                        stepOneData.value.selectedIssue.push(item.issue)
                        stepOneData.value.issueIdex.push(item.issueId)
                    }
                }else{
                    let arrIndex = stepOneData.value.appealIdex.indexOf(item.appealId)
                    if(arrIndex > -1){
                        $event.currentTarget.className = 'item'
                        stepOneData.value.appealIdex.splice(arrIndex,1)
                        stepOneData.value.selectedAppeal.splice(arrIndex,1)
                    }else{
                        $event.currentTarget.className = 'item item-selected'
                        stepOneData.value.appealIdex.push(item.appealId)
                        stepOneData.value.selectedAppeal.push(item.appeal)
                    }
                }
            };
            const handleDefineSelected = ($event,item,type) =>{
                if(type === 'issue'){
                    let arrIndex = stepOneData.value.selectedDefineIssues.indexOf(item)
                    if(arrIndex > -1){
                        $event.currentTarget.className = 'item'
                        stepOneData.value.selectedDefineIssues.splice(arrIndex,1)
                        stepOneData.value.selectedIssue.splice(arrIndex,1)
                    }else{
                        $event.currentTarget.className = 'item item-selected'
                        stepOneData.value.selectedDefineIssues.push(item)
                        stepOneData.value.selectedIssue.push(item)
                    }
                }else{
                    let arrIndex = stepOneData.value.selectedDefineAppeal.indexOf(item)
                    if(arrIndex > -1){
                        $event.currentTarget.className = 'item'
                        stepOneData.value.selectedDefineAppeal.splice(arrIndex,1)
                        stepOneData.value.selectedAppeal.splice(arrIndex,1)
                    }else{
                        $event.currentTarget.className = 'item item-selected'
                        stepOneData.value.selectedDefineAppeal.push(item)
                        stepOneData.value.selectedAppeal.push(item)
                    }
                }
            };
            const addCompanyName = () =>{
                stepOneData.value.ts_companyTitle = inputCompanyName.value;
                searchCompanyShow.value =false;
                getIssuesAndAppeal('')
            };
            const issueShow = ref(false);
            const showIssue = () =>{
                if( stepOneData.value.ts_companyTitle === ''){
                    showNotify({type:'warning', message:'请选择投诉对象'});
                    return;
                }
                issueShow.value = true;
            };
            const appealShow = ref(false);
            const showAppeal = () =>{
                if( stepOneData.value.ts_companyTitle === ''){
                    showNotify({type:'warning', message:'请选择投诉对象'});
                    return;
                }
                appealShow.value = true;
            };
            const chooseConfirm = (type) =>{
                if(type === 'issue'){
                    issueShow.value = false;
                    stepOneData.value.ts_issue = stepOneData.value.selectedIssue.join(',')
                }else{
                    appealShow.value = false;
                    stepOneData.value.ts_appeal = stepOneData.value.selectedAppeal.join(',')
                }
            };
            const fileList = ref([]);
            const afterRead = (file) =>{
                console.log(file);
            };
            const cancelBtn = () =>{
                showConfirmDialog({
                    title: '确定放弃投诉吗？',
                }).then(()=>{
                    router.push('/m_user');
                }).catch(()=>{
                    return;
                });
                // router.push('/m_index');
            };
            const toStep2 = () =>{
                active.value +=1;
            };
            const userComplaint = () =>{
                let formData = new FormData();
                fileList.value.forEach(file =>{
                    //multipartFiles 后台接收名字
                    formData.append("multipartFiles",file.file)
                });
                formData.append('info',JSON.stringify(stepOneData.value))
                formData.append('content',JSON.stringify(stepTwoData.value))

                complaint(formData).then(res =>{
                    console.log(res)
                    if(res.status === 200 && res.data.code === 200001){
                        active.value +=1;
                    }
                }).catch(error =>{
                    if(error.response.status === 403 || error.response.status === 401){
                        showNotify({message:"登录超时，请重新登录！",type:'warning'})
                        router.push('/m_login')
                    }else{
                        showNotify({type:'warning',message:error.message})
                    }

                });
            };
            const clearTitle = () =>{
                stepTwoData.value.tsTitle = '';
            };
            const clearContent = () =>{
                stepTwoData.value.tsContent = '';
            };
            const beforeRead = (file) =>{
                console.log("上传前", file)
                const imgformat = /image\/(png|jpg|jpeg)$/
                if(!imgformat.test(file.type)){
                    showNotify({type:'warning',message:'请上传　jpg/jpeg/png格式图片！'})
                    return false
                }
                if(file.size > 1*1024*1024){
                    showNotify({type:'warning',message:'单张图片大小不能超过　1M！'})
                    return false
                }
                return true
            };
            return {clearTitle,clearContent,fileList,afterRead,backStepOne,stepTwoData,addCompanyName,chooseConfirm,defineAppealVisible,newAppeal,handleAddAppeal,handleAddIssue,newIssue,defineIssueVisible,handleDefineSelected,defineAppeals,defineIssues,addSelected,issueResult,appealResult,selectItem,getIssuesAndAppeal,issueShow,appealShow,showIssue,showAppeal,active,toStep2,userComplaint,cancelBtn,searchCompanyShow,stepOneData,showCompanySearch,tmpCompanyName,searchCompany,closeSearchCompanyShow,searchResult,resultShow,inputCompanyName,beforeRead};
        }
    }
</script>

<style scoped>
    /*.van-field{*/
    /*    background: #fff;*/
    /*}*/
    :deep(.van-step--horizontal .van-step__title){
        float: left;
    }
    .search-result .nodata{
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #409EFF;
    }
    .nodata-desc{
        padding-left: 10px;
        color: #5D5D5D;
    }
    .nodataBtn{
        padding-right: 10px;
    }
    li{
        list-style: none;
        border-top: 1px solid #d6d6d6;
        padding-left: 10px;
        text-align: left;
        line-height: 1rem;
        font-size: 0.4rem;
        color: black;
    }
    a{
        text-decoration: none;
    }
    .head-popup{
        line-height: 1.22rem;
        padding: 0 0.32rem;
        align-items: center;
        background-color: #212121;
        display: flex;
        justify-content: space-between;
    }
    .head-popup a:first-child{
        color: #ffffff;
        font-size: 0.3rem;
        /*opacity: .5;*/
    }
    .head-popup a:last-child{
        color: #409EFF;
        font-size: 0.4rem;
        /*opacity: .5;*/
    }
    .head-popup span{
        color: #D6D6D6;
        font-size: 0.4rem;
    }
    .van-tag{
        font-size: 0.4rem;
        float: left;
        margin: 0.2rem 0.3rem;
    }
    .issue-item .item{
        display: inline-block;
        margin: 0.2rem 0.3rem;
        font-size: 0.4rem;
        border: 1px solid #DBDBDB;
        border-radius: 1rem;
        padding: 0.2rem 0.4rem;
        color: #5c5c5c;
        background-color: #fff;
    }
    .appeal-item .item{
        display: inline-block;
        margin: 0.2rem 0.3rem;
        font-size: 0.4rem;
        border: 1px solid #DBDBDB;
        border-radius: 1rem;
        padding: 0.2rem 0.3rem;
        color: #5c5c5c;
        background-color: #fff;
    }
    .issue-item .item-selected{
        background-color: var(--el-color-primary-light-5);
        color: #ffffff;
    }
    .appeal-item .item-selected{
        background-color: var(--el-color-primary-light-5);
        color: #ffffff;
    }
    section{
        margin-bottom: 0.3rem;
        background-color: #fff;
    }
    .van-cell-group{
        /*--van-cell-group-background : #fff;*/
        margin-bottom: 0.2rem;
    }
    .van-cell{
        opacity: 0.8;
    }
    .preview-cover {
        position: absolute;
        bottom: 0;
        box-sizing: border-box;
        width: 100%;
        padding: 4px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background: rgba(0, 0, 0, 0.3);
    }
    .btn-row{
        display: flex;
        justify-content: space-around;
    }
</style>
