import {request} from '@/utils/request'
export const saveWorkOrder = data =>{
    return request({
        method: 'POST',
        url: 'saveWorkOrder/',
        data
    })
}

export const getWorkOrders = data =>{
    return request({
        method:'POST',
        url: 'queryWorkOrderList/',
        data
    })
}
