<template>
    <van-nav-bar title="热点资讯" left-arrow right-text="返回首页" @click-left="onClickLeft" @click-right="onClickRight"/>
    <van-tabs v-model:active="active" sticky swipeable class="m-article-c">
        <van-tab title="最热">
            <van-pull-refresh v-model="hotNewsRefreshing" @refresh="onRefreshHotNews" disabled>
                <van-list v-model:loading="hotNewsLoading"
                          :finished="hotNewsFinished"
                          finished-text="我是有底线的"
                          offset="100"
                          :immediate-check="false"
                          @load="onLoadHotNews">
                        <div class="article-con" v-for="item in hotNewsList" :key="item" @click="showArticle(item.article_id)">
                            <div class="left-img">
                                <van-image :src="item.thumb" width="3rem">
                                    <template v-slot:error>加载失败</template>
                                </van-image>
                            </div>
                            <div class="right-con">
                                <h3 class="article-tit van-multi-ellipsis--l2">{{item.title}}</h3>
                                <p class="article-date">{{item.time}}</p>
                            </div>
                        </div>

                </van-list>
            </van-pull-refresh>
        </van-tab>
        <van-tab title="行业研究">
            <van-pull-refresh v-model="industryRefreshing" @refresh="onRefreshIndustry">
                <van-list v-model:loading ="industryLoading"
                          :finished="industryFinished"
                          finished-text="我是有底线的"
                          offset="100"
                          :immediate-check="false"
                          @load="onLoadIndustry">
                    <div class="article-con" v-for="item in industryList" :key="item" @click="showArticle(item.article_id)">
                        <div class="left-img">
                            <van-image :src="item.thumb" width="3rem">
                                <template v-slot:error>加载失败</template>
                            </van-image>
                        </div>
                        <div class="right-con">
                            <h3 class="article-tit van-multi-ellipsis--l2">{{item.title}}</h3>
                            <p class="article-date">{{item.time}}</p>
                        </div>
                    </div>

                </van-list>
            </van-pull-refresh>
        </van-tab>
        <van-tab title="投资者教育">
            <van-pull-refresh v-model="educateRefreshing" @refresh="onRefreshEducate" disabled>
                <van-list v-model:loading="educateLoading"
                          :finished="educateFinished"
                          finished-text="我是有底线的"
                          offset="100"
                          :immediate-check="false"
                          @load="onLoadEducate">
                    <div class="article-con" v-for="item in educateList" :key="item" @click="showArticle(item.article_id)">
                        <div class="left-img">
                            <van-image :src="item.thumb" width="3rem">
                                <template v-slot:error>加载失败</template>
                            </van-image>
                        </div>
                        <div class="right-con">
                            <h3 class="article-tit van-multi-ellipsis--l2">{{item.title}}</h3>
                            <p class="article-date">{{item.time}}</p>
                        </div>
                    </div>

                </van-list>
            </van-pull-refresh>
        </van-tab>
    </van-tabs>

</template>

<script>
    import {ref,onMounted} from 'vue';
    import {articleList} from '@/api/article';
    import {useRouter} from 'vue-router';
    export default {
        name: "m_article",
        setup(){
            const router = useRouter();
            const active = ref(0);
            const hotNewsList = ref([])
            const hotNewsRefreshing = ref(false);
            const hotNewsFinished = ref(false);
            const hotNewsLoading = ref(true);
            const hotNewsQueryParams = ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                cate:0
            });
            const onRefreshHotNews = () =>{
                hotNewsFinished.value = false;
                hotNewsLoading.value = true;
                onLoadHotNews();
            };
            const onLoadHotNews = async () =>{
                hotNewsQueryParams.value.page +=1;
                await setTimeout(()=> {
                    articleList(hotNewsQueryParams.value).then(res => {
                        if (res.status === 200 && res.data.code === 200001) {
                            let tlist = res.data.data.articleData;
                            let curPageInfo = res.data.data.pageInfo;
                            tlist.forEach((item)=>{
                                hotNewsList.value.push(item);
                            });
                            hotNewsLoading.value = false;
                            hotNewsQueryParams.value.page = curPageInfo.page;
                            hotNewsQueryParams.value.totalPage = curPageInfo.totalPage;

                            if(tlist.length < 10 || hotNewsQueryParams.value.page === hotNewsQueryParams.value.totalPage){
                                hotNewsFinished.value = true;
                            }
                        }
                        // if (this.articleList.length < 1) {
                        //     this.noDataShow = true
                        // }
                    });
                },1000);
            };

            const industryList = ref([])
            const industryRefreshing = ref(false);
            const industryFinished = ref(false);
            const industryLoading = ref(false);
            const industryQueryParams = ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                cate:1
            });
            const onRefreshIndustry = () =>{
                industryFinished.value = false;
                industryLoading.value = true;
                onLoadIndustry();
            };
            const onLoadIndustry = async () =>{
                if(industryRefreshing.value){
                    industryList.value = [];
                    industryRefreshing.value = false;
                }
                console.log('onLoadIndustry........')
                industryQueryParams.value.page += 1;
                await setTimeout(()=> {
                     articleList(industryQueryParams.value).then( res => {
                         industryLoading.value = false;
                        if (res.status === 200 && res.data.code === 200001) {
                            let tlist = res.data.data.articleData;
                            let curPageInfo = res.data.data.pageInfo;
                            if(tlist.length < 10 || curPageInfo.page === curPageInfo.totalPage){
                                industryFinished.value = true;
                            }
                            tlist.forEach((item)=>{
                                industryList.value.push(item);
                            });
                            industryQueryParams.value.page = curPageInfo.page;
                            industryQueryParams.value.totalPage = curPageInfo.totalPage;
                            // industryLoading.value = false;
                        }
                    });
                },1000);
            };

            const educateList = ref([])
            const educateRefreshing = ref(false);
            const educateFinished = ref(false);
            const educateLoading = ref(false);
            const educateQueryParams = ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                cate:2
            });
            const onRefreshEducate = () =>{
                educateFinished.value = false;
                educateLoading.value = true;
                onLoadEducate();
            };
            const onLoadEducate = async () =>{
                educateQueryParams.value.page += 1;
                await setTimeout(()=> {
                    articleList(educateQueryParams.value).then(res => {
                        if (res.status === 200 && res.data.code === 200001) {
                            let tlist = res.data.data.articleData;
                            let curPageInfo = res.data.data.pageInfo;
                            tlist.forEach((item)=>{
                                educateList.value.push(item);
                            });
                            educateQueryParams.value.page = curPageInfo.page;
                            educateQueryParams.value.totalPage = curPageInfo.totalPage;
                            educateLoading.value = false;
                            if(tlist.length < 10 || educateQueryParams.value.page === educateQueryParams.value.totalPage){
                                educateFinished.value = true;
                            }
                        }
                    });
                },1000);
            };
            const onClickLeft = () => history.back();
            const onClickRight = () =>{
                router.push('/m_index');
            };
            const showArticle = (id) =>{
                router.push({name:'m_articleShow',params:{articleId:id}})
            };
            onMounted(()=>{
                onLoadHotNews();
            });
            return {showArticle,onClickRight,router,onClickLeft,educateList,educateRefreshing,educateFinished,educateLoading,onRefreshEducate,onLoadEducate,industryList,industryRefreshing,industryFinished,industryLoading,onRefreshIndustry,onLoadIndustry,active,hotNewsList,hotNewsRefreshing,hotNewsFinished,hotNewsLoading,onRefreshHotNews,onLoadHotNews,hotNewsQueryParams};
        }
    }
</script>

<style scoped>
    .m-article-c{
        height: calc(100vh - 90px);
        overflow: scroll;
        box-sizing: border-box;
    }
    .article-con{
        display: flex;
        padding: 0.4rem;
        margin-bottom: 0.3rem;
        background: #fff;
    }
    .article-con .left-img{
        display: block;
        margin-right: 0.2rem;
    }
    .right-con{
        text-align: left;
        padding: 0.1rem 0.3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .article-tit{
        margin-block-start: 0;
        margin-block-end: 0;
        font-size: 0.4rem;
        font-weight: bold;
        word-wrap: break-word;
    }
    .article-date{
        margin-block-start: 0;
        margin-block-end: 0;
        font-size: 0.3rem;
        color: #c9c9c9;
    }
    .van-image{
        width: 3rem;
        height: 2rem;
        overflow: hidden;
    }
    :deep(.van-image__img){
        height: unset!important;
    }
</style>
