import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import m_router from "@/router/m_index"
import store from './store'
import 'amfe-flexible'
import 'element-plus/theme-chalk/base.css'
import 'element-plus/dist/index.css'

// 加载 element 组件库
import ElementPlus from 'element-plus'

import InfiniteScroll from 'element-plus'

// 加载 element 组件库的样式
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {Tabbar,TabbarItem,Icon,NavBar,NoticeBar,Swipe,SwipeItem,List,PullRefresh,Cell,Skeleton,
    SkeletonTitle,
    SkeletonImage,
    SkeletonAvatar,
    SkeletonParagraph,Popover,Grid,GridItem,Tabs,Tab,ConfigProvider,Image as VanImage,
    Form,Field,CellGroup,Button,Checkbox,CheckboxGroup,Notify,Row,Col,Badge,Uploader,
    Step,Steps,Popup,Search,Tag,Dialog,ImagePreview,Picker,Calendar} from 'vant';
import 'vant/lib/index.css';
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
if(/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(navigator.userAgent)){
    app.use(Tabbar).use(TabbarItem).use(Icon).use(NavBar).use(NoticeBar).use(Swipe).use(SwipeItem).use(List).use(PullRefresh).use(Cell);
    app.use(Skeleton).use(SkeletonTitle).use(SkeletonImage).use(SkeletonAvatar).use(SkeletonParagraph).use(Popover);
    app.use(Grid).use(GridItem);
    app.use(Tab).use(Tabs);
    app.use(ConfigProvider);
    app.use(VanImage);
    app.use(Form).use(Field).use(CellGroup);
    app.use(Button);
    app.use(Checkbox).use(CheckboxGroup);
    app.use(Notify);
    app.use(Row).use(Col);
    app.use(Badge);
    app.use(Uploader);
    app.use(Step).use(Steps);
    app.use(Popup);
    app.use(Search);
    app.use(Tag);
    app.use(Dialog);
    app.use(ImagePreview);
    app.use(Picker);
    app.use(Calendar);
    app.use(m_router);
}else{
    app.use(router)
}

app.use(store)
app.use(ElementPlus)
app.use(InfiniteScroll)
app.mount('#app')
