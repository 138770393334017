<template>
    <div id="home">
        <Header/>
        <MainBody/>
        <Footer/>
    </div>
</template>

<script>
    import Header from "./common/Header"
    import Footer from "./common/Footer"
    import MainBody from "./common/MainBody";
    export default {
        name: "Home",
        data(){
          return {}
        },
        components: {Footer, Header,MainBody}
    }
</script>

<style scoped>

</style>
