<template>
    <van-nav-bar title="我的资料" left-text="返回" left-arrow @click-left="onClickLeft"/>
    <section class="u-avatar">
            <van-image
                    round
                    width="2rem"
                    height="2rem"
                    :src="curUser.avatar" @click="showBottom=true"/>
    </section>
        <van-cell-group inset>
            <van-cell title="昵称" is-link :value="curUser.nikeName" @click="nikeNameChangeShow = true"/>
            <van-cell title="性别" is-link :value="curUser.gender" @click="showGenderPicker = true">
                <template #value>
                    <span v-if="curUser.gender === '1'">男</span>
                    <span v-else-if="curUser.gender === '2'">女</span>
                    <span v-else>无</span>
                </template>
            </van-cell>
            <van-cell title="生日" is-link :value="curUser.birth" @click="showBirth = true"/>
            <van-cell title="手机" is-link to="/m_stepTwo" :value="curUser.hidPhone"/>
            <van-cell title="密码" is-link to="/m_changePassword" value="修改密码"/>
            <van-cell title="注销用户" is-link @click="userCancel"/>
        </van-cell-group>
    <div style="margin: 16px;">
        <van-button round block color="#e6e6e6" @click="onLogout">
            退出登录
        </van-button>
    </div>
    <van-popup
            v-model:show="showGenderPicker"
            position="bottom"
            :style="{ height: '20%' }"
            safe-area-inset-bottom
    >
        <van-picker
                title="性别"
                :columns="genders"
                @confirm="onConfirmGender"
                @cancel="showGenderPicker = false"
                visible-option-num="2"
        />
    </van-popup>
    <van-calendar v-model:show="showBirth" show-confirm :min-date="minDate" @confirm="onConfirmBirth" />
    <van-popup
            v-model:show="showBottom"
            position="bottom"
            :style="{ height: '50%' }"
    >
        <div class="head-popup">
            <a href="javascript:;" @click="showBottom=false">取消</a>
            <span>修改头像</span>
            <a href="javascript:;" @click="selectAvatar">确认</a>
        </div>
        <section class="choiceImgs">
            <van-image :class="{'avatar-item':true,'selected': key===selectedAvatarInd}" :src="require('../../../assets/avatars'+item.slice(1))" v-for="(item,key) in avatarList" :key="item" @click="handleSelectAvatar(item,key)"/>
        </section>
    </van-popup>
    <van-popup v-model:show="nikeNameChangeShow" position="top" style="height: 100%;">

        <div class="head-popup">
            <a href="javascript:;" @click="nikeNameChangeShow=false">取消</a>
            <span>修改昵称</span>
            <a href="javascript:;" @click="changeNikename">确认</a>
        </div>
        <van-form ref="registerRef" @submit="onSubmit">
        <van-field v-model="newNikename" name="newNikename" maxlength="20" show-word-limit
                   :rules="[{pattern:/^[\u4e00-\u9fa5A-Za-z0-9_-‧]{4,20}$/,message: '4-20个字符，可由中英文、数字、“-”、“_”组成'},
                   {validator:nameInRules,message: '该昵称已被占用'}]"/>
        <span>4-20个字符，可由中英文、数字、“-”、“_”组成</span>
        </van-form>
    </van-popup>
    <van-popup v-model:show="showUserCancelPopup" position="top" style="height: 100%">
        <div class="head-popup">
            <a href="javascript:;" @click="showUserCancelPopup=false">取消</a>
            <span>注销用户</span>
            <router-link to="/m_user">返回主页</router-link>
        </div>
        <van-form ref="userCancelFormRef" @submit="onSubmitCancel">
            <van-cell-group>
                <van-field label="手机号" v-model="curUser.hidPhone" disabled />
                <van-field
                        v-model="shorMsgForm.captcha"
                        center
                        clearable
                        name="captcha"
                        placeholder="请输入图片验证码"
                        :rules="[{ required: true, message: '请填写验证码'}]"
                >
                    <template #right-icon>
                        <van-image :src="captchaUrl" @click="refresh"/>
                    </template>
                </van-field>
                <van-field
                        v-model="shortMsg"
                        center
                        clearable
                        label="短信验证码"
                        placeholder="请输入短信验证码"
                >
                    <template #button>
                        <van-button size="small" type="primary" :disabled="smsBtnDis" @click="sendMsg" >{{smsBtnContent}}</van-button>
                    </template>
                </van-field>
            </van-cell-group>
            <div style="margin: 16px;display: flex;justify-content: space-around">
                <span style="color: red;font-size: 0.4rem;text-align: left;margin-bottom: 0.3rem;">*请注意：账号注销完成后，此手机号在7日内不能再注册新用户。</span>
            </div>
            <div style="margin: 16px;display: flex;justify-content: space-around">
                <van-button block round type="primary" native-type="submit" >
                    注销用户
                </van-button>
            </div>
        </van-form>
    </van-popup>
</template>

<script>
    import {ref} from 'vue';
    import {changeBaseInfo,loadUserInfo,notFinishedCount,sendmsg,getCaptcha,userCancel as submitUserCancel,changeAvatar,validateNameInRules} from '@/api/user';
    import {showNotify} from 'vant';
    import webStorage from "@/utils/webStorage.js";
    import {useRouter} from 'vue-router';
    export default {
        name: "m_userInfo",
        components: {},
        setup(){
            const avatarList = ref([]);
            const selectedAvatarInd = ref(null);
            const curUser = ref({
                nikeName: '',
                phoneNo: '',
                avatar: '',
                category:'',
                hidPhone: '',
                gender:''
            });
            const router = useRouter();
            const fileList = ref([]);
            const fileList1 = ref([]);
            const showBottom = ref(false);
            const showCropper = ref(false);
            const showGenderPicker = ref(false);
            const onClickLeft = () => history.back();
            const genders = [{text:'男',value:1},{text:'女',value: 2}];
            const onConfirmGender = ({ selectedOptions }) =>{
                changeBaseInfo({gender:selectedOptions[0].value}).then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        showNotify({message:"信息修改成功",type:"success"});
                        loadUserInfo().then(res1 =>{
                            if(res1.status === 200 && res1.data.code === 200001){
                                webStorage.setItem('localStorage','user',JSON.stringify(res1.data.data),60000*60*2);
                                refreshUserInfo();
                            }
                        });
                        showGenderPicker.value=false;
                    }
                })
            };
            const showBirth = ref(false);
            const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            const onConfirmBirth = (value) =>{
                showBirth.value = false;
                curUser.value.birth = formatDate(value);
                changeBaseInfo({birth:formatDate(value)}).then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        showNotify({message:"信息修改成功",type:"success"});
                        loadUserInfo().then(res1 =>{
                            if(res1.status === 200 && res1.data.code === 200001){
                                webStorage.setItem('localStorage','user',JSON.stringify(res1.data.data),60000*60*2);
                                refreshUserInfo();
                            }
                        });
                        showBirth.value=false;
                    }
                })
            };
            const newNikename = ref(curUser.value.nikeName);
            const nikeNameChangeShow = ref(false);
            const changeNikename = () =>{
                curUser.value.nikeName = newNikename.value;
                changeBaseInfo({nikeName:newNikename.value}).then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        showNotify({message:"信息修改成功",type:"success"});
                        loadUserInfo().then(res1 =>{
                            if(res1.status === 200 && res1.data.code === 200001){
                                webStorage.setItem('localStorage','user',JSON.stringify(res1.data.data),60000*60*2);
                                refreshUserInfo();
                            }
                        });
                        nikeNameChangeShow.value=false;
                    }
                })
            };
            const onLogout = () =>{
                webStorage.removeItem('localStorage','user');
                webStorage.removeItem('localStorage','token');
                webStorage.removeItem('localStorage','username');
                webStorage.removeItem('localStorage','uCompany');
                router.push('/m_index');
            };
            const loading = ref(false);
            let imgData = '';
            const file_name = ref('');
            const showUserCancelPopup = ref(false);
            const shorMsgForm = ref({
                mobile:'',
                captcha: '',
                hashkey: '',
                requestType:'validMobile'
            });
            // const captcha = ref('');
            const captchaUrl = ref('');
            // const hashkey = ref('');
            const refresh = () =>{
                getCaptcha().then(res => {
                    shorMsgForm.value.hashkey=res.data.data.hashkey;
                    captchaUrl.value = res.data.data.image_url;
                }).catch(() => {
                    // 登录失败
                    showNotify({message:'获取验证码失败', type:'error'})
                    // 关闭 loading
                    // loginLoading.value = false
                })
            };
            const userCancel = () =>{
                notFinishedCount().then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        refresh();
                        showUserCancelPopup.value = true;
                    }else{
                        showNotify({message:res.data.message,type:"warning"})
                    }
                    // console.log(res);
                    // refresh();
                    // showUserCancelPopup.value = true;
                });
            };

            const smsBtnDis = ref(false);
            const smsBtnContent = ref("获取验证码");
            const count = ref(0);
            const timer = ref(0);
            const shortMsg = ref('');
            const sendMsg = () =>{
                sendmsg(shorMsgForm.value).then(res =>{
                    console.log(res)
                    if(res.status===200 && res.data.code===200001){
                        smsBtnDis.value = true;
                        const TIME_COUNT = ref(60);
                        count.value = TIME_COUNT.value;
                        timer.value = window.setInterval(() => {
                            if (count.value > 0 && count.value <= TIME_COUNT.value){
                                // 倒计时时不可点击
                                smsBtnDis.value = true;
                                // 计时秒数
                                count.value--;
                                // 更新按钮的文字内容
                                smsBtnContent.value = count.value + 's后重新获取';
                            } else {
                                // 倒计时完，可点击
                                smsBtnDis.value = false;
                                // 更新按钮文字内容
                                smsBtnContent.value = '获取短信';
                                // 清空定时器!!!
                                clearInterval(timer);
                                timer.value = null;
                            }
                        }, 1000)
                    }else{
                        console.log(res.data)
                        showNotify({type:'warning', message:res.data.message});
                    }
                });
            };
            const onSubmitCancel = () =>{
                submitUserCancel({shortMsg:shortMsg}).then(res =>{
                    if(res.status===200 && res.data.code===200001){
                        showNotify({type:'success',message:'用户注销成功！'});
                        onLogout();
                    }else if(res.data.code===300003){
                        showNotify({type:'warning',message:res.data.message});
                    }else{
                        showNotify({type:'warning',message:'用户注销失败！'});
                    }
                });
            };
            const selectedAvatarName = ref('');
            const handleSelectAvatar = (item,ind) =>{
                selectedAvatarInd.value = ind;
                selectedAvatarName.value = item.slice(1);
                // console.log(ind+' '+selectedAvatarName.value);

            };
            const selectAvatar = () =>{
                changeAvatar({fileName:selectedAvatarName.value}).then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        loadUserInfo().then(res1 =>{
                            if(res1.status === 200 && res1.data.code === 200001){
                                webStorage.setItem('localStorage','user',JSON.stringify(res1.data.data),60000*60*2);
                                refreshUserInfo();
                            }
                        });
                        showBottom.value = false;
                        showNotify({type:'success',message:'头像修改成功'})
                    }else{
                        showNotify({type:'warning',message:'头像修改失败，请稍候再试！'})
                    }
                })
            };
            const refreshUserInfo = () =>{
                let userinfo = JSON.parse(webStorage.getItem('localStorage', 'user'));
                curUser.value.nikeName = userinfo.nike_name;
                newNikename.value = userinfo.nike_name;
                curUser.value.phoneNo = userinfo.phone;
                shorMsgForm.value.mobile = userinfo.phone;
                curUser.value.avatar = userinfo.avatar;
                curUser.value.gender = userinfo.gender;
                curUser.value.birth = userinfo.birth;
                // if(userinfo.hidIdcard){
                //     curUser.value.idcard = userinfo.hidIdcard;
                // }
                curUser.value.category = userinfo.category;
                curUser.value.hidPhone = userinfo.hidPhone;
            };
            const nameInRules = (val) =>
                new Promise((resolve) => {
                    setTimeout(() => {
                        validateNameInRules({'content':val}).then(res =>{
                            let ret = JSON.parse(res.data);
                            if(ret.code === "200" ) {
                                resolve(ret.flag !== "1")
                            }
                        });
                    }, 1000);
                });
            return {nameInRules,refreshUserInfo,selectedAvatarName,selectAvatar,handleSelectAvatar,selectedAvatarInd,avatarList,onSubmitCancel,shorMsgForm,captchaUrl,refresh,shortMsg,smsBtnDis,smsBtnContent,count,timer,sendMsg,showUserCancelPopup,userCancel,file_name,loading,imgData,showCropper,curUser,onClickLeft,showBottom,fileList,fileList1,showGenderPicker,genders,onConfirmGender,showBirth,onConfirmBirth,formatDate,minDate: new Date(2010, 0, 1),onLogout,nikeNameChangeShow,changeNikename,newNikename};
        },
        methods: {

        },
        mounted() {
            const files = require.context("@/assets/avatars",true,/\.*\.jpg|jpeg|png$/).keys();
            this.avatarList = files;
            this.refreshUserInfo();
        }

    }
</script>

<style scoped>
    .u-avatar{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0.3rem;
    }
    .item{
        display: flex;
        justify-content: space-between;
        line-height: 1rem;
        font-size: 0.4rem;
        background-color: #ffffff;
        padding: 0 0.2rem;
    }
    :deep(.van-cell__title){
        float: left;
        text-align: left;
    }
    .head-popup{
        line-height: 1.22rem;
        padding: 0 0.32rem;
        align-items: center;
        background-color: #212121;
        display: flex;
        justify-content: space-between;
    }
    .head-popup a:first-child{
        color: #ffffff;
        font-size: 0.3rem;
        /*opacity: .5;*/
    }
    .head-popup a:last-child{
        color: #409EFF;
        font-size: 0.4rem;
        /*opacity: .5;*/
    }
    .head-popup span{
        color: #D6D6D6;
        font-size: 0.4rem;
    }


    :deep(.van-uploader__input-wrapper){
        width: 100%;
    }
    .font14{
        font-size: 0.6rem;
    }
    .avatar-item{
        width: 80px;
        height: 80px;
        padding: 5px;
    }
    .selected{
        border: 2px solid var(--el-color-primary);
    }
    .choiceImgs{
        height: calc(100% - 30px);
        overflow: auto;
        position: relative;
    }
    :deep(.van-cell__value){
        min-width: 70%;
    }
</style>
