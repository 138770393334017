<template>
    <div class="my-setting">
        <div class="subnav">
            <a href="/" title="投诉">首页</a>
            <span>> 设置</span>
        </div>
        <div class="setting-body">
            <el-col :span="4" style="height: 700px">
                <el-menu default-active="1" @select="handleSelect">
<!--                    <h2 class="">设置</h2>-->
                    <el-menu-item index="1">
<!--                        <el-icon></el-icon>-->
                        <span>编辑资料</span>
                    </el-menu-item>
                    <el-menu-item index="2">
<!--                        <el-icon></el-icon>-->
                        <span>手机号修改</span>
                    </el-menu-item>
                    <el-menu-item index="3">
<!--                        <el-icon></el-icon>-->
                        <span>密码修改</span>
                    </el-menu-item>
                    <el-menu-item index="4">
<!--                        <el-icon></el-icon>-->
                        <span>用户注销</span>
                    </el-menu-item>
                </el-menu>
            </el-col>
            <div class="line-1"></div>
            <el-col :span="19">
                <div class="user-info" v-show="userInfoShow">
<!--                    <div class="title">-->
<!--                        <h3>帐号信息设置</h3>-->
<!--                    </div>-->
                    <div class="info-detail">
                        <el-form
                                label-width="120px"
                                ref="baseInfoFormRef"
                                :model="userInfo"
                                :rules="baseInfoRule"
                        >
                            <el-form-item size="large" label="选择头像:">
                                <el-avatar :src="userInfo.avatar" size="medium" @click="uploadPicture('flagImg')"></el-avatar>
                            </el-form-item>
                            <el-form-item size="large" label="昵称(选填):" prop="nikeName" >
                                <el-input size="large" v-model="userInfo.nikeName" @change="handleBaseInfoChange($event,'nikeName')" maxlength="20" show-word-limit />
                            </el-form-item>
                            <el-form-item size="large" label="性别(选填):">
                                <el-radio-group size="large" v-model="userInfo.gender" class="ml-4" @change="handleBaseInfoChange($event,'gender')">
                                    <el-radio label="1" size="large">男</el-radio>
                                    <el-radio label="2" size="large">女</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item size="large" label="生日(选填):">
                                <el-date-picker
                                        v-model="userInfo.birth"
                                        type="date"
                                        placeholder="选择日期"
                                        size="large"
                                        value-format="YYYY-MM-DD"
                                        @change="handleBaseInfoChange($event,'birth')"
                                />
                            </el-form-item>
<!--                            <el-form-item size="large" label="身份证号(选填):">-->
<!--    &lt;!&ndash;                            <el-col :span="24">&ndash;&gt;-->
<!--                                <el-input size="large" v-model="userInfo.idcard" @change="handleBaseInfoChange($event,'idcard')"/>-->
<!--    &lt;!&ndash;                            </el-col>&ndash;&gt;-->
<!--                            </el-form-item>-->
                            <el-form-item>
                                <el-button type="primary"  @click="saveBaseInfo" >保存</el-button>
                                <el-button>取消</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="mobile-change" v-show="mobileChangeShow">
                    <div class="mobile-detail">
                        <el-form
                                label-width="120px"
                                ref="mobileFormRef"
                                :rules="mobileFormRule"
                                :model="mobileForm">
                            <el-form-item size="large" label="原始手机号:">
                                <span>{{userInfo.hidPhone}}</span>
                            </el-form-item>
                            <el-form-item size="large" label="新手机号:" prop="mobile">
                                <el-input size="large" v-model="mobileForm.mobile" placeholder="请输入新手机号"/>
                            </el-form-item>
                            <el-form-item size="large" label="图片验证码:" prop="captcha">
                                <el-col :span="16">
                                    <el-input size="large" v-model="mobileForm.captcha" placeholder="输入图片验证码"/>
                                </el-col>
                                <el-col :span="8">
                                    <el-image :src="captchaUrl"  @click="refresh"/>
                                </el-col>
                            </el-form-item>
                            <el-form-item size="large" label="短信验证码:" prop="shortMsg">
                                <el-col :span="16">
                                    <el-input size="large" v-model="mobileForm.shortMsg" placeholder="输入短信验证码"/>
                                </el-col>
                                <el-col :span="8">
                                    <el-button type="primary" size="large" :disabled="smsBtnDis" @click="getShortMsg('changeMobile')">{{smsBtnContent}}</el-button>
                                </el-col>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="changeMobile">保存</el-button>
                                <el-button>取消</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="pwd-change" v-show="pwdChangeShow">
                    <div class="pwd-detail">
                        <el-form label-width="120px"
                                 ref="pwdFormRef"
                                 :model="pwdForm"
                                 :rules="pwdFormRule">
                            <el-form-item size="large" label="原始密码:" prop="passwd">
                                <el-col :span="24">
                                    <el-input size="large" type="password" v-model="pwdForm.passwd" placeholder="请输入原始密码"/>
                                </el-col>
                            </el-form-item>
                            <el-form-item size="large" label="新密码:" prop="newPasswd">
                                <el-input size="large" type="password" v-model="pwdForm.newPasswd" placeholder="8~30位大小字母、数字、特殊字符"/>
                            </el-form-item>
                            <el-form-item size="large" label="确认密码:" prop="confirePwd">
                                <el-input size="large" type="password" v-model="pwdForm.confirePwd" placeholder="请输入确认密码"/>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="changePwd">保存</el-button>
                                <el-button>取消</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="pwd-change" v-show="userCancelShow">
                    <div class="pwd-detail">
                        <el-form>
                            <el-form-item size="large" label="原始手机号:">
                                <span>{{userInfo.hidPhone}}</span>
                            </el-form-item>
                            <el-form-item size="large" label="图片验证码:" prop="captcha">
                                <el-col :span="16">
                                    <el-input size="large" v-model="userCancelForm.captcha" placeholder="输入图片验证码"/>
                                </el-col>
                                <el-col :span="8">
                                    <el-image size="large" :src="captchaUrl"  @click="refresh"/>
                                </el-col>
                            </el-form-item>
                            <el-form-item size="large" label="短信验证码:" prop="shortMsg">
                                <el-col :span="16">
                                    <el-input size="large" v-model="userCancelForm.shortMsg" placeholder="输入短信验证码"/>
                                </el-col>
                                <el-col :span="8">
                                    <el-button type="primary" size="large" :disabled="smsBtnDis" @click="getShortMsg('validMobile')">{{smsBtnContent}}</el-button>
                                </el-col>
                            </el-form-item>
                            <span style="color: red;display: flex;justify-content: flex-start;margin-bottom: 10px;">*请注意：账号注销完成后，此手机号在7日内不能再注册新用户。</span>
                            <el-form-item>
                                <el-button>取消</el-button>
                                <el-button type="primary" @click="userCancelSubmit">注销</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-col>
            <!-- 剪裁组件弹窗 -->
            <el-dialog
                    title="选择头像"
                    v-model.propName="cropperModel"
                    width="950px"
                    center
                    class="change-avatar"
            >
                <span>温馨提示：单击只会选中头像，双击修改头像！</span>
                <div>
                    <img :class="{'avatar-item':true,'selected': key===selectedAvatarInd}" :src="require('../../assets/avatars'+item.slice(1))" v-for="(item,key) in avatarList" :key="item" @click="handleSelectAvatar(key)" @dblclick="selectAvatar(item)" />
                </div>
            </el-dialog>
        </div>
    </div>
    <el-dialog v-model="loginDialogVisible" title="登录" width="400" show-close="false" align-center>
        <pcLoginComponent @loginEnd="loginEnd" />
    </el-dialog>
    <el-dialog v-model="importantTipsVisible" title="重要提示" width="400" show-close="false" align-center>
        登录失效，请点击"确定"按钮重新登录！
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="importantTipsVisible = false">取消</el-button>
            <el-button type="primary" @click="toNext">
              确定
            </el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script>
    import {loadUserInfo, sendmsg,changeBaseInfo,changeMobile,changePwd,getCaptcha,userCancel,changeAvatar,notFinishedCount,checkPhoneRepied,validateNameInRules} from '@/api/user';
    import {ElMessage} from 'element-plus';
    import webStorage from "@/utils/webStorage";
    import pcLoginComponent from "@/views/login/pcLoginComponent";
    export default {
        name: "setting",
        components:{pcLoginComponent},
        data(){
            return{
                loginDialogVisible: false,
                importantTipsVisible: false,
                avatarList: [],
                selectedAvatarInd:null,
                captchaUrl: null,
                // pwdFormRef: null,
                userInfoShow: true,
                pwdChangeShow: false,
                mobileChangeShow: false,
                userCancelShow: false,
                smsBtnContent:'获取短信',
                smsBtnDis: false,
                saveBaseBtnDisable: true,
                saveMobileBtnDisable: true,
                timer:0,
                count:0,
                cropperModel:false,
                cropperName:'flagImg',
                imgName: '',
                imgVisible: false,
                dialogVisible: false,
                userInfo:{
                    nikeName:'',
                    gender:0,
                    birth:'',
                    // idcard:'',
                    phoneNo:'',
                    avatar:'',
                    hidPhone:''
                },
                changeInfos:{},
                phoneNo:'',
                pwdForm:{
                    passwd:'',
                    newPasswd:'',
                    confirePwd:''
                },
                mobileForm:{
                    mobile: '',
                    captcha: '',
                    hashkey: '',
                    // newPhoneNo:'',
                    shortMsg:'',
                    requestType:'changeMobile'
                },
                userCancelForm:{
                    mobile:'',
                    captcha: '',
                    hashkey: '',
                    shortMsg:'',
                    requestType:'validMobile'
                },
                baseInfoRule:{
                    nikeName:[{pattern:/^[\u4e00-\u9fa5A-Za-z0-9_-‧]{4,20}$/,message: '4-20个字符，可由中英文、数字、“-”、“_”组成'},
                        {trigger:'blur',validator:(rule,value,callback) =>{
                            if(value){
                                validateNameInRules({'content':value}).then(res =>{
                                    let ret = JSON.parse(res.data);
                                    if(ret.code === "200" && ret.flag === "1"){
                                        callback(new Error('该昵称已被占用'))
                                    }else{
                                        callback()
                                    }
                                });
                            }
                        }
                    }]
                },
                mobileFormRule:{
                    mobile: [
                        { required: true,  trigger: 'blur',validator:(rule, value, callback) =>{
                            var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                            if (!myreg.test(value)) {
                                callback(new Error('请输入正确的手机号！'));
                            } else {
                                checkPhoneRepied({mobile:value}).then(res =>{
                                    if(res.status===200 && res.data.code===200001){
                                        callback()
                                    }else{
                                        callback(new Error(res.data.message))
                                    }
                                })
                            }
                        } },
                        // { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
                    ],
                    captcha: [
                        { required: true,  trigger: 'blur', message: '图形验证码不能为空' },
                        { pattern: /^[a-zA-Z]{6}$/, message: '请输入正确的验证码', trigger: 'blur'}
                    ],
                    shortMsg: [
                        { required: true, message: '验证码不能为空', trigger: 'blur' },
                        { pattern: /^\d{4}$/, message: '请输入正确的验证码格式', trigger: 'blur' }
                    ],
                },
                pwdFormRule:{
                    passwd: [
                        { required: true, trigger: 'blur',message:'原始密码不能为空'},
                    ],
                    newPasswd: [
                        { required: true, trigger: 'blur', validator: (rule,value,callback)=>{
                                if (value === '') {
                                    callback(new Error('请输入密码'));
                                }
                                var passwdReg = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}$/
                                if(!passwdReg.test(value)){
                                    callback(
                                        new Error("密码必须为8~30位大小字母、数字、特称字符!")
                                    )
                                }else{
                                    callback()
                                }
                            }
                        },
                    ],
                    confirePwd: [
                        { required: true,  trigger: 'blur', validator: (rule,value,callback)=>{
                                if (value === '') {
                                    callback(new Error('请输入确认密码!'));
                                }else if(value != this.pwdForm.newPasswd){
                                    callback(new Error('确认密码和新密码不一致!'));
                                }else{
                                    callback()
                                }
                            }
                        }
                    ],
                }
            }
        },
        created(){
            window.addEventListener('setItem',()=>{
                let curUser = JSON.parse(webStorage.getItem('localStorage','user'))
                this.userInfo.nikeName = curUser.nike_name
                this.userInfo.gender = curUser.gender
                this.userInfo.birth = curUser.birth
                this.userInfo.avatar = curUser.avatar
                this.userInfo.phoneNo = curUser.phone
                this.userInfo.hidPhone = curUser.hidPhone
                // if(curUser.hidIdcard){
                //     this.userInfo.idcard = curUser.hidIdcard
                // }
            });
            this.refresh();
        },
        mounted(){
            const files = require.context("@/assets/avatars",true,/\.*\.jpg|jpeg|png$/).keys();
            this.avatarList = files;
            let curUser = JSON.parse(webStorage.getItem('localStorage','user'));
            this.userInfo.nikeName = curUser.nike_name;
            this.userInfo.gender = curUser.gender;
            this.userInfo.birth = curUser.birth;
            this.userInfo.avatar = curUser.avatar;
            this.userInfo.phoneNo = curUser.phone;
            this.userCancelForm.mobile = curUser.phone;
            this.userInfo.hidPhone = curUser.hidPhone;
            // if(curUser.hidIdcard){
            //     this.userInfo.idcard = curUser.hidIdcard
            // }
        },
        methods: {
            toNext(){
                this.importantTipsVisible = false;
                this.loginDialogVisible = true;
            },
            loginEnd(){
                this.loginDialogVisible = false;
            },
            selectAvatar(item){
                console.log(item.slice(1));
                changeAvatar({fileName:item.slice(1)}).then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        loadUserInfo().then(res1 =>{
                            if(res1.status === 200 && res1.data.code === 200001){
                                webStorage.setItem('localStorage','user',JSON.stringify(res1.data.data),60000*60*2)
                            }
                        });
                        this.cropperModel = false;
                        ElMessage({type:'success',message:'头像修改成功'})
                    }else{
                        ElMessage({type:'warning',message:'头像修改失败，请稍候再试！'})
                    }
                }).catch(error =>{
                    if(error.response.status === 401){
                        // ElMessage({message:'登录超时，请重新登录！',type:"warning"});
                        // this.$router.push('/p_login');
                        this.importantTipsVisible = true;
                    }
                })
            },
            handleSelectAvatar(ind){
                this.selectedAvatarInd = ind;
            },
            handleBaseInfoChange($event,key){
                this.changeInfos[key]= $event
            },
            saveBaseInfo(){
                console.log(this.changeInfos)
                if(Object.keys(this.changeInfos).length !== 0){
                    this.$refs.baseInfoFormRef.validate((valid) =>{
                        if(valid){
                            changeBaseInfo(this.changeInfos).then(res =>{
                                if(res.status === 200 && res.data.code === 200001){
                                    ElMessage({message:"信息修改成功",type:"success"})
                                    loadUserInfo().then(res1 =>{
                                        if(res1.status === 200 && res1.data.code === 200001){
                                            webStorage.setItem('localStorage','user',JSON.stringify(res1.data.data),60000*60*2)
                                        }
                                    });
                                    this.changeInfos = {}
                                }else{
                                    ElMessage({message:res.data.message,type:"warning"})
                                }
                            }).catch(error =>{
                                if(error.response.status === 401){
                                    // ElMessage({message:'登录超时，请重新登录！',type:"warning"});
                                    // this.$router.push('/p_login');
                                    this.importantTipsVisible = true;
                                }
                            })
                        }
                    });

                }else {
                    ElMessage({message:"请更改信息后再提交",type:"warning"})
                }

            },
            changeMobile(){
                let form = this.$refs["mobileFormRef"]
                form.validate(valid =>{
                    if(valid){
                        changeMobile(this.mobileForm).then(res =>{
                            console.log(res)
                            if(res.status === 200 && res.data.code === 200001){
                                ElMessage({message:"手机号修改成功",type:"success"})
                                loadUserInfo().then(res1 =>{
                                    if(res1.status === 200 && res1.data.code === 200001){
                                        webStorage.setItem('localStorage','user',JSON.stringify(res1.data.data),60000*60*2)
                                    }
                                })
                                this.$refs.mobileFormRef.resetFields()
                            }else{
                                ElMessage({message:res.data.message,type:"warning"})
                            }
                        }).catch(error =>{
                            if(error.response.status === 401){
                                // ElMessage({message:'登录超时，请重新登录！',type:"warning"});
                                // this.$router.push('/p_login');
                                this.importantTipsVisible = true;
                            }
                        })
                    }
                })

            },
            changePwd(){
                let form = this.$refs["pwdFormRef"]
                form.validate(valid =>{
                    if(valid){
                        changePwd(this.pwdForm).then(res =>{
                            console.log(res)
                            if(res.status === 200 && res.data.code === 200001){
                                ElMessage({message:"密码修改成功",type:"success"})
                                this.$refs.pwdFormRef.resetFields()
                            }else if(res.data.code === 300001 || res.data.code === 300002){
                                ElMessage({message:res.data.message,type:"warning"})
                            }
                        }).catch(error =>{
                            if(error.response.status === 401){
                                // ElMessage({message:'登录超时，请重新登录！',type:"warning"});
                                // this.$router.push('/p_login');
                                this.importantTipsVisible = true;
                            }
                        })
                    }
                })

            },
            closeAvatarDialog(data) {
                console.log(data)
                this.dialogVisible = false
            },
            //封面设置
            uploadPicture(name){
                this.cropperName = name;
                this.cropperModel = true;
            },
            reflushUserInfo(){
                loadUserInfo().then(res =>{
                    webStorage.setItem('localStorage','user',JSON.stringify(res.data.data),60000*60*2)
                })

            },
            //图片上传成功后
            handleUploadSuccess (data){
                console.log(data)
                switch(data.name){
                    case 'flagImg':
                        // this.formValidate.mainImage = 'http://127.0.0.1:8000/static/'+data.url;
                        this.userInfo.avatar = data.url;
                        console.log('最终输出'+data.name);
                        break;
                }
                this.cropperModel = false;
                this.reflushUserInfo();
                // this.$router.go(0)
            },
            handleSelect(key){
                switch (key) {
                    case '1':
                        this.userInfoShow = true;
                        this.pwdChangeShow = false;
                        this.mobileChangeShow = false;
                        this.userCancelShow = false;
                        break;
                    case '2':
                        this.captchaUrl = '';
                        this.refresh();
                        this.userInfoShow = false;
                        this.pwdChangeShow = false;
                        this.mobileChangeShow = true;
                        this.userCancelShow = false;
                        break;
                    case '3':
                        this.userInfoShow = false;
                        this.pwdChangeShow = true;
                        this.mobileChangeShow = false;
                        this.userCancelShow = false;
                        break;
                    case '4':
                        notFinishedCount().then(res =>{
                            if(res.status === 200 && res.data.code === 200001){
                                this.captchaUrl = '';
                                this.refresh();
                                this.userInfoShow = false;
                                this.pwdChangeShow = false;
                                this.mobileChangeShow = false;
                                this.userCancelShow = true;
                            }else{
                                ElMessage({message:res.data.message,type:"warning"})
                            }
                        });
                        break;
                }
            },
            refresh(){
                getCaptcha().then(res => {
                    this.mobileForm.hashkey=res.data.data.hashkey;
                    this.userCancelForm.hashkey = res.data.data.hashkey;
                    this.captchaUrl=res.data.data.image_url
                }).catch(err => {
                    // 登录失败
                    ElMessage({message:err, type:'error'})
                })
            },
            getShortMsg(type) {
                // let vm = this
                let param = this.mobileForm;
                if(type === 'changeMobile'){
                    let reg = /^1[3|5|7|8|9]\d{9}$/
                    if(!(reg.test(this.mobileForm.mobile))){
                        ElMessage({message: "请输入正确的手机号",type:'warning'})
                        return
                    }else{
                        checkPhoneRepied({mobile:this.mobileForm.mobile}).then(res =>{
                            if(res.status===200 && res.data.code!==200001){
                                ElMessage({message: res.data.message,type:'warning'})
                                return
                            }else{
                                if(!this.mobileForm.captcha){
                                    ElMessage({message: "图片验证码不能为空",type:'warning'})
                                    return
                                }else{
                                    sendmsg(param).then(res =>{
                                        console.log(res)
                                        if(res.status===200 && res.data.code===200001){
                                            console.log(res.data)
                                            this.smsBtnDis = true
                                            const TIME_COUNT = 60
                                            this.count = TIME_COUNT
                                            this.timer = window.setInterval(() => {
                                                if (this.count > 0 && this.count <= TIME_COUNT){
                                                    // 倒计时时不可点击
                                                    this.smsBtnDis = true
                                                    // 计时秒数
                                                    this.count--
                                                    // 更新按钮的文字内容
                                                    this.smsBtnContent = this.count + 's后重新获取'
                                                } else {
                                                    // 倒计时完，可点击
                                                    this.smsBtnDis = false
                                                    // 更新按钮文字内容
                                                    this.smsBtnContent = '获取短信'
                                                    // 清空定时器!!!
                                                    clearInterval(this.timer)
                                                    this.timer = null
                                                }
                                            }, 1000)
                                        }else{
                                            ElMessage({type:'error',message:res.data.message})
                                        }
                                    }).catch(() =>{
                                        ElMessage({type:'error',message:"短信验证码发送失败"})
                                    })
                                }
                            }
                        })
                    }

                }else{
                    param = this.userCancelForm;
                    if(!this.userCancelForm.captcha){
                        ElMessage({message: "图片验证码不能为空",type:'warning'})
                        return
                    }else{
                        sendmsg(param).then(res =>{
                            console.log(res)
                            if(res.status===200 && res.data.code===200001){
                                console.log(res.data)
                                this.smsBtnDis = true
                                const TIME_COUNT = 60
                                this.count = TIME_COUNT
                                this.timer = window.setInterval(() => {
                                    if (this.count > 0 && this.count <= TIME_COUNT){
                                        // 倒计时时不可点击
                                        this.smsBtnDis = true
                                        // 计时秒数
                                        this.count--
                                        // 更新按钮的文字内容
                                        this.smsBtnContent = this.count + 's后重新获取'
                                    } else {
                                        // 倒计时完，可点击
                                        this.smsBtnDis = false
                                        // 更新按钮文字内容
                                        this.smsBtnContent = '获取短信'
                                        // 清空定时器!!!
                                        clearInterval(this.timer)
                                        this.timer = null
                                    }
                                }, 1000)
                            }else{
                                ElMessage({type:'error',message:res.data.message})
                            }
                        }).catch(() =>{
                            ElMessage({type:'error',message:"短信验证码发送失败"})
                        })
                    }
                }

            },
            userCancelSubmit(){
                userCancel({shortMsg:this.userCancelForm.shortMsg}).then(res =>{
                    if(res.status===200 && res.data.code===200001){
                        ElMessage({type:'success',message:'用户注销成功！'});
                        // onLogout();
                        webStorage.removeItem('localStorage','user');
                        webStorage.removeItem('localStorage','token');
                        webStorage.removeItem('localStorage','username');
                        webStorage.removeItem('localStorage','uCompany');
                        this.$router.push({path:'/p_index'})
                    }else if(res.data.code===300003){
                        ElMessage({type:'warning',message:res.data.message});
                    }else{
                        ElMessage({type:'warning',message:'用户注销失败！'});
                    }
                }).catch(error =>{
                    if(error.response.status === 401){
                        // ElMessage({message:'登录超时，请重新登录！',type:"warning"});
                        // this.$router.push('/p_login');
                        this.importantTipsVisible = true;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .my-setting{
        width: 100%;
        margin: 80px auto;
    }
    .my-setting .subnav{
        line-height: 20px;
        font-weight: 500;
        /*width: 98%;*/
        border-bottom: 3px solid var(--el-color-primary-light-5);
        /*margin-bottom: 20px;*/
        font-size: 18px;
        display: flex;
        padding: 10px;
    }
    .setting-body{
        display: flex;
        background-color: #fff;
        margin-top: 20px;
    }
    .line-1{
        width: 2px;
        border-right: 2px solid #dedfe0;
    }
    .user-info{
        padding: 0 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .pwd-change{
        padding: 0 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .mobile-change{
        padding: 0 20px 0 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .title{
        line-height: 20px;
        font-size: 16px;
        font-weight: 500;
        float: left;
        width: 100%;
        border-bottom: 2px solid var(--el-color-primary-light-5);
    }
    .title h3{
        float: left;
    }

    .mobile-change .mobile-detail{
        width: 80%;
        margin: 50px auto;
    }
    .user-info .info-detail{
        width: 80%;
        margin: 50px auto;
    }
    .pwd-change .pwd-detail{
        width: 80%;
        margin: 50px auto;
    }
    .user-info :deep(.el-date-editor.el-input){
        width: 100%;
    }
    .el-menu{
        border-right: none!important;
    }
    .el-menu-item{
        border-bottom: 1px solid #dedfe0;
    }
    .avatar-item{
        width: 100px;
        height: 100px;
        padding: 5px;
    }
    .selected{
        border: 2px solid var(--el-color-primary);
    }
    .change-avatar{
        display: flex;
    }
    .el-image{
        overflow: unset!important;
    }
</style>
