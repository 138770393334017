/**
 * 用户相关请求模块
 */
import {request} from '@/utils/request'

// 获取投诉企业
export const companyInfo = data => {
    return request({
        method: 'POST',
        url: 'companyInfo/',
        data
    })
}

// 获取投诉问题和诉求
export const getIssueAppeal = data =>{
    return request({
        method: 'POST',
        url: 'getIssueAppeal/',
        data
    })
}

export const complaint = data =>{
    return request({
        method: 'POST',
        url: 'complaint/',
        data,
        headers:{
            'Content-type':'multipart/form-data',
            transformRequest: [data => data]
        }
    })
}

export const complaintList = data =>{
    return request({
        method: 'POST',
        url: 'complaintList/',
        data
    })
}

export const replyTS = data =>{
    return request({
        method: 'POST',
        url: 'replyTS/',
        data,
        headers:{
            'Content-type':'multipart/form-data',
            transformRequest: [data => data]
        }
    })
}

export const complaintInfo = data =>{
    return request({
        method: 'POST',
        url: 'complaintInfo/',
        data
    })
}

export const finishTS = data =>{
    return request({
        method: 'POST',
        url: 'finishTS/',
        data
    })
}
