<template>
    <div class="header">
        <el-container>
            <el-header>
                <el-row>
                    <el-col class="logo" :span="6">
                        <router-link to="/p_index" >
                            <img src="@/assets/logo.png" alt="">
                        </router-link>
                    </el-col>
                    <el-col class="nav" :span="14">
                            <el-menu
                                    :default-active="activeIndex"
                                    class="el-menu-demo"
                                    mode="horizontal"
                                    background-color="#03111f"
                                    text-color="#fff"
                                    active-text-color="#ffd04b"
                                    router
                            >
                                <el-menu-item index="index" route="/p_index" >首页</el-menu-item>
                                <el-menu-item index="hotNews" route="/p_hotNews">热点资讯</el-menu-item>
                                <el-menu-item index="industry" route="/p_industry">行业研究</el-menu-item>
                                <el-menu-item index="investor" route="/p_investor">投资者教育</el-menu-item>
                            </el-menu>
                    </el-col>
                    <el-col class="login-bar" :span="4">
                        <el-row v-if="token">
                            <el-dropdown >
                                <div class=" el-dropdown-link">
                                    <el-col :span="5">
                                        <el-avatar
                                                class="avatar"
                                                shape="circle"
                                                :size="40"
                                                fit="cover"
                                        >
                                            <img :src="avatar" @error="setDefaultUser"/>
                                        </el-avatar>
                                    </el-col>
<!--                                    <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
                                </div>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click="toMySetting">设置</el-dropdown-item>
                                        <el-dropdown-item divided @click="onLogout">退出</el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                            <el-col :span="10" class="username" v-if="token">
                                <span >{{username}}</span>
                            </el-col>
                        </el-row>
<!--                        </el-row>-->
<!--                        <el-row v-else>-->
                            <el-col v-else :span="15" :offset="5">
                                <span class="register">
                                    <a href="javascript:;" @click="loginDialogVisible=true">登录</a>
                                    <a href="javascript:;" @click="registerDialogVisible=true">注册</a>
                                </span>
                            </el-col>
<!--                        </el-row>-->
                    </el-col>
                </el-row>
            </el-header>
        </el-container>
    </div>
    <el-dialog v-model="loginDialogVisible" title="登录" width="400" show-close="false" align-center @closed="clearLoginChildComponent">
        <pcLoginComponent @loginEnd="loginEnd" ref="loginComponentRef" redirect="p_index"/>
    </el-dialog>
    <el-dialog v-model="loginToSetDialogVisible" title="登录" width="400" show-close="false" align-center>
        <pcLoginComponent @loginEnd="loginEnd" redirect="p_setting"/>
    </el-dialog>
    <el-dialog v-model="registerDialogVisible" title="用户注册" width="600" show-close="false" align-center @closed="clearRegChildComponent">
        <pcRegisterComponent ref="registerComponentRef" @registEnd="registerEnd"/>
    </el-dialog>
    <el-dialog v-model="importantTipsVisible" title="重要提示" width="400" show-close="false" align-center>
        登录失效，请点击"确定"按钮重新登录！
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="importantTipsVisible = false">取消</el-button>
            <el-button type="primary" @click="toNext">
              确定
            </el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script>
    import webStorage from '@/utils/webStorage.js';
    import defaultUser from '@/assets/icons/default-user.png';
    import pcLoginComponent from "@/views/login/pcLoginComponent";
    import pcRegisterComponent from "@/views/register/pcRegisterComponent";
    export default {
        name: "Header",
        components: {pcLoginComponent,pcRegisterComponent},
        data(){
            return {
                activeIndex:"index",
                avatar:'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
                username: '',
                // 设置一个登录标识，表示是否登录
                token: '',
                loginDialogVisible: false,
                loginToSetDialogVisible: false,
                registerDialogVisible: false,
                importantTipsVisible: false,
                redirectPath: 'p_index',
            };
        },
        created(){
            this.token = webStorage.getItem('localStorage','token')
            let curUser = JSON.parse(webStorage.getItem('localStorage','user'))
            if(curUser && curUser.avatar){
                this.avatar = curUser.avatar
            }
            if(curUser && curUser.nike_name){
                this.username = curUser.nike_name
            }else{
                this.username = webStorage.getItem('localStorage','username')
            }
            window.addEventListener('setItem',()=>{
                this.token = webStorage.getItem('localStorage','token')
                let curUser = JSON.parse(webStorage.getItem('localStorage','user'))
                this.avatar = curUser.avatar
                if(curUser && curUser.nike_name){
                    this.username = curUser.nike_name
                }else{
                    this.username = webStorage.getItem('localStorage','username')
                }
            });
            window.addEventListener('removeItem',()=>{
                console.log('removeItem listener');
                let rmUser = JSON.parse(webStorage.getItem('localStorage','user'));
                if(!rmUser){
                    this.avatar = '';
                    this.username = '';
                    this.token = '';
                }
            });
            window.addEventListener('changeActiveMenu',(val)=>{
                this.activeIndex = val.detail
            })
        },
        mounted(){
            this.token = this.gettoken
        },
        computed: {
            gettoken() {
                return webStorage.getItem('localStorage','token')
            },
        },
        methods:{
            clearLoginChildComponent(){
                this.$refs.loginComponentRef.$refs.ruleFormUPRef.resetFields();
                this.$refs.loginComponentRef.$refs.ruleFormUPRef.clearValidate();
                this.$refs.loginComponentRef.$refs.ruleFormMRef.resetFields();
                this.$refs.loginComponentRef.$refs.ruleFormMRef.clearValidate();
            },
            clearRegChildComponent(){
                this.$refs.registerComponentRef.$refs.ruleFormRef.resetFields();
                this.$refs.registerComponentRef.$refs.ruleFormRef.clearValidate();
            },
            toNext(){
                this.importantTipsVisible = false;
                this.loginToSetDialogVisible = true;
            },
            setDefaultUser(e){
                e.target.src = defaultUser
            },
            toMySetting(){
                if(this.token){
                    if(webStorage.getItem('localStorage','token')){
                        this.$router.push("/p_setting");
                    }else{
                        this.token = '';
                        this.username = '';
                        this.avatar = '';
                        this.importantTipsVisible = true;
                    }
                }else{
                    this.loginToSetDialogVisible = true;
                }

            },
            onLogout(){
                webStorage.removeItem('localStorage','user');
                webStorage.removeItem('localStorage','token');
                webStorage.removeItem('localStorage','username');
                webStorage.removeItem('localStorage','uCompany');
                this.$router.push({path:'/p_index'})
            },
            loginEnd(val){
                if(val === 'p_index'){
                    this.loginDialogVisible = false;
                }else if(val === 'p_setting'){
                    this.loginToSetDialogVisible = false;
                    this.$router.push("/p_setting");
                }
            },
            registerEnd(){
                this.registerDialogVisible = false;
                this.loginDialogVisible = true;
            }
        }
    }
</script>

<style scoped>
    .header{
        top:0;
        left:0;
        right:0;
        margin: auto;
        /*background-color: #fff;*/
        height: 80px;
        z-index: 1000;
        position: fixed;
        box-shadow: 0 0.5px 0.5px 0 #c9c9c9;
        min-width: 1000px;
        background-color: #03111f;
    }
    .header .el-container{
        max-width: 1200px;
        margin: 0 auto;

    }
    .el-header{
        height: 80px!important;
        padding:0;
    }
    .el-header :deep(.el-menu--horizontal){
        display: flex;
        justify-content: space-around;
    }
    .el-menu--horizontal>.el-menu-item.is-active{
        border-bottom: 2px solid var(--el-color-primary);
        color: var(--el-color-primary)!important;
    }
    .logo{

    }
    .logo img{
        padding-top: 18px;
        max-width: 100%;
        min-width: 10%;
        /*height: 53px;*/
    }

    .nav{
        margin-top: 7px;
    }

    .nav .el-col a{
        display: inline-block;
        text-align: center;
        padding-bottom: 16px;
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        font-size: 16px;
        margin-left: 20px;
    }

    .nav .el-col .current{
        color: #4a4a4a;
        border-bottom: 4px solid #ffc210;
    }
    .nav .el-menu-item{
        font-size: 16px;
    }
    :deep(.el-menu){
        font-weight: bold;
    }
    :deep(.el-sub-menu__title){
        font-size: 16px;
    }
    :deep(.el-menu--horizontal){
        border-bottom: none;
    }
    .login-bar{
        margin-top: 7px;
        display: inline-grid;
        align-content: center;
    }
    .register{
        display: flex;
        justify-content: space-around;
    }
    .register a{
        font-size: 16px;
        color: #ffffff;
        font-weight: bold;
    }
    .avatar-wrap{
        width: 100%;
        display: flex;
    }
    /*.cart-ico span{*/
    /*    margin-left: 12px;*/
    /*}*/
    .member img{
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: inline-block;
    }
    .member img:hover{
        border: 1px solid yellow;
    }
    .login-bar .el-dropdown-link {
        cursor: pointer;
        color: var(--el-color-primary);
        display: flex;
        align-items: center;
        width: 100%;
        /*margin-left: 10px;*/
    }
    /*.login-bar :deep(el-dropdown-menu:first-child){*/
    /*    border-bottom: 1px solid #212121;*/
    /*}*/
    .username{
        margin-block-start: auto;
        margin-block-end: auto;
        text-align: center;
        font-size: large;
        color: aliceblue;
    }
</style>
