import {request} from '@/utils/request'

// 用户登录
export const partnerList = data => {
    return request({
        method: 'POST',
        url: 'patnerList/',
        data
    })
}
