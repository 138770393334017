<template>
    <van-nav-bar title="登录" left-text="返回" left-arrow @click-left="onClickLeft"
        right-text="注册" @click-right="onClickRight"
    />
    <van-form v-show="pwdLoginShow" ref="loginRef" @submit="onSubmit">
        <van-cell-group inset>
            <van-field
                    v-model="loginData.username"
                    name="username"
                    placeholder="用户名"
                    :rules="[{required: true, message: '请填写用户名'}]"/>
            <van-field
                    v-model="loginData.password"
                    type="password"
                    name="password"
                    placeholder="密码"
                    :rules="[{ required: true, message: '请填写密码' }]"
            />
            <van-field
                    v-model="loginData.captcha"
                    center
                    clearable
                    name="captcha"
                    placeholder="请输入图片验证码"
                    :rules="[{ required: true, message: '请填写验证码'}]"
            >
                <template #right-icon>
                    <van-image :src="captchaUrl" @click="refresh"/>
                </template>
            </van-field>
            <van-field name="agree" :rules="[{required:true,message:'请阅读并同意用户协议与隐私政策'}]">
                <template #input>
                    <van-checkbox icon-size="16px" v-model="agree" shape="square" style="margin-right: 2px;"/> 已阅读并同意<a href="javascript:;" @click="openfile">《用户协议与隐私政策》</a>
                </template>
            </van-field>
        </van-cell-group>
        <span style="font-size: 0.3rem;float:left;padding:0.3rem;color:red">注：请勾选用户协议与隐私政策。</span>
        <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">
                登录
            </van-button>
        </div>
        <div style="margin: 16px;display: flex;flex-direction:row;justify-content: space-between;font-size: 0.3rem;">
            <a @click="toPhoneLogin">使用手机登录</a>
            <router-link to="/m_forgetPwd">忘记密码，找回</router-link>
        </div>
    </van-form>
    <van-form v-show="phoneLoginShow" ref="phoneLoginRef" @submit="onPhoneLoginSubmit">
        <van-cell-group inset>
            <van-field v-model="phoneLoginForm.mobile" ref="mobilRef" name="mobile" type="tel" placeholder="建议使用常用手机" :rules="[{requrid:true,message:'请填写手机号'},{validator,message:'请输入正确的手机号'}]"/>
            <van-field
                    v-model="phoneLoginForm.captcha"
                    center
                    clearable
                    name="captcha"
                    placeholder="请输入图片验证码"
                    :rules="[{ required: true, message: '请填写验证码'}]"
            >
                <template #right-icon>
                    <van-image :src="captchaUrl" @click="refresh"/>
                </template>
            </van-field>
            <van-field
                    v-model="shortMsg"
                    center
                    clearable
                    placeholder="请输入短信验证码"
                    :rules="[{required:true,message:'短信验证码不能为空'}]"
            >
                <template #button>
                    <van-button size="small" type="primary" :disabled="smsBtnDis" @click="sendMsg" >{{smsBtnContent}}</van-button>
                </template>
            </van-field>
            <van-field name="agree" :rules="[{required:true,message:'请阅读并同意用户协议和隐私政策'}]">
                <template #input>
                    <van-checkbox icon-size="16px" v-model="agree" shape="square" style="margin-right: 2px;"/> 已阅读并同意<a href="javascript:;" @click="openfile">《用户协议与隐私政策》</a>
                </template>
            </van-field>
        </van-cell-group>
        <span style="font-size: 0.3rem;float:left;padding:0.3rem;color:red">注：请勾选用户协议与隐私政策。</span>
        <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">
                登录
            </van-button>
        </div>
        <div style="margin: 16px;display: flex;flex-direction:row;justify-content: space-between;font-size: 0.3rem;">
            <a @click="toPwdLogin">使用用户密码登录</a>
        </div>
    </van-form>
</template>

<script>
    import {ref} from 'vue';
    import { getCaptcha ,login,loadUserInfo,sendmsg} from '@/api/user';
    import webStorage from "@/utils/webStorage.js"
    import { showNotify } from 'vant';
    import {useRouter,useRoute} from 'vue-router';
    export default {
        name: "m_login",
        setup(){
            const router = useRouter();
            const curRoute = useRoute();
            // const pattern = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            const validator = (val) => /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(val);
            const pwdLoginShow = ref(true);
            const phoneLoginShow = ref(false);
            // const mobilRef = ref('');
            const phoneLoginRef = ref();
            const phoneLoginForm = ref({
                hashkey: '',
                mobile: '',
                captcha: '',
                requestType:'login'
            });
            const shortMsg = ref('');
            const loginData = ref({
                hashkey:'',
                loginType:'0',
                username:'',
                password:'',
                token:'',
                captcha:''
            });
            const agree = ref(false);
            const captchaUrl = ref('');
            const loginLoading = ref(false);
            const onSubmit = (values) => {
                console.log('submit', values);
                login(loginData.value).then(res => {
                    if ( res.status ===200 && res.data.code===200001){
                        webStorage.setItem('localStorage','token',res.data.data.token,60000*60*2)
                        if(res.data.data.uCompany){
                            webStorage.setItem('localStorage','uCompany',res.data.data.uCompany,60000*60*2)
                        }
                        loadUserInfo().then(async res1 =>{
                            if(res1.status === 200 && res1.data.code === 200001){
                                webStorage.setItem('localStorage','user',JSON.stringify(res1.data.data),60000*60*2)
                                webStorage.setItem('localStorage','username',res1.data.data.username,60000*60*2)
                                let redirect = decodeURIComponent(curRoute.query.redirect || '/');
                                router.push({path:redirect});
                                // router.push('/m_user')
                            }
                        })

                    }else{
                        showNotify({ type: 'danger', message: res.data.message });
                    }
                    // this.$router.push({ name: 'home' })
                }).catch(() => {
                    // 登录失败
                    showNotify({ type: 'danger', message: '登录失败' });
                    // 关闭 loading
                    loginLoading.value = false
                })
            };
            const refresh = () =>{
                getCaptcha().then(res => {
                    loginData.value.hashkey=res.data.data.hashkey;
                    phoneLoginForm.value.hashkey = res.data.data.hashkey;
                    captchaUrl.value = res.data.data.image_url;
                }).catch(() => {
                    // 登录失败
                    // ElMessage({message:'获取验证码失败', type:'error'})
                    // 关闭 loading
                    loginLoading.value = false
                })
            };
            const onClickLeft = () => history.back();
            const onClickRight = () =>{
                router.push('/m_register')
            };
            const toPhoneLogin = () =>{
                pwdLoginShow.value=false;
                phoneLoginShow.value=true;
            };
            const toPwdLogin = () =>{
                pwdLoginShow.value=true;
                phoneLoginShow.value=false;
            };
            const smsBtnDis = ref(false);
            const smsBtnContent = ref("获取验证码");
            const count = ref(0);
            const timer = ref(0);
            const sendMsg = () =>{
                var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if(!reg.test(phoneLoginForm.value.mobile)){
                    showNotify({message: "请输入正确的手机号",type:'warning'})
                    return
                }
                if(!phoneLoginForm.value.captcha){
                    showNotify({message: "请输入正确的图片验证码",type:'warning'})
                    return
                }
                sendmsg(phoneLoginForm.value).then(res =>{
                    console.log(res)
                    if(res.status===200 && res.data.code===200001){
                        smsBtnDis.value = true;
                        const TIME_COUNT = ref(60);
                        count.value = TIME_COUNT.value;
                        timer.value = window.setInterval(() => {
                            if (count.value > 0 && count.value <= TIME_COUNT.value){
                                // 倒计时时不可点击
                                smsBtnDis.value = true;
                                // 计时秒数
                                count.value--;
                                // 更新按钮的文字内容
                                smsBtnContent.value = count.value + 's后重新获取';
                            } else {
                                // 倒计时完，可点击
                                smsBtnDis.value = false;
                                // 更新按钮文字内容
                                smsBtnContent.value = '获取短信';
                                // 清空定时器!!!
                                clearInterval(timer);
                                timer.value = null;
                            }
                        }, 1000)
                    }else{
                        showNotify({type:'warning', message:res.data.message});
                    }
                });
            };
            const onPhoneLoginSubmit = () =>{
                login({mobile:phoneLoginForm.value.mobile,shortMsg:shortMsg.value}).then(res => {
                    if ( res.status ===200 && res.data.code===200001){
                        webStorage.setItem('localStorage','token',res.data.data.token,60000*60*2)
                        if(res.data.data.uCompany){
                            webStorage.setItem('localStorage','uCompany',res.data.data.uCompany,60000*60*2)
                        }
                        loadUserInfo().then(async res1 =>{
                            if(res1.status === 200 && res1.data.code === 200001){
                                webStorage.setItem('localStorage','user',JSON.stringify(res1.data.data),60000*60*2)
                                webStorage.setItem('localStorage','username',res1.data.data.username,60000*60*2)
                                router.push('/m_user')
                            }
                        });

                    }else{
                        showNotify({ type: 'danger', message: res.data.message });
                    }
                    // this.$router.push({ name: 'home' })
                }).catch(() => {
                    // 登录失败
                    showNotify({ type: 'danger', message: '登录失败' });
                    // 关闭 loading
                    loginLoading.value = false
                })
            };
            const openfile = () =>{
                window.open('/北京市互联网金融消费者投诉平台用户协议与隐私政策.pdf','用户协议与隐私政策');
            };
            return {curRoute,
                openfile,
                onClickLeft,
                onClickRight,
                loginData,
                agree,
                captchaUrl,
                onSubmit,
                refresh,
                pwdLoginShow,
                phoneLoginShow,
                toPhoneLogin,
                toPwdLogin,phoneLoginForm,smsBtnDis,smsBtnContent,count,timer,sendMsg,validator,shortMsg,phoneLoginRef,onPhoneLoginSubmit
            };
        },
        mounted(){
            this.refresh();
        }
    }
</script>

<style scoped>
    /*.van-nav-bar{*/
    /*    margin-bottom: 0.2rem;*/
    /*}*/
    .van-form{
        padding: 0.2rem 0;
        height: 100vh;
    }
    .van-cell-group--inset{
        margin: 0 14px;
    }
    :deep(.van-field__right-icon){
        height: 24px;
    }
</style>
