<template>
    <div class="article-container">
        <div class="subnav">
            <a href="/" title="首页">首页</a>
            <span v-if="art_type === '0'">> 热点资讯</span>
            <span v-if="art_type === '1'">> 行业研究</span>
            <span v-if="art_type === '2'">> 投资者教育</span>
        </div>
        <li class="article-con clearfix" v-for="item in artList" :key="item" @click="showArticle(item.article_id)">
            <a href="javascript:;" >
                <img class="left-pic" :src="item.thumb" @error="setDefaultPic">
            </a>
            <div class="right-card">
                <h3 class="art-card-tt">
                    <a href="javascript:;" >{{item.title}}</a>
                </h3>
                <h3 class="short-tit">{{item.short_desc}}</h3>
                <p class="art-card-tip clearfix">
                    <span class="">{{item.time}}</span>
                </p>
            </div>
        </li>
        <div class="loading" v-if="newTabLoading">
            <span >{{newTabLoadText}}</span>
        </div>
        <p class="data-empty" v-show="noDataShow">敬请期待...</p>
    </div>
</template>

<script>
    import {articleList} from '@/api/article';
    import defaultPic from '@/assets/icons/default-pic.png';
    import {ref,onMounted,onUnmounted} from 'vue';
    import {useRouter} from 'vue-router';
    export default {
        name: "article",
        props:['art_type'],

        setup(props){
            const router = useRouter();
            const noDataShow = ref(false);
            const artList = ref([]);
            const newTabLoading = ref(true);
            const newTabLoadText = ref('正在加载...');
            const queryParam = ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                cate:props.art_type
            });
            const getClientHeight = () => {
                var clientHeight = 0;
                if (document.body.clientHeight && document.documentElement.clientHeight) {
                    clientHeight = Math.min(document.body.clientHeight, document.documentElement.clientHeight)
                } else {
                    clientHeight = Math.max(document.body.clientHeight, document.documentElement.clientHeight)
                }
                return clientHeight
            };
            //获取文档完整的高度
            const getScrollHeight = () =>{
                return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight)
            };
            //获取当前滚动条的位置
            const getScrollTop = () => {
                var scrollTop = 0;
                //window.pageYOffset = document.documentElement.scrollTop
                if (document.documentElement && document.documentElement.scrollTop) {
                    scrollTop = document.documentElement.scrollTop
                } else if (document.body) {
                    scrollTop = document.body.scrollTop
                }
                return scrollTop
            };
            const windowScroll = () => {
                //获取三个值
                var scrollTop = getScrollTop()
                var clientHeight = getClientHeight()
                var scrollHeight = getScrollHeight()
                //如果满足公式则，确实到底了
                if(scrollTop+clientHeight === scrollHeight){
                    setTimeout(()=>{
                        if(queryParam.value.page < queryParam.value.totalPage){
                            queryParam.value.page += 1;
                            loadData();
                        }
                    },1000);

                }
            };
            const loadData = () =>{
                newTabLoading.value = true;
                articleList(queryParam.value).then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        artList.value = artList.value.concat(res.data.data.articleData);
                        let pageInfo = res.data.data.pageInfo;
                        queryParam.value.page = pageInfo.page;
                        queryParam.value.totalPage = pageInfo.totalPage;
                    }
                    if(artList.value.length<1){
                        noDataShow.value = true
                    }
                    newTabLoading.value = false;
                });

            };
            const showArticle = (val) => {
                let url = router.resolve({path:'/p_article',
                    query:{
                        articleId : val
                    }
                });
                window.open(url.href,'_blank');
            };
            onMounted(()=>{
                loadData();
                window.addEventListener('scroll', windowScroll,true);
            });
            onUnmounted(()=>{
                window.removeEventListener("scroll", windowScroll,true);//销毁滚动事件
            });
            return {newTabLoading,newTabLoadText,router,noDataShow,artList,queryParam,getClientHeight,getScrollHeight,getScrollTop,windowScroll,loadData,showArticle,}
        },
        methods:{
            setDefaultPic(e){
                e.target.src = defaultPic
            },
        }
    }
</script>

<style scoped>
    .article-container{
        width: 100%;
        margin: 80px auto;
        min-height: 700px;
    }
    .article-container .subnav{
        line-height: 20px;
        font-weight: 500;
        /*width: 98%;*/
        border-bottom: 3px solid var(--el-color-primary-light-5);
        /*margin-bottom: 20px;*/
        font-size: 18px;
        display: flex;
        padding: 10px;
    }
    a {
        text-decoration: none;
        outline: none;
        color: #333;
    }
    .data-empty{
        position: relative;
        z-index: 10;
        overflow: visible;
        border-bottom: 1px solid #EFEFEF;
        background-color: #fff;
        line-height: 30px;
        font-size: 20px;
        font-weight: bold;
    }
    .article-con {
        position: relative;
        z-index: 10;
        overflow: visible;
        /*border-bottom: 1px solid #EFEFEF;*/
        background-color: #fff;
        letter-spacing: .5px;
        letter-spacing: 0\9;
        padding-left: 20px;
        padding-right: 10px;
    }
    .article-con .left-pic {
        margin-top: 20px;
        padding-bottom: 20px;
        width: 150px;
        height: 100px;
        float: left;
    }
    li{
        list-style: none;
    }
    .right-card{
        margin-left: 170px;
    }
    .art-card-tt {
        padding-top: 17px;
        margin-right: 30px;
        height: 30px;
        /*line-height: 35px;*/
        font-size: 20px;
        font-weight: bold;
        word-wrap: break-word;
        display: flex;
        text-align: left;
    }
    .short-tit{
        padding-top: 9px;
        margin-right: 30px;
        /*height: 52px;*/
        line-height: 19px;
        font-size: 10px;
        /*font-weight: bold;*/
        word-wrap: break-word;
        display: flex;
        text-align: left;
        color: #c9c9c9;
    }
    a {
        text-decoration: none;
        outline: none;
        color: #333;
    }
    .art-card-tip {
        margin-top: 3px;
        padding-bottom: 10px;
        height: 24px;
        line-height: 24px;
        font-size: 14px;
        color: #999;
        display: flex;
    }
    .clearfix{
        zoom: 1;
    }
    .clearfix:after {
        height: 0;
        display: block;
        clear: both;
        content: '.';
        visibility: hidden;
    }
    .loading span{
        font-size: 12px;
    }
</style>
