<template>
    <van-nav-bar title="修改密码" left-text="返回" left-arrow @click-left="onClickLeft"/>
    <van-form ref="changePwdRef" @submit="onSubmit">
        <van-cell-group inset>
            <van-field
                    v-model="pwdForm.passwd"
                    type="password"
                    name="passwd"
                    placeholder="原始密码"
                    :rules="[{ required: true, message: '请填写原始密码' }]"
            />
            <van-field
                    v-model="pwdForm.newPasswd"
                    type="password"
                    name="newPasswd"
                    placeholder="新密码长度为8~30位大小字母+数字+特殊字符组合"
                    :rules="[{ required: true, message: '请填写新密码' },{validator,message: '密码必须为8~30位大小字母、数字、特称字符!'}]"
            />
            <van-field
                    v-model="pwdForm.confirePwd"
                    type="password"
                    name="confirePwd"
                    placeholder="确认密码"
                    :rules="[{ required: true, message: '请填写确认密码' },{validator:validator1,message: '确认密码和新密码不一致!'}]"
            />
        </van-cell-group>
        <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit" :disabled="nextBtnDis">
                修改密码
            </van-button>
        </div>
    </van-form>
</template>

<script>
    import {ref,computed,onMounted} from 'vue';
    import {changePwd} from '@/api/user';
    import { showNotify } from 'vant';
    import {useRouter} from 'vue-router';
    import webStorage from "@/utils/webStorage.js";
    export default {
        name: "m_changePassword",
        setup(){
            const router = useRouter();
            const pwdForm = ref({
                passwd: '',
                newPasswd: '',
                confirePwd: ''
            });
            const onClickLeft = () => history.back();
            const nextBtnDis = computed(()=>pwdForm.value.passwd.length<=0 ||pwdForm.value.newPasswd.length<=0 ||pwdForm.value.confirePwd.length<=0);
            const validator = (val) => /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}$/.test(val);
            const validator1 = (val) => val === pwdForm.value.newPasswd;
            const onSubmit = () =>{
                changePwd(pwdForm.value).then(res =>{
                    console.log(res)
                    if(res.status === 200 && res.data.code === 200001){
                        showNotify({message:"密码修改成功",type:"success"})
                        router.push('/m_userInfo');
                    }else{
                        showNotify({message:res.data.message,type:"warning"})
                    }
                }).catch(error =>{
                    if(error.response.status === 401){
                        showNotify({message:'登录超时，请重新登录！',type:"warning"});
                        router.push('/m_login');
                    }
                })
            };
            onMounted(()=>{
                let userinfo = JSON.parse(webStorage.getItem('localStorage', 'user'));
                if(!userinfo){
                    console.log('relogin')
                    // router.push('/m_login');
                }
            });
            return {pwdForm,onClickLeft,nextBtnDis,validator,validator1,onSubmit};
        }
    }
</script>

<style scoped>

</style>
