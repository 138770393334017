<template>
    <van-nav-bar left-arrow @click-left="onClickLeft" fixed>
        <template #title>
            <span>{{article.title}}</span>
        </template>
    </van-nav-bar>
    <div class="box">
        <div class="title">
            <h1>{{article.title}}</h1>
        </div>
        <div class="time">
            <span>{{article.publish_date}}</span>
        </div>
        <div class="content" v-html="article.content"></div>
    </div>

</template>

<script>
    import {ref} from 'vue';
    import {useRouter,useRoute} from 'vue-router';
    import {articleShow} from '@/api/article'
    export default {
        name: "m_articleShow",
        setup(){
            const articleId = ref('');
            const router = useRouter();
            const route = useRoute();
            const article = ref({
                article_id:'',
                title:'',
                short_desc:'',
                content:'',
                cover:'',
                media:'',
                publish_date:'',
                read_num:0,
                vote_num:0,
                comm_num:0,
                share_num:0
            });
            const showArticle = () => {
                articleShow({'articleId':route.params.articleId}).then(res =>{
                    article.value = res.data.data;
                });
            };
            const onClickLeft = () => history.back();
            return {onClickLeft,articleId,route,router,showArticle,article};
        },
        created(){
            this.showArticle();
            // console.log(this.$route.params);
            // this.articleId = this.$route.params.articleId;
            // console.log(this.articleId);
        }
    }
</script>

<style scoped>
    /*.content{*/
    /*    float: left;*/
    /*}*/
    /*section{*/
    /*    */
    /*}*/
    :deep(h1){
        font-size: 0.7rem;
    }
    .box{
        /*margin-top: 1.5rem;*/
        margin-bottom: 1rem;
        padding: 1.5rem 0.3rem 1rem 0.3rem;
        height: 100%;
    }
    .box .title{
        text-align: left;
    }
    .box .time{
        text-align: right;
        font-size: 0.4rem;
        color: #c9c9c9;
    }
    .box .content :deep(p){
        line-height: .6rem;
        text-align: left;
        font-size: 0.4rem;
    }
    .box .content :deep(p:has(img)) {
        text-align: center !important;
    }
    .box .content :deep(p>img){
        width: 100% !important;
        height: auto !important;
    }
</style>
