<template>
    <Header/>
    <div class="register-container">
        <el-tabs v-model="activeName" class="register-tabs" stretch>
            <el-tab-pane label="用户注册" name="user-register">
                <el-form
                        ref="ruleFormRef"
                        :model="ruleForm"
                        :rules="rules"
                        label-width="100px"
                        class="demo-ruleForm"
                        status-icon
                >
                    <el-form-item label="用户名" prop="username">
                        <el-input size="large" v-model="ruleForm.username" placeholder="用户名长度为4～20位英文或数字，或2～10位汉字"/>
                    </el-form-item>
                    <el-form-item label="登录密码" prop="passwd">
                        <el-input size="large" type="password" v-model="ruleForm.passwd" placeholder="8~30位大小字母、数字、特殊字符"/>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="checkPasswd">
                        <el-input size="large" type="password" v-model="ruleForm.checkPasswd" placeholder="再次输入密码"/>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="mobile">
                        <el-input size="large" v-model="ruleForm.mobile" ref="phoneNoRef" placeholder="建议使用常用手机"/>
                    </el-form-item>
                    <el-form-item label="图片验证码" prop="captcha">
                        <el-col :span="16">
                            <el-input size="large" v-model="ruleForm.captcha" placeholder="输入图片验证码"/>
                        </el-col>
                        <el-col :span="8">
                            <el-image style="height: 38px" :src="captchaUrl"  @click="refresh"/>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="短信验证码" prop="shortMsg">
                        <el-col :span="16">
                            <el-input size="large" v-model="ruleForm.shortMsg" placeholder="输入短信验证码"/>
                        </el-col>
                        <el-col :span="8">
                            <el-button type="primary" size="large" :disabled="smsBtnDis" @click="getShortMsg()">{{smsBtnContent}}</el-button>
                        </el-col>
                    </el-form-item>
                    <el-form-item prop="agree">
                        <el-checkbox v-model="ruleForm.agree">已阅读并同意<a href="javascript:;" @click="openfile">《用户协议与隐私政策》</a></el-checkbox>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm">注册</el-button>
                        <el-button @click="resetForm()">重置</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
    </div>
    <Footer/>
</template>
<script >
    import { getCaptcha ,register,sendmsg,checkUNameRepied,checkPhoneRepied,validateNameInRules} from '@/api/user';
    import {  reactive, ref, unref } from 'vue';
    import Header from "../components/common/Header";
    import Footer from "../components/common/Footer";
    import {ElMessage} from 'element-plus';
    export default {
        components: {Header,Footer},
        created(){
            this.refresh();
        },
        setup(){
            const ruleFormRef = ref(null)
            const captchaUrl = ref(null)
            const smsBtnDis = ref(false)
            const smsBtnContent = ref('获取短信')
            const timer = ref()
            const count = ref()
            const activeName = ref("user-register")
            // const router = useRouter()
            // const route = useRoute()
            const ruleForm = reactive({
                username: '',
                passwd: '',
                checkPasswd: '',
                mobile: '',
                captcha: '',
                hashkey: '',
                shortMsg: '',
                agree: false,
                requestType:'register'
            })
            const rules = {
                username: [
                    { required: true, trigger: 'blur', validator: validateUsername },
                    {trigger:'blur',validator:(rule,value,callback) =>{
                            if(value){
                                validateNameInRules({'content':value}).then(res =>{
                                    let ret = JSON.parse(res.data)
                                    if(ret.code === "200" && ret.flag === "1"){
                                        callback(new Error('该用户名已被占用'))
                                    }else{
                                        callback()
                                    }
                                });
                            }
                        }
                    }
                ],
                passwd: [
                    { required: true, trigger: 'blur', validator: validatePass },
                ],
                checkPasswd: [
                    { required: true,  trigger: 'blur', validator: validatePass2 }
                ],
                mobile: [
                    { required: true,  trigger: 'blur', validator: validatePhone },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
                ],
                captcha: [
                    { required: true,  trigger: 'blur', validator: validateCaptcha },
                    { pattern: /^[a-zA-Z]{4}$/, message: '请输入正确的验证码', trigger: 'blur'}
                ],
                shortMsg: [
                    { required: true,  trigger: 'blur', validator: validateShortMsg }
                ],
                agree: [
                    {
                        validator: (rule, value, callback) => {
                            if (value) {
                                // 验证通过
                                callback()
                            } else {
                                // 验证失败
                                callback(new Error('请同意用户协议与隐私政策'))
                            }
                        },
                        trigger: 'blur'
                    }
                ]
            }

            function validateUsername(rule, value, callback){
                if (!value) {
                    callback(new Error('请输入用户名'));
                }else {
                    var nameReg = /^[\u4e00-\u9fa5]{2,10}$|^[A-Za-z0-9_-]{4,20}$/
                    if (!nameReg.test(value)) {
                        callback(new Error("用户名长度为4～20位英文或数字，或2～10位汉字"))
                    } else {
                        checkUNameRepied({userName:value}).then(res =>{
                            if(res.status===200 && res.data.code===200001){
                                callback()
                            }else{
                                callback(new Error(res.data.message))
                            }
                        })

                    }
                }
            }

            function validatePass(rule, value, callback) {
                if (value === '') {
                    callback(new Error('请输入密码'));
                }
                // var passwdReg = /^(?![A-z0-9]+$)(?![A-z~@*()_]+$)(?![0-9~@*()_]+$)([A-z0-9~@*()_]{6,15})$/
                var passwdReg= /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}$/
                if(!passwdReg.test(value)){
                    callback(
                        new Error("密码必须为8~30位大小字母、数字、特称字符!")
                    )
                }else{
                    callback()
                }
            }
            function validatePass2(rule, value, callback) {
                if (value === '') {
                    callback(new Error('请输入确认密码!'));
                }else if(value != ruleForm.passwd){
                    callback(new Error('两次输入密码不一致!'));
                }else{
                    callback()
                }
            }
            function validatePhone(rule, value, callback) {
                var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (!myreg.test(value)) {
                    callback(new Error('请输入正确的手机号！'));
                } else {
                    checkPhoneRepied({mobile:value}).then(res =>{
                        if(res.status===200 && res.data.code===200001){
                            callback()
                        }else{
                            callback(new Error(res.data.message))
                        }
                    })
                }
            }
            function validateCaptcha(rule, value, callback){
                if(value === ''){
                    callback(new Error('请输入图片验证码！'))
                }else{
                    callback()
                }
            }
            function validateShortMsg(rule, value, callback) {
                if(value === ''){
                    callback(new Error('请输入短信验证码！'))
                }else{
                    callback()
                }
            }
            function refresh(){
                getCaptcha().then(res => {
                    ruleForm.hashkey=res.data.data.hashkey
                    captchaUrl.value=res.data.data.image_url
                }).catch(err => {
                    // 登录失败
                    ElMessage({message:err, type:'error'})
                })
            }
            const phoneNoRef = ref(null);
            function getShortMsg() {
                // let vm = this
                var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if(!reg.test(ruleForm.mobile)){
                    ElMessage({message: "请输入正确的手机号",type:'warning'})
                    return
                }else {
                    checkPhoneRepied({mobile:ruleForm.mobile}).then(res =>{
                        if(res.status===200 && res.data.code !==200001){
                            ElMessage({type:'warning',message:res.data.message});
                            return;
                        }
                    })
                }
                sendmsg(ruleForm).then(res =>{
                    if(res.status===200 && res.data.code===200001){
                        smsBtnDis.value = true
                        const TIME_COUNT = 60
                        count.value = TIME_COUNT
                        timer.value = window.setInterval(() => {
                            if (count.value > 0 && count.value <= TIME_COUNT){
                                // 倒计时时不可点击
                                smsBtnDis.value = true
                                // 计时秒数
                                count.value--
                                // 更新按钮的文字内容
                                smsBtnContent.value = count.value + 's后重新获取'
                            } else {
                                // 倒计时完，可点击
                                smsBtnDis.value = false
                                // 更新按钮文字内容
                                smsBtnContent.value = '获取短信'
                                // 清空定时器!!!
                                clearInterval(timer.value)
                                timer.value = null
                            }
                        }, 1000)
                    }else{
                        ElMessage({type:'error',message:res.data.message})
                    }
                }).catch(() =>{
                    ElMessage({type:'error',message:"短信验证码发送失败"})
                })
            }
            return{phoneNoRef,activeName,captchaUrl,ruleFormRef,ruleForm,rules,validateUsername,validatePass,validatePass2,validatePhone,validateCaptcha,validateShortMsg,getShortMsg,refresh,smsBtnDis,smsBtnContent,timer,count}
        },
        methods: {
            openfile(){
                window.open('/北京市互联网金融消费者投诉平台用户协议与隐私政策.pdf','用户协议与隐私政策');
            },
            submitForm(){
                const form = unref(this.ruleFormRef)
                try{
                    form.validate(valid =>{
                        if (valid){
                            register(this.ruleForm).then(res =>{
                                if(res.status == 200){
                                    if (res.data.code == 200001){
                                        ElMessage({message:"注册成功", type:'success'})
                                        this.$router.push('/p_login')
                                    }else{
                                        ElMessage({message:res.data.message, type:'error'})
                                    }
                                }
                            }).catch(err =>{
                                ElMessage({message:err, type:'error'})
                            })
                        }
                    })

                }catch (error){
                    ElMessage({message:error.toString(), type:'error'})
                }
            },
            resetForm() {
                const form = unref(this.ruleFormRef)
                form.resetFields();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .register-container{
        width: 450px;
        min-height: 650px;
        margin: 80px auto;
        align-items: center;
        padding-top: 70px;
    }
    .register-tabs{
        /*width: 50%;*/
        align-items: center;
    }
    .registerFrom{
        /*line-height: 20px;*/
    }
    .register-tabs :deep(.el-tabs__item){
        font-size: 20px;
    }
    .register-tabs :deep(.el-tabs__active-bar){
        height: 5px;
    }
    .register-tabs :deep(.el-tabs__header){
        /*width: 50%;*/
        margin: 0 auto;
    }
    .register-tabs :deep(.el-tabs__content){
        /*width: 50%;*/
        margin: 40px auto ;
    }
    .register-tabs :deep(.el-tabs__nav-wrap:after){
        position: static !important;
    }
</style>
