import {request} from "@/utils/request";

export const articleList = data =>{
    return request({
        method: 'POST',
        url: 'articleList/',
        data
    })
}

export const articleShow = data =>{
    return request({
        method: 'POST',
        url:'showArticle/',
        data
    })
}
