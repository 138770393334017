<template>
    <div class="mytousu">
        <div class="subnav">
            <a href="/" title="投诉">首页</a>
            <span v-if="curUser.category == 1">> 我的投诉</span>
            <span v-else-if="curUser.category ==3">>我收到的投诉</span>
            <span v-else-if="curUser.category ==5">>我参与的投诉</span>
            <span v-else></span>
        </div>
        <div class="mytousu-main">
            <el-tabs v-model="activeTab" type="card" class="" stretch="true" >
                <el-tab-pane name="all">
                    <template #label>
                        <span class="custom-tabs-label">全部<el-badge :value="allDataNum" v-if="allDataNum>0" class="item"></el-badge></span>
                    </template>
                        <div class="tousu_con" v-for="item in allData" :key="item" @click="showComplaintInfo(item.complaint_id)">
                            <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                            <a class="box" :href="item.url" target="_blank">
                                <div class="img">
                                    <el-avatar
                                            class="avatar"
                                            shape="circle"
                                            fit="cover"
                                            :size="60"
                                    >
                                        <img :src="item.imgURL" @error="setDefaultUser"/>
                                    </el-avatar>
<!--                                    <img :src="item.imgURL">-->
                                </div>
                                <div class="tit">
                                    <span class="name">{{item.userName}}</span>
                                    <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                                </div>
                                <h1 class="">{{item.title}}</h1>
                                <p>{{item.desc}}</p>
                            </a>
                            <div class="tsFoot">
                                <ul class="list">
                                    <li>
                                        <span>[投诉对象]</span>{{item.tousu_target}}
                                    </li>
                                    <li>
                                        <span>[投诉要求]</span>{{item.tousu_appeal}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="loading" v-if="allTabLoading">
                            <span>{{allTabLoadText}}</span>
                        </div>
                </el-tab-pane>
                <el-tab-pane name="operating">
                    <template #label>
                        <span class="custom-tabs-label" v-if="curUser.category !== 3">处理中<el-badge :value="operatingDataNum" v-if="operatingDataNum>0" class="item"/></span>
                        <span class="custom-tabs-label" v-else>待处理<el-badge :value="operatingDataNum" v-if="operatingDataNum>0" class="item"/></span>
                    </template>
                        <div class="tousu_con" v-for="item in operatingData" :key="item" @click="showComplaintInfo(item.complaint_id)">
                            <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                            <a class="box" :href="item.url" target="_blank">
                                <div class="img">
                                    <el-avatar
                                            class="avatar"
                                            shape="circle"
                                            fit="cover"
                                            :size="60"
                                    >
                                        <img :src="item.imgURL" @error="setDefaultUser"/>
                                    </el-avatar>
                                </div>
                                <div class="tit">
                                    <span class="name">{{item.userName}}</span>
                                    <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                                </div>
                                <h1 class="">{{item.title}}</h1>
                                <p>{{item.desc}}</p>
                            </a>
                            <div class="tsFoot">
                                <ul class="list">
                                    <li>
                                        <span>[投诉对象]</span>{{item.tousu_target}}
                                    </li>
                                    <li>
                                        <span>[投诉要求]</span>{{item.tousu_appeal}}
                                    </li>
                                </ul>
                                <div class="tsRightOper">
                                    <el-button v-if="item.user_id === curUser.user_id" @click="showComplaintInfo(item.complaint_id)">补充投诉</el-button>
                                    <el-button v-else @click="showComplaintInfo(item.complaint_id)">回复</el-button>
                                </div>
                            </div>
                        </div>
                        <div class="loading" v-if="operatingTabLoading">
                            <span>{{operatingTabLoadText}}</span>
                        </div>
                </el-tab-pane>
                <el-tab-pane v-if="curUser.category === 1" name="noPass">
                    <template #label>
                        <span class="custom-tabs-label">未通过<el-badge :value="noPassDataNum" v-if="noPassDataNum>0" class="item"/></span>
                    </template>
                        <div class="tousu_con" v-for="item in noPassData" :key="item" @click="showComplaintInfo(item.complaint_id)">
                            <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                            <a class="box" :href="item.url" target="_blank">
                                <div class="img">
                                    <el-avatar
                                            class="avatar"
                                            shape="circle"
                                            fit="cover"
                                            :size="60"
                                    >
                                        <img :src="item.imgURL" @error="setDefaultUser"/>
                                    </el-avatar>
                                </div>
                                <div class="tit">
                                    <span class="name">{{item.userName}}</span>
                                    <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                                </div>
                                <h1 class="">{{item.title}}</h1>
                                <p>{{item.desc}}</p>
                            </a>
                            <div class="tsFoot">
                                <ul class="list">
                                    <li>
                                        <span>[投诉对象]</span>{{item.tousu_target}}
                                    </li>
                                    <li>
                                        <span>[投诉要求]</span>{{item.tousu_appeal}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="loading" v-if="noPassTabLoading">
                            <span>{{noPassTabLoadText}}</span>
                        </div>
                </el-tab-pane>
                <el-tab-pane v-if="curUser.category == 3" name="replenish">
                    <template #label>
                        <span class="custom-tabs-label">已回复<el-badge :value="operatedDataNum" v-if="operatedDataNum>0" class="item"/></span>
                    </template>
                        <div class="tousu_con" v-for="item in operatedData" :key="item" @click="showComplaintInfo(item.complaint_id)">
                            <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                            <a class="box" :href="item.url" target="_blank">
                                <div class="img">
                                    <el-avatar
                                            class="avatar"
                                            shape="circle"
                                            fit="cover"
                                            :size="60"
                                    >
                                        <img :src="item.imgURL" @error="setDefaultUser"/>
                                    </el-avatar>
                                </div>
                                <div class="tit">
                                    <span class="name">{{item.userName}}</span>
                                    <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                                </div>
                                <h1 class="">{{item.title}}</h1>
                                <p>{{item.desc}}</p>
                            </a>
                            <div class="tsFoot">
                                <ul class="list">
                                    <li>
                                        <span>[投诉对象]</span>{{item.tousu_target}}
                                    </li>
                                    <li>
                                        <span>[投诉要求]</span>{{item.tousu_appeal}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="loading" v-if="operatedTabLoading">
                            <span>{{operatedTabLoadText}}</span>
                        </div>
                </el-tab-pane>
                <el-tab-pane v-if="[1,3,5].indexOf(curUser.category)>=0" name="success">
                    <template #label>
                        <span class="custom-tabs-label">完成<el-badge :value="successDataNum" v-if="successDataNum>0" class="item"/></span>
                    </template>
                        <div class="tousu_con" v-for="item in successData" :key="item" @click="showComplaintInfo(item.complaint_id)">
                            <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                            <a class="box" :href="item.url" target="_blank">
                                <div class="img">
                                    <el-avatar
                                            class="avatar"
                                            shape="circle"
                                            fit="cover"
                                            :size="60"
                                    >
                                        <img :src="item.imgURL" @error="setDefaultUser"/>
                                    </el-avatar>
                                </div>
                                <div class="tit">
                                    <span class="name">{{item.userName}}</span>
                                    <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                                </div>
                                <h1 class="">{{item.title}}</h1>
                                <p>{{item.desc}}</p>
                            </a>
                            <div class="tsFoot">
                                <ul class="list">
                                    <li>
                                        <span>[投诉对象]</span>{{item.tousu_target}}
                                    </li>
                                    <li>
                                        <span>[投诉要求]</span>{{item.tousu_appeal}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="loading" v-if="successTabLoading">
                            <span>{{successTabLoadText}}</span>
                        </div>
                </el-tab-pane>
            </el-tabs>

        </div>
    </div>
</template>

<script>
    import {myts} from '@/api/user'
    import {ElMessage} from 'element-plus'
    import webStorage from "@/utils/webStorage";
    import defaultUser from '@/assets/icons/default-user.png'
    export default {
        name: "myComplaint",
        data(){
            return{
                activeTab:'all',
                allData:[],
                operatingData:[],
                operatedData:[],
                noPassData:[],
                successData:[],
                allDataNum: 0,
                operatingDataNum: 0,
                operatedDataNum: 0,
                noPassDataNum: 0,
                successDataNum: 0,
                allDataDisabled: false,
                operatingDataDisabled: false,
                replenishDataDisabled: false,
                noPassDataDisabled: false,
                successDataDisabled: false,
                allTabLoading: false,
                allTabLoadText: '正在加载...',
                operatingTabLoading: false,
                operatingTabLoadText:'正在加载...',
                noPassTabLoading: false,
                noPassTabLoadText:'正在加载...',
                operatedTabLoading: false,
                operatedTabLoadText:'正在加载...',
                successTabLoading: false,
                successTabLoadText:'正在加载...',
                tableData:this.allData,
                curUser:{},
                allTagQueryParams:{
                    page: -1,
                    pageSize: 10,
                    totalPage: 0,
                    queryDataStatus:'0,1,3,4,5,6'
                },
                operatingTagQueryParams:{
                    page: -1,
                    pageSize: 10,
                    totalPage: 0,
                    queryDataStatus:'0,1,3,4,5'
                },
                operatedTagQueryParams:{
                    page: -1,
                    pageSize: 10,
                    totalPage: 0,
                    queryDataStatus:'4'
                },
                replenishTagQueryParams:{
                    page: -1,
                    pageSize: 10,
                    totalPage: 0,
                    queryDataStatus:'4'
                },
                noPassTagQueryParams:{
                    page: -1,
                    pageSize: 10,
                    totalPage: 0,
                    queryDataStatus:'2'
                },
                successTagQueryParams:{
                    page: -1,
                    pageSize: 10,
                    totalPage: 0,
                    queryDataStatus:'6'
                },

            }
        },
        methods:{
            setDefaultUser(e){
                e.target.src = defaultUser
            },
            loadUserInfo(){
                this.curUser = JSON.parse(webStorage.getItem('localStorage','user'))
            },
            operatingComplaints(){
                this.operatingTagQueryParams.page += 1
                if(this.operatingTagQueryParams.page > this.operatingTagQueryParams.totalPage){
                    return
                }
                this.operatingTabLoading = true
                myts(this.operatingTagQueryParams).then(res =>{
                    if(res.status === 200 && res.data.code=== 200001) {
                        let listData = res.data.data.complaintData
                        let curPageInfo = res.data.data.pageInfo
                        this.operatingDataNum = curPageInfo.totalNum
                        let len = this.operatingData.length

                        if (listData && listData.length === 0 && !len) {
                            this.operatingTabLoadText = '没有找到相关数据'

                        } else if (listData && listData.length < 10) {
                            this.operatingTabLoadText = '暂无更多数据'
                            this.operatingData = this.operatingData.concat(listData)
                            this.operatingTagQueryParams.page = curPageInfo.page
                            this.operatingTagQueryParams.totalPage = curPageInfo.totalPage
                            this.operatingDataDisabled = true
                        } else {
                            this.operatingTabLoading = false
                            this.operatingData = this.operatingData.concat(listData)
                            this.operatingTagQueryParams.page = curPageInfo.page
                            this.operatingTagQueryParams.totalPage = curPageInfo.totalPage
                            if (this.operatingTagQueryParams.page === this.operatingTagQueryParams.totalPage) {
                                this.operatingDataDisabled = true
                            }
                        }
                    }
                }).catch(err =>{
                    ElMessage({message:err,type:'error'})
                })
            },
            noPassComplaints(){
                this.noPassTagQueryParams.page += 1
                if(this.noPassTagQueryParams.page > this.noPassTagQueryParams.totalPage){
                    return
                }
                this.noPassTabLoading = true
                myts(this.noPassTagQueryParams).then(res =>{
                    if(res.status === 200 && res.data.code=== 200001) {
                        let listData = res.data.data.complaintData
                        let curPageInfo = res.data.data.pageInfo
                        this.noPassDataNum = curPageInfo.totalNum
                        let len = this.noPassData.length

                        if (listData && listData.length === 0 && !len) {
                            this.noPassTabLoadText = '没有找到相关数据'

                        } else if (listData && listData.length < 10) {
                            this.noPassTabLoadText = '暂无更多数据'
                            this.noPassData = this.noPassData.concat(listData)
                            this.noPassTagQueryParams.page = curPageInfo.page
                            this.noPassTagQueryParams.totalPage = curPageInfo.totalPage
                            this.noPassDataDisabled = true
                        } else {
                            this.noPassTabLoading = false
                            this.noPassData = this.noPassData.concat(listData)
                            this.noPassTagQueryParams.page = curPageInfo.page
                            this.noPassTagQueryParams.totalPage = curPageInfo.totalPage
                            if (this.noPassTagQueryParams.page === this.noPassTagQueryParams.totalPage) {
                                this.noPassDataDisabled = true
                            }
                        }
                    }
                }).catch(err =>{
                    ElMessage({message:err,type:'error'})
                })
            },
            operatedComplaints(){
                this.operatedTagQueryParams.page += 1
                if(this.operatedTagQueryParams.page > this.operatedTagQueryParams.totalPage){
                    return
                }
                this.operatedTabLoading = true
                myts(this.operatedTagQueryParams).then(res =>{
                    if(res.status === 200 && res.data.code=== 200001) {
                        let listData = res.data.data.complaintData
                        let curPageInfo = res.data.data.pageInfo
                        this.operatedDataNum = curPageInfo.totalNum
                        let len = this.operatedData.length

                        if (listData && listData.length === 0 && !len) {
                            this.operatedTabLoadText = '没有找到相关数据'

                        } else if (listData && listData.length < 10) {
                            this.operatedTabLoadText = '暂无更多数据'
                            this.operatedData = this.operatedData.concat(listData)
                            this.operatedTagQueryParams.page = curPageInfo.page
                            this.operatedTagQueryParams.totalPage = curPageInfo.totalPage
                            this.replenishDataDisabled = true
                        } else {
                            this.operatedTabLoading = false
                            this.operatedData = this.operatedData.concat(listData)
                            this.operatedTagQueryParams.page = curPageInfo.page
                            this.operatedTagQueryParams.totalPage = curPageInfo.totalPage
                            if (this.operatedTagQueryParams.page === this.operatedTagQueryParams.totalPage) {
                                this.replenishDataDisabled = true
                            }
                        }
                    }
                }).catch(err =>{
                    ElMessage({message:err,type:'error'})
                })
            },
            successComplaints(){
                this.successTagQueryParams.page += 1
                if(this.successTagQueryParams.page > this.successTagQueryParams.totalPage){
                    return
                }
                this.successTabLoading = true
                myts(this.successTagQueryParams).then(res =>{
                    if(res.status === 200 && res.data.code=== 200001) {
                        let listData = res.data.data.complaintData
                        let curPageInfo = res.data.data.pageInfo
                        this.successDataNum = curPageInfo.totalNum
                        let len = this.successData.length

                        if (listData && listData.length === 0 && !len) {
                            this.successTabLoadText = '没有找到相关数据'

                        } else if (listData && listData.length < 10) {
                            this.successTabLoadText = '暂无更多数据'
                            this.successData = this.successData.concat(listData)
                            this.successTagQueryParams.page = curPageInfo.page
                            this.successTagQueryParams.totalPage = curPageInfo.totalPage
                            this.successDataDisabled = true
                        } else {
                            this.successTabLoading = false
                            this.successData = this.successData.concat(listData)
                            this.successTagQueryParams.page = curPageInfo.page
                            this.successTagQueryParams.totalPage = curPageInfo.totalPage
                            if (this.successTagQueryParams.page === this.successTagQueryParams.totalPage) {
                                this.successDataDisabled = true
                            }
                        }
                    }
                }).catch(err =>{
                    ElMessage({message:err,type:'error'})
                })
            },
            myComplaints(){
                this.allTagQueryParams.page += 1
                if(this.allTagQueryParams.page > this.allTagQueryParams.totalPage){
                    return
                }
                this.allTabLoading = true
                myts(this.allTagQueryParams).then(res =>{
                    if(res.status === 200 && res.data.code=== 200001) {
                        let listData = res.data.data.complaintData
                        let curPageInfo = res.data.data.pageInfo
                        this.allDataNum = curPageInfo.totalNum
                        let len = this.allData.length

                        if (listData && listData.length === 0 && !len) {
                            this.allTabLoadText = '没有找到相关数据'

                        } else if (listData && listData.length < 10) {
                            this.allTabLoadText = '暂无更多数据'
                            this.allData = this.allData.concat(listData)
                            this.allTagQueryParams.page = curPageInfo.page
                            this.allTagQueryParams.totalPage = curPageInfo.totalPage
                            this.allDataDisabled = true
                        } else {
                            this.allTabLoading = false
                            this.allData = this.allData.concat(listData)
                            this.allTagQueryParams.page = curPageInfo.page
                            this.allTagQueryParams.totalPage = curPageInfo.totalPage
                            if (this.allTagQueryParams.page === this.allTagQueryParams.totalPage) {
                                this.allDataDisabled = true
                            }
                        }
                    }
                }).catch(err =>{
                    ElMessage({message:err,type:'error'})
                })
            },
            showComplaintInfo(val){
                const route = this.$router.resolve({
                    path:'/p_complaintInfo',
                    query:{
                        complaintId : val
                    }
                })
                window.open(route.href,'_blank')
            },
            //获取当前可视范围的高度
            getClientHeight() {
                var clientHeight = 0;
                if (document.body.clientHeight && document.documentElement.clientHeight) {
                    clientHeight = Math.min(document.body.clientHeight, document.documentElement.clientHeight)
                } else {
                    clientHeight = Math.max(document.body.clientHeight, document.documentElement.clientHeight)
                }
                return clientHeight
            },
            //获取文档完整的高度
            getScrollHeight() {
                return Math.max(document.body.scrollHeight, document.documentElement.scrollHeight)
            },
            //获取当前滚动条的位置
            getScrollTop() {
                var scrollTop = 0;
                //window.pageYOffset = document.documentElement.scrollTop
                if (document.documentElement && document.documentElement.scrollTop) {
                    scrollTop = document.documentElement.scrollTop
                } else if (document.body) {
                    scrollTop = document.body.scrollTop
                }
                return scrollTop
            },
            windowScroll() {
                //获取三个值
                var scrollTop = this.getScrollTop()
                var clientHeight = this.getClientHeight()
                var scrollHeight = this.getScrollHeight()
                //如果满足公式则，确实到底了
                if(scrollTop+clientHeight == scrollHeight){
                    setTimeout(()=> {
                        if (this.activeTab == "all") {
                            this.myComplaints();
                        } else if (this.activeTab == "operating") {
                            this.operatingComplaints();
                        } else if (this.activeTab == "noPass") {
                            this.noPassComplaints();
                        } else if (this.activeTab == "replenish") {
                            this.replenishComplaints();
                        } else if (this.activeTab == "success") {
                            this.successComplaints();
                        }
                    },1000);
                }
            }
        },
        beforeCreate(){

        },
        created(){
            this.loadUserInfo()
            if(this.curUser.category === 1){
                this.allTagQueryParams.queryDataStatus = '0,1,2,3,4,5,6';
                this.operatingTagQueryParams.queryDataStatus = '0,1,3,4,5';
                this.noPassComplaints();
            }else if(this.curUser.category === 3){
                this.allTagQueryParams.queryDataStatus = '3,4,5,6';
                this.operatingTagQueryParams.queryDataStatus = '1,3,5';
                this.operatedComplaints();
            }else if(this.curUser.category === 5){
                this.allTagQueryParams.queryDataStatus = '4,5,6';
                this.operatingTagQueryParams.queryDataStatus = '4,5';
            }
            this.myComplaints();
            this.operatingComplaints();
            this.successComplaints();
        },
        mounted(){
            window.addEventListener('scroll', this.windowScroll,true)
        },
        unmounted() {
            window.removeEventListener("scroll", this.windowScroll,true);//销毁滚动事件
        }
    }
</script>

<style scoped>
    .mytousu{
        width: 100%;
        min-height: 700px;
        margin: 80px auto;
        padding: 10px 10px;
        /*background: #ffffff;*/
        /*display: flex;*/
        /*flex-direction: column;*/
        /*align-items: flex-start;*/
    }
    .mytousu .subnav{
        /*margin-bottom: 20px;*/
        /*font-size: 18px;*/

        line-height: 20px;
        font-weight: 500;
        /*width: 98%;*/
        border-bottom: 3px solid var(--el-color-primary-light-5);
        /*margin-bottom: 20px;*/
        font-size: 18px;
        display: flex;
        padding: 10px;
    }
    :deep(.el-tabs--card>.el-tabs__header .el-tabs__item){
        height: 50px;
        width: 120px;
    }
    :deep(.el-tabs--card>.el-tabs__header){
        height: 50px;
    }
    .item{
        margin-top: -10px;
    }
    /*.el-badge{*/
    /*    margin-top: 5px;*/
    /*}*/
    :deep(.el-tabs--card>.el-tabs__content){
        /*font-size: 32px;*/
        /*width: 700px;*/
    }
    .mytousu-main{
        margin-top: 20px;
        background-color: #ffffff;
    }
    .custom-tabs-label{
        vertical-align: middle;
        margin-left: 4px;
    }
    /*:deep(.el-badge__content.is-fixed){*/
        /*top:4px;*/
        /*right: calc(-7px + var(--el-badge-size)/2);*/
    /*}*/
    .tousu_con {
        /*background: #fff;*/
        position: relative;
        /*width: 100%;*/
        border-bottom: 2px dotted #979797;
        cursor: pointer;
        padding: 0 20px 20px 20px;
    }
    /*.tousu_con:nth-child(even){*/
    /*    background: #f1f1f1;*/
    /*}*/
    .icon-status {
        position: absolute;
        right: 15px;
        top: 20px;
        background-size: 100%;
        font-size: 14px;
        line-height: 30px;
        color: #fff;
        text-align: center;
    }
    .icontstj{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/daishenhe.png") no-repeat;
        background-size: cover;
    }
    .iconyishenhe{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/yishenhe.png") no-repeat;
        background-size: cover;
    }
    .iconshenheshibai{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/shenheshibai.png") no-repeat;
        background-size: cover;
    }
    .iconyifenpei{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/yifenpei.png") no-repeat;
        background-size: cover;
    }
    .iconDone{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/finished.png") no-repeat;
        background-size: cover;
    }
    .iconAnswer{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/replied.png") no-repeat;
        background-size: cover;
    }

    .iconBuchong{
        width: 60px;
        height: 60px;
        background: url("@/assets/icons/yibuchong.png") no-repeat;
        background-size: cover;
    }
    .tousu_con .box {
        display: block;
    }
    a {
        text-decoration: none;
        outline: none;
        color: #333;
    }
    .tousu_con .img {
        width: 60px;
        height: 60px;
        float: left;
        margin: 20px 0;
        border-radius: 50%;
        background-size: 100%;
    }
    .tousu_con .img img {
        border-radius: 100%;
        width: 60px;
        height: 60px;
    }
    .tousu_con .tit {
        overflow: hidden;
        padding: 30px 15px 20px;
        line-height: 22px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    .tousu_con .name {
        font-size: 16px;
        color: #212121;
    }
    .tousu_con .time {
        display: block;
        font-size: 14px;
        color: #84848A;
        margin-top: .04rem;
        line-height: 20px;
    }
    .tousu_con h1.blackcat-hot {
        padding-left: 25px;
        background: url("//n.sinaimg.cn/finance/blackcat/pc/img/heimao_hot1x.png") 0 8px no-repeat;
        background-image: -webkit-image-set(url("//n.sinaimg.cn/finance/blackcat/pc/img/heimao_hot1x.png") 1x, url("//n.sinaimg.cn/finance/blackcat/pc/img/heimao_hot2x.png") 2x);
    }
    .tousu_con h1 {
        font-size: 20px;
        color: #000;
        line-height: 34px;
        text-align: justify;
        clear: both;
        padding-right: 20px;
    }
    .tousu_con p {
        overflow: hidden;
        width: 640px;
        margin: 10px 0;
        font-size: 14px;
        color: #5D5D5D;
        line-height: 26px;
        text-align: justify;
    }
    .tousu_con .tsFoot{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .tousu_con .tsFoot .list {
        font-size: 14px;
        color: #5D5D5D;
        line-height: 26px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .tousu_con ul{
        list-style: none;
        padding-inline-start: 0;
    }
    .tousu_con .list span:first-child {
        color: #212121;
        padding-right: 5px;
    }
    .tsRightOper{
        float: right;
        display: flex;
        flex-direction: column;
    }
    .tsRightOper button{
        margin-bottom: 5px;
    }
</style>
