<template>
    <van-nav-bar left-arrow right-text="返回首页" @click-left="onClickLeft" @click-right="onClickRight">
        <template #title>
            <span>投诉编号：{{complaintId}}</span>
        </template>
    </van-nav-bar>
    <section>
        <van-cell-group inset class="tousu-info">
            <div class="tousu-title paddingleft">
                <h1>{{tousu.title}}</h1>
                <div v-show="[1,3,4,5].indexOf(tousu.status_desc)!==-1 && curUser && curUser.user_id === tousu.user_id" class="rgithBtn"><van-button size="small" type="primary" @click="finishComplaint">确认完成</van-button></div>
            </div>
            <ul class="ts-list">
                <li>
                    <label>[投诉对象]</label>{{tousu.target}}
                </li>
                <li>
                    <label>[投诉问题]</label>{{tousu.issue}}
                </li>
                <li>
                    <label>[投诉诉求]</label>{{tousu.appeal}}
                </li>
                <li>
                    <label>[涉诉金额]</label>{{tousu.amount}}元
                </li>
                <li v-if="tousu.is_anon === 0 && curUser && curUser.user_id === tousu.targetUser">
                    <label>联系方式：</label>{{tousu.hidPhone}}
                    <van-popover
                            v-model="showPopover"
                            trigger="click"
                            placement="top"
                            :actions="actionPhoneNo"
                            close-on-click-outside
                            close-on-click-action
                            close-on-click-overlay
                    >
                        <template #reference>
                            <a href="javascript:;" @click="showPhoneNo">查看完整联系方式</a>
                        </template>
                    </van-popover>
                </li>
                <li>
                    <label>[投诉进度]</label><span class="statu-text">{{tousu.status}}</span>
                </li>
            </ul>
            <template v-if="tousu.is_help === 1">
                <div class="law-help" >
                    <span class="font-circle">帮</span>
                    <span >律师已参与</span>
                </div>
            </template>
        </van-cell-group>
    </section>
    <section>
        <van-cell-group inset>
            <van-steps direction="vertical" :active="0">
                <van-step v-for="(item,i) in tsStep" :key="item" >
                    <div class="step-content">
                        <h3>{{item.status}}</h3>
                        <p>{{item.date}}</p>
                        <p>{{item.content}}</p>
                        <div class="step-img">
                            <van-image width="2rem" height="2rem" v-for="img in item.attachs" :key="img" :src="img.url" @click="previewImages(item.attachs)"/>
                        </div>
                    </div>
                    <div v-if="i === 0 && [1,3,4,5].indexOf(tousu.status_desc)!==-1 && curUser && ((curUser.category ===5 && tousu.is_help !== 1) || curUser.user_id === tousu.user_id || curUser.user_id === tousu.targetUser)">
                        <van-button size="small" type="primary" @click="overlayShow = true">回复/补充</van-button>
                    </div>
                    <template #active-icon>
                        <van-image
                                round
                                width="1rem"
                                height="1rem"
                                :src="item.avatar"
                                style="top: 0px;"
                        />
                    </template>
                    <template #inactive-icon>
                        <van-image
                                round
                                width="1rem"
                                height="1rem"
                                :src="item.avatar"
                                style="top: 0px;"
                        />
                    </template>
                </van-step>
            </van-steps>
        </van-cell-group>
    </section>
    <van-popup
            v-model:show="overlayShow"
            position="bottom"
            :style="{ height: '50%' }"
    >
        <van-form @submit="replyTs">
            <van-cell-group inset >
                <van-field v-model="replyContent"
                           name="replyContent"
                           label="回复/补充"
                           rows="5"
                           type="textarea"
                           maxlength="1000"
                           show-word-limit
                           clearable="true"
                           @clear="clearContent"
                           placeholder="请您依据实际情况输入回复/补充内容"
                           :rules="[{required:true,message:'请输入回复/补充内容'}]"/>
                <van-field label="添加附件" model-value="" readonly/>
                <van-uploader style="float: left;margin: 0.1rem" :before-read="beforeRead" v-model="fileList" accept=".jpg,.jpeg,.png" multiple :max-count="5">
                    <template #preview-cover="{ file }">
                        <div class="preview-cover van-ellipsis">{{ file.name }}</div>
                    </template>
                </van-uploader>
            </van-cell-group>
            <van-row class="btn-row">
                <van-col span="10">
                    <van-button round block type="primary" native-type="submit">
                        提交
                    </van-button>
                </van-col>
            </van-row>
        </van-form>
    </van-popup>
    <section>
        <div class="safe-statment">
            <p class="text-item">声明：</p>
            <p class="text-item">1.以上内容仅代表投诉者本人，不代表北京市互联网金融消费者投诉平台立场。</p>
            <p class="text-item">2.未经授权，本平台案例禁止任何转载，违者将被追究法律责任。</p>
            <p class="text-item">3.北京市互联网金融消费者投诉平台处理投诉不收取任何费用，凡以北京市互联网金融消费者投诉平台名义收费的均为冒充、欺骗行为，请及时报警并与北京市互联网金融消费者投诉平台官方反馈。举报邮箱 biz@bjifia.com.cn。</p>
            <p class="text-item">4.请大家选择官方渠道解决消费纠纷，不要轻信第三方机构或个人办理退费以免受骗。请不要随意通过QQ或微信等方式添加陌生人，更不要给陌生人汇款，谨防被骗，如遇诈骗请及时报警。</p>
        </div>
    </section>
</template>

<script>
    import {ref} from 'vue';
    import {complaintInfo,finishTS,replyTS} from '@/api/complaint';
    import {showUserPhoneNo} from '@/api/user';
    import {useRouter} from 'vue-router';
    import webStorage from "@/utils/webStorage";
    import {showConfirmDialog,showNotify,showImagePreview} from 'vant';
    export default {
        name: "m_complaintInfo",
        setup(){
            const overlayShow = ref(false);
            const complaintId = ref('');
            const router = useRouter();
            const curUser = ref({
                user_id: '',
            });
            const tousu =ref({
                    title:'',
                    userName:'',
                    avatar:'',
                    date:'',
                    complaintId:'',
                    target:'',
                    issue: '',
                    appeal:'',
                    amount:0,
                    status:''
                });
            const tsStep = ref([]);
            const showComplaintInfo = () =>{
                complaintInfo({'complaint_id':complaintId.value}).then(res => {
                    tousu.value = res.data.data.complaintData;
                    tsStep.value = res.data.data.stepsData;
                })
            };
            const onClickLeft = () => history.back();
            const onClickRight = () =>{
                router.push('/m_index');
            };
            const finishComplaint = () => {
                showConfirmDialog({
                    title : '结束投诉',
                    message : '确认完成后该投诉单将自动关闭，请确认已达到您的预期诉求？'
                }).then(() =>{
                    finishTS({'complaintId':complaintId.value}).then(res =>{
                        console.log(res);
                        if(res.status === 200 && res.data.code === 200001){
                            showNotify({message:'投诉已确认完成',type:'success'});
                            showComplaintInfo();
                        }
                    }).catch(error =>{
                        if(error.response.status === 401){
                            showNotify({message:'登录超时，请重新登录！',type:"warning"});
                            router.push('/m_login');
                        }
                    })
                }).catch(() =>{

                })
            };
            const clearContent = () =>{
                replyContent.value = '';
            };
            const fileList = ref([]);
            const replyContent = ref('');
            const replyTs = (() =>{
                let formData = new FormData();
                fileList.value.forEach(file =>{
                    //multipartFiles 后台接收名字
                    formData.append("multipartFiles",file.file);
                });
                formData.append('complaintId',complaintId.value);
                formData.append('replyContent',replyContent.value);
                replyTS(formData).then(res =>{
                    if(res.status ===200 && res.data.code === 200001){
                        showNotify({message: '数据已提交！',type:'success'});
                        replyContent.value = '';
                        fileList.value = [];
                        overlayShow.value = false;
                        showComplaintInfo();
                    }else{
                        showNotify({message:res.data.message,type:'error'})
                    }
                }).catch(error =>{
                    if(error.response.status === 401){
                        showNotify({message:'登录超时，请重新登录！',type:"warning"});
                        router.push('/m_login');
                    }
                })
            });
            const previewImages =((images) =>{
                let imageUrls = [];
                images.forEach((image) =>{
                    imageUrls.push(image.url);
                });
                showImagePreview({images:imageUrls});
            });
            const showPopover = ref(false);
            const phoneNo = ref('');
            const actionPhoneNo = ref([{text:''}]);

            const showPhoneNo = () =>{
                if(phoneNo.value===''){
                    showUserPhoneNo({'complaintId':complaintId.value}).then(res =>{
                        if(res.status ===200 && res.data.code === 200001){
                            phoneNo.value = res.data.data;
                            actionPhoneNo.value[0].text=phoneNo.value
                        }else{
                            showNotify({type:'warning',message:res.data.message})
                        }
                    }).catch(error =>{
                        if(error.response.status === 401){
                            showNotify({message:'登录超时，请重新登录！',type:"warning"});
                            router.push('/m_login');
                        }
                    })
                }
            };
            const beforeRead = (file) =>{
                console.log("上传前", file)
                const imgformat = /image\/(png|jpg|jpeg)$/
                if(!imgformat.test(file.type)){
                    showNotify({type:'warning',message:'请上传　jpg/jpeg/png格式图片！'})
                    return false
                }
                if(file.size > 1*1024*1024){
                    showNotify({type:'warning',message:'单张图片大小不能超过　1M！'})
                    return false
                }
                return true
            };
            return {showPopover,showPhoneNo,phoneNo,actionPhoneNo,previewImages,replyTs,replyContent,fileList,clearContent,overlayShow,complaintId,tousu,tsStep,showComplaintInfo,onClickLeft,onClickRight,curUser,finishComplaint,beforeRead};
        },
        created(){
            this.complaintId = this.$route.query.complaintId;
            this.showComplaintInfo();
            this.curUser = JSON.parse(webStorage.getItem('localStorage','user'));
            // this.curUser = JSON.parse(webStorage.getItem('localStorage','user'))
        },
    }
</script>

<style scoped>
    section{
        margin-bottom: 0.3rem;
        background-color: #fff;
    }
    .van-cell-group{
        /*--van-cell-group-background : #fff;*/
        margin-bottom: 0.2rem;
    }
    .van-cell{
        opacity: 0.8;
    }
    .tousu-info{
        display: flex;
        flex-direction: column;
    }
    .tousu-info .tousu-title{
        font-weight: lighter;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .tousu-info .tousu-title h1{
        font-size: 0.6rem;
        text-align: justify;
    }
    .tousu-info .tousu-title .rgithBtn{
        float: right;
    }
    .tousu-info .law-help{
        font-size: 0.375rem;
        color: var(--el-color-primary-dark-2);
        display: flex;
        align-items: center;
        margin-bottom: 0.1rem;
    }
    .tousu-info .law-help .font-circle{
        width: 0.7rem;
        height: 0.7rem;
        border: 2px solid var(--el-color-primary-dark-2);
        border-radius: 50%;
        margin-right: 0.2rem;
        font-size: 0.5rem;
    }
    .ts-list{
        list-style-type: none;
        float: left;
        line-height: 1rem;
        font-size: 0.4rem;
        /*font-weight: 500;*/
        /*padding-inline-start: 0px;*/
        display: flex;
        flex-direction: column;
        align-items: flex-start;

    }
    .ts-list li{
        /*float: left;*/
    }
    .ts-list li:last-child{
        /*margin-block-end: 1rem;*/
    }
    .ts-list li label{
        float: left;
        font-size: 0.4rem;
        font-weight: bold;
        margin-right: 0.1rem;
    }
    .ts-list .statu-text{
        color: var(--el-color-primary);
    }
    .van-steps{
        margin-top: 0.2rem;
    }
    .van-step{
        text-align: left;
        line-height: 0.5rem;
    }
    .van-step__title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .van-step__title h3{
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0.3rem;
    }
    .van-step__title p{
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0.3rem;
    }
    .van-step__title p:last-child{
        /*margin-block-end: 1rem;*/
    }
    .step-content .step-img{
        display: flex;
        flex-direction: row;
        margin-inline-start: 0.3rem;
        margin-block-start: 1rem;
        margin-block-end: 1rem;
    }
    .safe-statment {
        text-align: left;
        font-size: 0.3rem;
        color: #acacac;
        margin-bottom: 1rem;
        padding: 0.3rem;
    }
    .safe-statment p{
        margin-block-start: 0;
        margin-inline-start: 0.3rem;
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .block {
        width: 120px;
        height: 120px;
        background-color: #fff;
    }
</style>
