/**
 * 基于axios封装的请求模块
 */

import axios from 'axios';
import JSONbig from 'json-bigint';
import webStorage from "@/utils/webStorage.js";

// 创建一个 axios 实例，说白了就是复制了一个 axios
// 我们通过这个实例去发请求，把需要的配置配置给这个实例来处理
const request = axios.create({
    // baseURL: 'http://127.0.0.1:8000/', // 请求的(根)基础路径
    // baseURL: 'http://192.168.1.101:8000/', // 请求的(根)基础路径
    baseURL: 'https://bhjtsapi.bjifia.com.cn/', // 生产环境请求的(根)基础路径
    // 定义后端返回的原始数据的处理
    // 参数data就是后端返回的原始数据 （未经处理的JSON格式字符串）
    transformResponse: [function (data) {
        // axios 默认在内部使用JSON.parse 来处理原始数据
        try {
            return JSONbig.parse(data)
        } catch (error) {
            return data
        }
    }]
});
// axios.defaults.withCredentials = true;  //允许跨域
//Content-type 响应头
// axios.defaults.headers.post['Content-type']='application/json;charset=UTF-8';
// axios.defaults.headers.post['Access-Control-Allow-Origin']='https://tousu.bjifia.com.cn';
const remoteRequest = axios.create({
    // baseURL: 'http://114.116.229.149:9690/', // 请求的(根)基础路径
    // baseURL: 'http://192.168.1.101:10000/', // 请求的(根)基础路径
    baseURL: 'https://bhjtsmgr.bjifia.com.cn/', // 生产环境请求的(根)基础路径
    changeOrigin: true,
    // 定义后端返回的原始数据的处理
    // 参数data就是后端返回的原始数据 （未经处理的JSON格式字符串）
    transformResponse: [function (data) {
        // axios 默认在内部使用JSON.parse 来处理原始数据
        try {
            return data
        } catch (error) {
            return data
        }
    }]
})
// 请求拦截器
request.interceptors.request.use(function (config) {
    // 任何请求都会经过这里
    // config 是当前请求相关的配置信息对象
    const user = JSON.parse(webStorage.getItem('localStorage','user'))
    // 如果有用户登录信息，则设置token
    if (user) {
        config.headers.Authorization = `jwt ${user.token}`
    }
    return config
    },
    function (err) {
        // 失败会到这来
        return Promise.reject(err)
    })
// remoteRequest.interceptors.request.use(function (config) {
        // 任何请求都会经过这里
        // config 是当前请求相关的配置信息对象
        // const user = JSON.parse(webStorage.getItem('localStorage','user'))
        // 如果有用户登录信息，则设置token
        // if (user) {
        //     config.headers.Authorization = `jwt ${user.token}`
        // }
        // config.headers['Origin'] = 'https://tousu.bjifia.com.cn'
        // config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        // config.headers['Authorization'] =  `${user.token}`
        // return config
    // },
    // function (err) {
        // 失败会到这来
        // return Promise.reject(err)
    // })
// 导出请求方法
export {request,remoteRequest}
