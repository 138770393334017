<template>
    <van-tabbar v-model="active" safe-area-inset-bottom="true" fixed route>
        <van-tabbar-item to="/m_index">
            <span>首页</span>
            <template #icon>
                <van-icon name="home-o"/>
            </template>
        </van-tabbar-item>
        <van-tabbar-item to="/m_complaint" v-if="userInfo.category <= 1">
            <span style="color: var(--van-tabbar-item-active-color)">我要投诉</span>
            <template #icon>
                <van-icon color="var(--van-tabbar-item-active-color)" v-if="active === 1" size="1rem" name="add-o"/>
                <van-icon color="var(--van-tabbar-item-active-color)" v-else size="1rem" name="add"/>
            </template>
        </van-tabbar-item>
        <van-tabbar-item icon="user-o" to="/m_user" >我的</van-tabbar-item>
    </van-tabbar>
</template>

<script>
    import {ref} from 'vue';
    import { showToast } from 'vant';
    import webStorage from '@/utils/webStorage.js'
    export default {
        name: "m_footer",
        setup(){
            const active = ref(0);
            const showPopover = ref(false);
            const userInfo = ref({
                category:1
            });
            const onSelect = (action) => showToast(action.text);
            return {active,showPopover,onSelect,userInfo};
        },
        created(){
            let curUser = JSON.parse(webStorage.getItem('localStorage','user'));
            if(curUser){
                this.userInfo.category = curUser.category;
            }
            window.addEventListener('removeItem',()=>{
                let rmUser = JSON.parse(webStorage.getItem('localStorage','user'));
                if(!rmUser){
                    this.userInfo.category = 0;
                }
            });
        },
    }
</script>

<style scoped>
    .van-tabbar{
        height: 60px;
    }
    .my-icon-style{
        /*color: #fff;*/
    }
</style>
