<template>
    <van-nav-bar title="绑定手机" left-arrow @click-left="onClickLeft"/>
    <van-form ref="changeMobileRef" @submit="onSubmit" >
        <van-cell-group inset>
            <van-field v-model="mobileForm.mobile" name="mobile" placeholder="请输入新手机号"
                       :rules="[{ required: true, message:'请输入新手机号'},
                       {pattern:/^[1][3,4,5,6,7,8,9][0-9]{9}$/,message:'手机号格式不正确'},
                       {validator:checkMobileRepied,message:validatePhoneMsg }]" @update:model-value="checkPhone"/>
            <van-field
                    v-model="mobileForm.captcha"
                    center
                    clearable
                    name="captcha"
                    placeholder="请输入图片验证码"
                    :rules="[{ required: true, message: '请填写验证码'}]"
            >
                <template #right-icon>
                    <van-image :src="captchaUrl" @click="refresh"/>
                </template>
            </van-field>
            <van-field
                    v-model="mobileForm.shortMsg"
                    center
                    clearable
                    label="短信验证码"
                    placeholder="请输入短信验证码"
            >
                <template #button>
                    <van-button size="small" type="primary" :disabled="smsBtnDis" @click="sendMsg" >{{smsBtnContent}}</van-button>
                </template>
            </van-field>
        </van-cell-group>
        <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit" :disabled="nextBtnDis">
                绑定手机
            </van-button>
        </div>
    </van-form>

</template>

<script>
    import {ref,computed} from 'vue';
    import { getCaptcha ,sendmsg,changeMobile,loadUserInfo,checkPhoneRepied} from '@/api/user';
    import {showNotify,closeToast, showLoadingToast} from 'vant';
    import webStorage from "@/utils/webStorage.js";
    import {useRouter} from 'vue-router';
    export default {
        name: "m_stepTwo",

        setup(){
            const router = useRouter();
            const mobileForm = ref({
                mobile:'',
                captcha:'',
                hashkey:'',
                shortMsg:'',
                requestType:'changeMobile'
            });
            const checkPhoneRetMsg = ref('');
            const validatePhoneMsg = () =>{
                return checkPhoneRetMsg;
            };
            const phoneRepied = ref(true);
            const checkPhone = async () =>{
                if(!mobileForm.value.mobile){
                    phoneRepied.value = true;
                    return;
                }
                let val = mobileForm.value.mobile;
                var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (!myreg.test(val)) {
                    phoneRepied.value = true;
                }else{
                    await checkPhoneRepied({mobile:val}).then(res =>{
                        if(res.status===200 && res.data.code === 400001){
                            phoneRepied.value = true;
                        }else{
                            phoneRepied.value = false;
                        }
                    });
                }
            };
            const checkMobileRepied = (val)=>
                new Promise((resolve) => {
                    showLoadingToast('手机号验证...');
                    setTimeout(() => {
                        checkPhoneRepied({mobile:val}).then(res => {
                            closeToast();
                            if (res.status === 200 && res.data.code === 200001) {
                                resolve(true);
                            }else{
                                checkPhoneRetMsg.value = res.data.message;
                                resolve(false);
                            }
                        });
                    }, 1000);
                });

            const captchaUrl = ref('');
            const smsBtnDis = computed(() => mobileForm.value.captcha.length<=0 || phoneRepied.value);
            const nextBtnDis = computed(()=> mobileForm.value.captcha.length<=0 || mobileForm.value.shortMsg.length<=0);
            const smsBtnContent = ref('获取验证码');
            const refresh = () =>{
                getCaptcha().then(res => {
                    mobileForm.value.hashkey=res.data.data.hashkey;
                    captchaUrl.value = res.data.data.image_url;
                }).catch(() => {
                    showNotify({ type: 'danger', message: '登录失败' });
                })
            };
            const count = ref(0);
            const timer = ref(0);
            const sendMsg = () =>{
                let reg = /^1[3|5|7|8|9]\d{9}$/
                if(!(reg.test(mobileForm.value.mobile))){
                    showNotify({message: "请输入正确的手机号",type:'warning'})
                    return
                }
                sendmsg(mobileForm.value).then(res =>{
                    console.log(res)
                    if(res.status===200 && res.data.code===200001){
                        smsBtnDis.value = true;
                        const TIME_COUNT = ref(60);
                        count.value = TIME_COUNT.value;
                        timer.value = window.setInterval(() => {
                            if (count.value > 0 && count.value <= TIME_COUNT.value){
                                smsBtnDis.value = true;
                                count.value--;
                                smsBtnContent.value = count.value + 's后重新获取';
                            } else {
                                smsBtnDis.value = false;
                                smsBtnContent.value = '获取短信';
                                clearInterval(timer);
                                timer.value = null;
                            }
                        }, 1000)
                    }else{
                        showNotify({type:'error', message:res.data.message});
                    }
                }).catch(error =>{
                    if(error.response.status === 401){
                        showNotify({message:'登录超时，请重新登录！',type:"warning"});
                        router.push('/m_login');
                    }
                });
            };
            const onSubmit = () =>{
                changeMobile(mobileForm.value).then(res =>{
                    console.log(res)
                    if(res.status === 200 && res.data.code === 200001){
                        showNotify({message:"手机号修改成功",type:"success"});
                        loadUserInfo().then(res1 =>{
                            if(res1.status === 200 && res1.data.code === 200001){
                                webStorage.setItem('localStorage','user',JSON.stringify(res1.data.data),60000*60*2)
                            }
                        });
                        router.push('/m_user');
                    }else{
                        showNotify({message:"手机号修改失败",type:"warning"});
                    }
                }).catch(error =>{
                    if(error.response.status === 401){
                        showNotify({message:'登录超时，请重新登录！',type:"warning"});
                        router.push('/m_login');
                    }
                })
            };
            const onClickLeft = () => history.back();
            return {validatePhoneMsg,checkPhoneRetMsg,checkPhone,checkMobileRepied,onClickLeft,mobileForm,captchaUrl,smsBtnDis,smsBtnContent,sendMsg,refresh,count,timer,nextBtnDis,onSubmit};
        },
        mounted(){
            // let userinfo = JSON.parse(webStorage.getItem('localStorage', 'user'));
            // this.mobileForm.mobile = userinfo.phone;
            this.refresh();
        }
    }
</script>

<style scoped>

</style>
