<template>
    <Article art_type="2"></Article>
</template>

<script >
    import Article from "./components/article"
    export default {
        name: "inverstorEducate",
        components: {Article},
        created(){
            let activeMenuEvent = document.createEvent('CustomEvent')
            activeMenuEvent.initCustomEvent('changeActiveMenu',false,false,'investor')
            window.dispatchEvent(activeMenuEvent)
        }
    }
</script>

<style scoped>

</style>
