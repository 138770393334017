<template>
        <van-row class="user-info">
            <van-col span="6">
                <van-image
                        round
                        width="2rem"
                        height="2rem"
                        :src="curUser.avatar"/>
            </van-col>
            <van-col span="12">
                <p class="name-blk">
                    <span class="tit">{{curUser.nikeName}}</span>
<!--                    <a href="javascript:;" class="link">文明公约</a>-->
                </p>
            </van-col>
            <van-col span="6">
                <router-link to="/my_userInfo" class="usermsg">编辑资料></router-link>
            </van-col>

        </van-row>
<!--    </section>-->
<!--    <section>-->
        <van-tabs v-model:active="active"  sticky swipeable>
            <van-tab name="all" :title="'全部('+allNum+')'">
                <van-pull-refresh v-model="allTsRefreshing" @refresh="onRefreshall" disabled>
                    <van-list
                            v-model:loading="allTsLoading"
                            :finished="allTsFinished"
                            finished-text="我是有底线的"
                            offset="10"
                            @load="onLoadAll"
                    >
                        <div class="tousu_con" v-for="item in allTsList" :key="item" @click="showComplaintInfo(item.complaint_id)">
                            <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                            <a class="box" :href="item.url" target="_blank">
                                <van-image
                                        round
                                        width="1rem"
                                        height="1rem"
                                        :src="item.imgURL"
                                />
                                <div class="tit">
                                    <span class="name" v-if="item.is_anon === 1">匿名</span>
                                    <span class="name" v-else>{{item.userName}}</span>
                                    <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                                </div>
                                <h1 class="">{{item.title}}</h1>
                                <p>{{item.desc}}</p>
                            </a>
                            <ul class="list">
                                <li>
                                    <span>[投诉对象]</span>{{item.tousu_target}}
                                </li>
                                <li>
                                    <span>[投诉要求]</span>{{item.tousu_appeal}}
                                </li>
                            </ul>
                            <template v-if="item.is_help === 1">
                                <div class="law-help" >
                                    <span class="font-circle">帮</span>
                                    <span >律师已参与</span>
                                </div>
                            </template>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </van-tab>
            <van-tab name="operating">
                <template #title v-if="curUser.category !== 3">处理中({{operatingNum}})</template>
                <template #title v-else>待处理({{operatingNum}})</template>
                <van-pull-refresh v-model="operatingTsRefreshing" @refresh="onRefreshOperating" disabled>
                    <van-list
                            v-model:loading="operatingTsLoading"
                            :finished="operatingTsFinished"
                            finished-text="我是有底线的"
                            offset="10"
                            @load="onLoadOperating"
                    >
                        <div class="tousu_con" v-for="item in operatingTsList" :key="item" @click="showComplaintInfo(item.complaint_id)">
                            <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                            <a class="box" :href="item.url" target="_blank">
                                <van-image
                                        round
                                        width="1rem"
                                        height="1rem"
                                        :src="item.imgURL"
                                />
                                <div class="tit">
                                    <span class="name" v-if="item.is_anon === 1">匿名</span>
                                    <span class="name" v-else>{{item.userName}}</span>
                                    <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                                </div>
                                <h1 class="">{{item.title}}</h1>
                                <p>{{item.desc}}</p>
                            </a>
                            <ul class="list">
                                <li>
                                    <span>[投诉对象]</span>{{item.tousu_target}}
                                </li>
                                <li>
                                    <span>[投诉要求]</span>{{item.tousu_appeal}}
                                </li>
                            </ul>
                            <template v-if="item.is_help === 1">
                                <div class="law-help" >
                                    <span class="font-circle">帮</span>
                                    <span >律师已参与</span>
                                </div>
                            </template>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </van-tab>
            <van-tab name="noPass" v-if="curUser.category === 1" :title="'未通过('+noPassNum+')'">
                <van-pull-refresh v-model="noPassTsRefreshing" @refresh="onRefreshNopass" disabled>
                    <van-list
                            v-model:loading="noPassTsLoading"
                            :finished="noPassTsFinished"
                            finished-text="我是有底线的"
                            offset="10"
                            @load="onLoadNoPass"
                    >
                        <div class="tousu_con" v-for="item in noPassTsList" :key="item" @click="showComplaintInfo(item.complaint_id)">
                            <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                            <a class="box" :href="item.url" target="_blank">
                                <van-image
                                        round
                                        width="1rem"
                                        height="1rem"
                                        :src="item.imgURL"
                                />
                                <div class="tit">
                                    <span class="name" v-if="item.is_anon === 1">匿名</span>
                                    <span class="name" v-else>{{item.userName}}</span>
                                    <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                                </div>
                                <h1 class="">{{item.title}}</h1>
                                <p>{{item.desc}}</p>
                            </a>
                            <ul class="list">
                                <li>
                                    <span>[投诉对象]</span>{{item.tousu_target}}
                                </li>
                                <li>
                                    <span>[投诉要求]</span>{{item.tousu_appeal}}
                                </li>
                            </ul>
                            <template v-if="item.is_help === 1">
                                <div class="law-help" >
                                    <span class="font-circle">帮</span>
                                    <span >律师已参与</span>
                                </div>
                            </template>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </van-tab>
            <van-tab name="replenish" v-if="curUser.category === 3" :title="'已回复('+operatedNum+')'">
                <van-pull-refresh v-model="operatedTsRefreshing" @refresh="onRefreshOperated" disabled>
                    <van-list
                            v-model:loading="operatedTsLoading"
                            :finished="operatedTsFinished"
                            finished-text="我是有底线的"
                            offset="10"
                            @load="onLoadOperated"
                    >
                        <div class="tousu_con" v-for="item in operatedTsList" :key="item" @click="showComplaintInfo(item.complaint_id)">
                            <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                            <a class="box" :href="item.url" target="_blank">
                                <van-image
                                        round
                                        width="1rem"
                                        height="1rem"
                                        :src="item.imgURL"
                                />
                                <div class="tit">
                                    <span class="name" v-if="item.is_anon === 1">匿名</span>
                                    <span class="name" v-else>{{item.userName}}</span>
                                    <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                                </div>
                                <h1 class="">{{item.title}}</h1>
                                <p>{{item.desc}}</p>
                            </a>
                            <ul class="list">
                                <li>
                                    <span>[投诉对象]</span>{{item.tousu_target}}
                                </li>
                                <li>
                                    <span>[投诉要求]</span>{{item.tousu_appeal}}
                                </li>
                            </ul>
                            <template v-if="item.is_help === 1">
                                <div class="law-help" >
                                    <span class="font-circle">帮</span>
                                    <span >律师已参与</span>
                                </div>
                            </template>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </van-tab>
            <van-tab name="finished" :title="'已完成('+successNum+')'">
                <van-pull-refresh v-model="successTsRefreshing" @refresh="onRefreshSuccess" disabled>
                    <van-list
                            v-model:loading="successTsLoading"
                            :finished="successTsFinished"
                            finished-text="我是有底线的"
                            offset="10"
                            @load="onLoadSuccess"
                    >
                        <div class="tousu_con" v-for="item in successTsList" :key="item" @click="showComplaintInfo(item.complaint_id)">
                            <div :class="{'icon-status':true,icontstj:item.status === 0,iconyishenhe:item.status === 1,iconshenheshibai:item.status ===2,iconyifenpei:item.status === 3,iconAnswer:item.status ===4 ,iconBuchong:item.status === 5,iconDone:item.status === 6}"></div>
                            <a class="box" :href="item.url" target="_blank">
                                <van-image
                                        round
                                        width="1rem"
                                        height="1rem"
                                        :src="item.imgURL"
                                />
                                <div class="tit">
                                    <span class="name" v-if="item.is_anon === 1">匿名</span>
                                    <span class="name" v-else>{{item.userName}}</span>
                                    <span class="time">{{item.tousuTime}} 于北互金平台发起</span>
                                </div>
                                <h1 class="">{{item.title}}</h1>
                                <p>{{item.desc}}</p>
                            </a>
                            <ul class="list">
                                <li>
                                    <span>[投诉对象]</span>{{item.tousu_target}}
                                </li>
                                <li>
                                    <span>[投诉要求]</span>{{item.tousu_appeal}}
                                </li>
                            </ul>
                            <template v-if="item.is_help === 1">
                                <div class="law-help" >
                                    <span class="font-circle">帮</span>
                                    <span >律师已参与</span>
                                </div>
                            </template>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </van-tab>
        </van-tabs>
<!--    </section>-->
</template>

<script>
    import {ref} from 'vue';
    import webStorage from "@/utils/webStorage.js";
    import {myts,userComplaintsStatusCount} from '@/api/user';
    import {useRouter} from 'vue-router';
    export default {
        name: "m_user",
        setup(){
            const active = ref(0);
            const router = useRouter();
            const curUser = ref({
                nikeName: '',
                phoneNo: '',
                avatar: '',
                category:''
            });
            const allNum = ref(0);
            const titClass = ref('tab-tit');
            //全部
            const allTsList = ref([])
            const allTsRefreshing = ref(false);
            const allTsFinished = ref(false);
            const allTsLoading = ref(false);
            const allTagQueryParams = ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                queryDataStatus:'0,1,3,4,5,6'
            });
            const onLoadAll =()=>{
                setTimeout(()=>{
                    if(allTsRefreshing.value){
                        allTsList.value = [];
                        allTsRefreshing.value = false;
                    }
                    allTagQueryParams.value.page += 1;
                    myts(allTagQueryParams.value).then(res => {
                        if (res.status === 200 && res.data.code === 200001) {
                            let listData = res.data.data.complaintData;

                            let curPageInfo = res.data.data.pageInfo;
                            allNum.value = curPageInfo.totalNum;
                            // let len = this.tousu_new.length;
                            listData.forEach((item) => {
                                allTsList.value.push(item);
                            });
                            allTagQueryParams.value.page = curPageInfo.page;
                            allTagQueryParams.value.totalPage = curPageInfo.totalPage;
                            allTsLoading.value = false;
                            if(listData.length < 10 || allTagQueryParams.value.page === allTagQueryParams.value.totalPage){
                                allTsFinished.value = true;
                            }
                        }
                    });

                },1000);
            };
            const onRefreshAll = () =>{
                allTsFinished.value = false;
                allTsLoading.value = true;
                onLoadAll();
            };
            //处理中
            const operatingNum = ref(0);
            const operatingTsList = ref([])
            const operatingTsRefreshing = ref(false);
            const operatingTsFinished = ref(false);
            const operatingTsLoading = ref(false);
            const operatingTagQueryParams = ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                queryDataStatus:'0,1,3,4,5'
            });
            const onLoadOperating =()=>{
                setTimeout(()=>{
                    if(operatingTsRefreshing.value){
                        operatingTsList.value = [];
                        operatingTsRefreshing.value = false;
                    }
                    operatingTagQueryParams.value.page += 1;
                    myts(operatingTagQueryParams.value).then(res => {
                        if (res.status === 200 && res.data.code === 200001) {
                            let listData = res.data.data.complaintData;

                            let curPageInfo = res.data.data.pageInfo;
                            operatingNum.value = curPageInfo.totalNum;
                            // let len = this.tousu_new.length;
                            listData.forEach((item) => {
                                operatingTsList.value.push(item);
                            });
                            operatingTagQueryParams.value.page = curPageInfo.page;
                            operatingTagQueryParams.value.totalPage = curPageInfo.totalPage;
                            operatingTsLoading.value = false;
                            if(listData.length < 10 || operatingTagQueryParams.value.page === operatingTagQueryParams.value.totalPage){
                                operatingTsFinished.value = true;
                            }
                        }
                    });
                },1000);
            };
            const onRefreshOperating = () =>{
                operatingTsFinished.value = false;
                operatingTsLoading.value = true;
                onLoadOperating();
            };
            //已补充
            const operatedNum = ref(0);
            const operatedTsList = ref([])
            const operatedTsRefreshing = ref(false);
            const operatedTsFinished = ref(false);
            const operatedTsLoading = ref(false);
            const operatedTagQueryParams = ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                queryDataStatus:'4'
            });
            const onLoadOperated =()=>{
                setTimeout(()=>{
                    if(operatedTsRefreshing.value){
                        operatedTsList.value = [];
                        operatedTsRefreshing.value = false;
                    }
                    operatedTagQueryParams.value.page += 1;
                    myts(operatedTagQueryParams.value).then(res => {
                        if (res.status === 200 && res.data.code === 200001) {
                            let listData = res.data.data.complaintData;

                            let curPageInfo = res.data.data.pageInfo;
                            operatedNum.value = curPageInfo.totalNum;
                            // let len = this.tousu_new.length;
                            listData.forEach((item) => {
                                operatedTsList.value.push(item);
                            });
                            operatedTagQueryParams.value.page = curPageInfo.page;
                            operatedTagQueryParams.value.totalPage = curPageInfo.totalPage;
                            operatedTsLoading.value = false;
                            if(listData.length < 10 || operatedTagQueryParams.value.page === operatedTagQueryParams.value.totalPage){
                                operatedTsFinished.value = true;
                            }
                        }
                    });

                },1000);
            };
            const onRefreshOperated = () =>{
                operatedTsFinished.value = false;
                operatedTsLoading.value = true;
                onLoadOperated();
            };
            //未通过
            const noPassNum = ref(0);
            const noPassTsList = ref([])
            const noPassTsRefreshing = ref(false);
            const noPassTsFinished = ref(false);
            const noPassTsLoading = ref(false);
            const noPassTagQueryParams = ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                queryDataStatus:'2'
            });
            const onLoadNoPass =()=>{
                setTimeout(()=>{
                    if(noPassTsRefreshing.value){
                        noPassTsList.value = [];
                        noPassTsRefreshing.value = false;
                    }
                    noPassTagQueryParams.value.page += 1;
                    myts(noPassTagQueryParams.value).then(res => {
                        if (res.status === 200 && res.data.code === 200001) {
                            let listData = res.data.data.complaintData;

                            let curPageInfo = res.data.data.pageInfo;
                            noPassNum.value = curPageInfo.totalNum;
                            // let len = this.tousu_new.length;
                            listData.forEach((item) => {
                                noPassTsList.value.push(item);
                            });
                            noPassTagQueryParams.value.page = curPageInfo.page;
                            noPassTagQueryParams.value.totalPage = curPageInfo.totalPage;
                            noPassTsLoading.value = false;
                            if(listData.length < 10 || noPassTagQueryParams.value.page === noPassTagQueryParams.value.totalPage){
                                noPassTsFinished.value = true;
                            }
                        }
                    });

                },1000);
            };
            const onRefreshNoPass = () =>{
                noPassTsFinished.value = false;
                noPassTsLoading.value = true;
                onLoadNoPass();
            };
            //已完成
            const successNum = ref(0);
            const successTsList = ref([])
            const successTsRefreshing = ref(false);
            const successTsFinished = ref(false);
            const successTsLoading = ref(false);
            const successTagQueryParams = ref({
                page: -1,
                pageSize: 10,
                totalPage: 0,
                queryDataStatus:'6'
            });
            const onLoadSuccess =()=>{
                setTimeout(()=>{
                    if(successTsRefreshing.value){
                        successTsList.value = [];
                        successTsRefreshing.value = false;
                    }
                    successTagQueryParams.value.page += 1;
                    myts(successTagQueryParams.value).then(res => {
                        if (res.status === 200 && res.data.code === 200001) {
                            let listData = res.data.data.complaintData;

                            let curPageInfo = res.data.data.pageInfo;
                            successNum.value = curPageInfo.totalNum;
                            // let len = this.tousu_new.length;
                            listData.forEach((item) => {
                                successTsList.value.push(item);
                            });
                            successTagQueryParams.value.page = curPageInfo.page;
                            successTagQueryParams.value.totalPage = curPageInfo.totalPage;
                            successTsLoading.value = false;
                            if(listData.length < 10 || successTagQueryParams.value.page === successTagQueryParams.value.totalPage){
                                successTsFinished.value = true;
                            }
                        }
                    });

                },1000);
            };
            const onRefreshSuccess = () =>{
                successTsFinished.value = false;
                successTsLoading.value = true;
                onLoadSuccess();
            };
            const showComplaintInfo = (val) =>{
                router.push({
                    path:'/m_complaintInfo',
                    query:{
                        complaintId : val
                    }
                });
            };
            const userComplaintsCountByStatus = () =>{
                userComplaintsStatusCount().then(res =>{
                    if (res.status === 200 && res.data.code === 200001) {
                        allNum.value = res.data.data.allCount;
                        operatingNum.value = res.data.data.operatingCount;
                        noPassNum.value = res.data.data.noPassCount;
                        successNum.value = res.data.data.finishedCount;
                    }
                });
            };
            return {userComplaintsCountByStatus,showComplaintInfo,active,curUser,titClass,allNum,allTagQueryParams,allTsList,allTsRefreshing,allTsFinished,allTsLoading,onLoadAll,onRefreshAll,operatingTsList,operatingTsRefreshing,operatingTsFinished,operatingTsLoading,operatingTagQueryParams,onLoadOperating,onRefreshOperating,operatedTsList,operatedTsRefreshing,operatedTsFinished,operatedTsLoading,operatedTagQueryParams,onLoadOperated,onRefreshOperated,noPassTsList,noPassTsRefreshing,noPassTsFinished,noPassTsLoading,noPassTagQueryParams,onLoadNoPass,onRefreshNoPass,successTsList,successTsRefreshing,successTsFinished,successTsLoading,successTagQueryParams,onLoadSuccess,onRefreshSuccess,operatingNum,operatedNum,noPassNum,successNum};
        },
        created(){
            let userinfo = JSON.parse(webStorage.getItem('localStorage','user'));
            this.curUser.nikeName = userinfo.nike_name;
            this.curUser.phoneNo = userinfo.phone;
            this.curUser.avatar = userinfo.avatar;
            this.curUser.category = userinfo.category;
            if(this.curUser.category === 1){
                this.allTagQueryParams.queryDataStatus = '0,1,2,3,4,5,6';
                this.operatingTagQueryParams.queryDataStatus = '0,1,3,4,5';
                this.onLoadNoPass();
            }else if(this.curUser.category === 3){
                this.allTagQueryParams.queryDataStatus = '3,4,5,6';
                this.operatingTagQueryParams.queryDataStatus = '1,3,5';
                this.onLoadOperated();
            }else if(this.curUser.category === 5){
                this.allTagQueryParams.queryDataStatus = '4,5,6';
                this.operatingTagQueryParams.queryDataStatus = '4,5';
            }
            this.onLoadOperating();
            this.onLoadSuccess();
        },
        mounted(){
            // this.userComplaintsCountByStatus();
        }
    }
</script>

<style scoped>
    /*.tousu-userhead {*/
    /*    padding: 0.5rem 0.2rem;*/
    /*}*/
    .user-info{
        padding: 0.5rem 0.2rem;
        /*display: flex;*/
        /*flex-direction: row;*/
        /*align-items: center;*/
        /*justify-content: space-between;*/
    }
    .user-info .name-blk{
        float: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-left: 0.24rem;
        /*margin-block-start: 0;*/
        /*margin-block-end: 0;*/
    }
    .name-blk .tit {
        font-size: 0.4rem;
        line-height: 1rem;
        color: #212121;
        word-break: break-all;
    }
    .name-blk .link{
        line-height: 0.3rem;
        font-size: 0.26rem;
        color: #A3A3A3;
        margin-top: 0.05rem;
    }
    .user-info .usermsg{
        float: right;
        font-size: .26rem;
        color: #A3A3A3;
        line-height: 1.5rem;
    }
    .tab-tit{
        width: 100%;
        height: 100%;
    }
    .icon-status {
        position: absolute;
        right: 0.2rem;
        top: 0.6rem;
        background-size: 100%;
        /*font-size: 14px;*/
        /*line-height: 30px;*/
        color: #fff;
        text-align: center;
        width: 40px;
        height: 40px;
    }
    .icontstj{
        /*width: 30px;*/
        /*height: 30px;*/
        background: url("@/assets/icons/daishenhe.png") no-repeat;
        background-size: cover;
    }
    .iconyishenhe{
        /*width: 30px;*/
        /*height: 30px;*/
        background: url("@/assets/icons/yishenhe.png") no-repeat;
        background-size: cover;
    }
    .iconshenheshibai{
        /*width: 20px;*/
        /*height: 20px;*/
        background: url("@/assets/icons/shenheshibai.png") no-repeat;
        background-size: cover;
    }
    .iconyifenpei{
        /*width: 20px;*/
        /*height: 20px;*/
        background: url("@/assets/icons/yifenpei.png") no-repeat;
        background-size: cover;
    }
    .iconDone{
        /*width: 20px;*/
        /*height: 20px;*/
        background: url("@/assets/icons/finished.png") no-repeat;
        background-size: cover;
    }
    .iconAnswer{
        /*width: 20px;*/
        /*height: 20px;*/
        background: url("@/assets/icons/replied.png") no-repeat;
        background-size: cover;
    }

    .iconBuchong{
        /*width: 20px;*/
        /*height: 20px;*/
        background: url("@/assets/icons/yibuchong.png") no-repeat;
        background-size: cover;
    }
    .tousu_con {
        background: #fff;
        position: relative;
        /*width: 100%;*/
        padding: 0 0.3rem 0.3rem 0.3rem;
        border-bottom: 2px dotted #979797;
        cursor: pointer;
        /*box-sizing: border-box;*/
    }
    .tousu_con .box {
        display: block;
    }
    a {
        text-decoration: none;
        outline: none;
        color: #333;
    }
    .tousu_con .van-image {
        float: left;
        margin: 20px 0;
        border-radius: 50%;
        background-size: 100%;
    }
    .tousu_con .img img {
        border-radius: 100%;
        width: 60px;
        height: 60px;
    }
    .tousu_con .tit {
        overflow: hidden;
        padding: 0.45rem 0.375rem 0.375rem;
        line-height: 22px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    .tousu_con .name {
        font-size: 0.4rem;
        color: #212121;
    }
    .tousu_con .time {
        display: block;
        font-size: 0.375rem;
        color: #84848A;
        margin-top: .04rem;
        line-height: 20px;
    }
    .tousu_con h1 {
        font-size: 20px;
        color: #000;
        line-height: 34px;
        text-align: justify;
        clear: both;
    }
    .tousu_con p {

        margin: 10px 0;
        font-size: 0.375rem;
        color: #5D5D5D;
        /*line-height: 26px;*/
        text-align: justify;
        overflow: hidden;
        text-overflow: ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp: 4;
    }
    .tousu_con .list {
        font-size: 0.375rem;
        color: #5D5D5D;
        line-height: 0.75rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .tousu_con ul{
        list-style: none;
        padding-inline-start: 0;
    }
    .tousu_con .list span:first-child {
        color: #212121;
        padding-right: 5px;
    }
    .tousu_con .law-help{
        font-size: 0.375rem;
        color: var(--el-color-primary-dark-2);
        display: flex;
        align-items: center;
        margin-bottom: 0.1rem;
    }
    .tousu_con .law-help .font-circle{
        width: 0.7rem;
        height: 0.7rem;
        border: 2px solid var(--el-color-primary-dark-2);
        border-radius: 50%;
        margin-right: 0.2rem;
        font-size: 0.4rem;
    }
</style>
