<template>
    <el-container class="body-main">
        <el-col :span="16" >
            <router-view></router-view>
        </el-col>
        <div style="width: 30px;"/>
        <el-col :span="7">
            <RightAside/>
        </el-col>
    </el-container>
</template>

<script>
    import RightAside from "./RightAside";
    export default {
        name: "MainBody",
        components:{RightAside}
    }
</script>

<style scoped>
.body-main{
    position: relative;
    width: 1100px;
    /*height: 100%;*/
    min-height: 700px;
    margin: 20px auto;
}
</style>
