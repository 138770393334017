<template>
    <van-nav-bar title="修改手机" left-arrow @click-left="onClickLeft"/>
    <van-form ref="changeMobileRef" @submit="onSubmit">
        <van-cell-group inset>
    <!--        <van-field v-model="mobileForm.mobile" name="mobile" placeholder="请输入新手机号" :rules="[{ required: true, message:'请输入新手机号'}]"/>-->
            <van-field
                    v-model="mobileForm.captcha"
                    center
                    clearable
                    name="captcha"
                    placeholder="请输入图片验证码"
                    :rules="[{ required: true, message: '请填写验证码'}]"
            >
                <template #right-icon>
                    <van-image :src="captchaUrl" @click="refresh"/>
                </template>
            </van-field>
            <van-field
                    v-model="mobileForm.shortMsg"
                    center
                    clearable
                    label="短信验证码"
                    placeholder="请输入短信验证码"
            >
                <template #button>
                    <van-button size="small" type="primary" :disabled="smsBtnDis" @click="sendMsg" >{{smsBtnContent}}</van-button>
                </template>
            </van-field>
        </van-cell-group>
        <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit" :disabled="nextBtnDis">
                下一步，绑定新手机
            </van-button>
        </div>
    </van-form>
</template>

<script>
    import {ref,computed} from 'vue';
    import { getCaptcha,sendmsg,validShortMsg } from '@/api/user';
    import {showNotify} from 'vant';
    import webStorage from "@/utils/webStorage.js";
    import {useRouter} from 'vue-router';
    export default {
        name: "m_stepOne",
        setup(){
            const router = useRouter();
            const mobileForm = ref({
                mobile:'',
                captcha:'',
                hashkey:'',
                shortMsg:'',
                requestType:'validMobile'
            });
            const curUser = ref({
                nikeName: '',
                phoneNo: '',
                avatar: '',
                category:'',
                hidPhone: ''
            });
            const captchaUrl = ref('');
            const smsBtnDis = ref(false);
            const nextBtnDis = computed(()=> mobileForm.value.captcha.length<=0 || mobileForm.value.shortMsg.length<=0);
            const smsBtnContent = ref('获取验证码');
            const refresh = () =>{
                getCaptcha().then(res => {
                    mobileForm.value.hashkey=res.data.data.hashkey;
                    captchaUrl.value = res.data.data.image_url;
                }).catch(() => {
                    // 登录失败
                    // ElMessage({message:'获取验证码失败', type:'error'})
                    // 关闭 loading
                    showNotify({ type: 'danger', message: '登录失败' });
                    // loginLoading.value = false
                })
            };
            const count = ref(0);
            const timer = ref(0);
            const sendMsg = () =>{
                sendmsg(mobileForm.value).then(res =>{
                    console.log(res)
                    if(res.status===200 && res.data.code===200001){
                        smsBtnDis.value = true;
                        const TIME_COUNT = ref(60);
                        count.value = TIME_COUNT.value;
                        timer.value = window.setInterval(() => {
                            if (count.value > 0 && count.value <= TIME_COUNT.value){
                                // 倒计时时不可点击
                                smsBtnDis.value = true;
                                // 计时秒数
                                count.value--;
                                // 更新按钮的文字内容
                                smsBtnContent.value = count.value + 's后重新获取';
                            } else {
                                // 倒计时完，可点击
                                smsBtnDis.value = false;
                                // 更新按钮文字内容
                                smsBtnContent.value = '获取短信';
                                // 清空定时器!!!
                                clearInterval(timer);
                                timer.value = null;
                            }
                        }, 1000)
                    }else{
                        showNotify({type:'error', message:res.data.message});
                    }
                });
            };
            const onSubmit = () =>{
                validShortMsg(mobileForm.value).then(res =>{
                    if(res.status===200 && res.data.code===200001){
                        router.push('/m_stepTwo');
                    }else{
                        showNotify({type:'warning', message:'短信验证失败，请稍后再试！'});
                    }
                })
            };
            const onClickLeft = () => history.back();
            return {onClickLeft,curUser,mobileForm,captchaUrl,smsBtnDis,smsBtnContent,sendMsg,refresh,onSubmit,nextBtnDis};
        },
        mounted(){
            let userinfo = JSON.parse(webStorage.getItem('localStorage', 'user'));
            this.curUser.nikeName = userinfo.nike_name;
            this.curUser.phoneNo = userinfo.phone;
            this.curUser.avatar = userinfo.avatar;
            this.curUser.category = userinfo.category;
            this.curUser.hidPhone = userinfo.hidPhone;
            this.mobileForm.mobile = userinfo.phone;
            this.refresh();
        }
    }
</script>

<style scoped>

</style>
