<template>
    <router-view/>
    <m_footer/>
</template>

<script>
    import m_footer from "./common/m_footer";
    export default {
        name: "m_home",
        components: { m_footer},
    }
</script>

<style scoped>
    body{
        /*background-color: #f0f0f0;*/
        /*background-color: #dcdfe6;*/
    }
    :root{
        --van-nav-bar-background: #212121;
    }
</style>
