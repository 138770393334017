<template>
    <van-nav-bar title="找回密码" left-text="返回" left-arrow @click-left="onClickLeft"
                 right-text="返回首页" @click-right="onClickRight" />
    <div v-show="showStep1">
        <van-form ref="step1Ref" @submit="sendMsg">
            <van-cell-group inset>
                <van-field
                        v-model="fgpwd.captcha"
                        center
                        clearable
                        name="captcha"
                        placeholder="请输入图片验证码"
                        :rules="[{ required: true, message: '请填写验证码'}]"
                >
                    <template #right-icon>
                        <van-image :src="captchaUrl" @click="refresh"/>
                    </template>
                </van-field>
                <van-field v-model="fgpwd.mobile" name="mobile" type="tel" placeholder="输入注册时使用的手机号" :rules="[{pattern,message:'请输入正确的手机号'}]"/>
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                    发送短信
                </van-button>
            </div>
        </van-form>
    </div>
    <div v-show="showStep2">
        <van-form ref="step2Ref" @submit="validateMsg">
            <van-cell-group inset>
                <van-field
                        v-model="fgpwd.shortMsg"
                        center
                        clearable
                        label="短信验证码"
                        placeholder="请输入短信验证码"
                />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                    验证短信
                </van-button>
            </div>
        </van-form>
    </div>
    <div v-show="showStep3">
        <van-form ref="step3Ref" @submit="resetPasswd">
            <van-cell-group inset>
                <van-field
                        v-model="reset.passwd"
                        type="password"
                        name="passwd"
                        placeholder="8~30位大小字母、数字、特称字符"
                        :rules="[{ required: true, message: '请填写密码' }]"
                />
                <van-field
                        v-model="reset.checkPasswd"
                        type="password"
                        name="checkPasswd"
                        placeholder="再次输入密码"
                        :rules="[{ required: true, message: '请填写密码' }]"
                />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                    完成
                </van-button>
            </div>
        </van-form>
    </div>
    <div v-show="showStep4">

    </div>
</template>

<script>
    import {useRouter} from 'vue-router';
    import {showNotify} from 'vant';
    import { getCaptcha ,sendmsg,validShortMsg,resetPwd} from '@/api/user';
    import {ref} from 'vue';
    export default {
        name: "forgetPwd",
        setup(){
            const router = useRouter();
            const onClickLeft = () =>history.back();
            const pattern = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            const onClickRight = ()=>{
                router.push('/m_index');
            };
            const captchaUrl = ref('');
            const fgpwd = ref({
                mobile: '',
                captcha:'',
                shortMsg:'',
                hashkey:'',
                actionType:'resetPwd'
            });
            const reset = ref({
                passwd :'',
                checkPasswd :''
            });
            const refresh = () =>{
                getCaptcha().then(res => {
                    fgpwd.value.hashkey=res.data.data.hashkey;
                    captchaUrl.value = res.data.data.image_url;
                }).catch(() => {
                    // 登录失败
                    showNotify({message:'获取验证码失败', type:'error'})
                    // 关闭 loading
                    // loginLoading.value = false
                })
            };
            const showStep1 = ref(true);
            const showStep2 = ref(false);
            const showStep3 = ref(false);
            const showStep4 = ref(false);
            const sendMsg = () =>{
                sendmsg(fgpwd.value).then(res => {
                    if (res.status === 200 && res.data.code === 200001) {
                        showStep1.value = false;
                        showStep2.value = true;
                    }
                })
            };
            const validateMsg = () =>{
                validShortMsg(fgpwd.value).then(res => {
                    if (res.status === 200 && res.data.code === 200001) {
                        showStep2.value = false;
                        showStep3.value = true;
                    }
                })
            };
            const resetPasswd = () =>{
                resetPwd({mobile:fgpwd.value.mobile,newPasswd:reset.value.passwd,confirePwd:reset.value.checkPasswd}).then(res => {
                    if (res.status === 200 && res.data.code === 200001) {
                        showStep3.value = false;
                        showStep4.value = true;
                    }
                }).catch(error =>{
                    if(error.response.status === 401){
                        showNotify({message:'登录超时，请重新登录！',type:"warning"});
                        router.push('/m_login');
                    }
                })
            };
            return {pattern,resetPasswd,validateMsg,sendMsg,refresh,captchaUrl,fgpwd,reset,router,onClickLeft,onClickRight,showStep1,showStep2,showStep3,showStep4}
        },
        mounted(){
            this.refresh();
        }
    }
</script>

<style scoped>

</style>
