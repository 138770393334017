<template>
    <Article art_type="1"></Article>
</template>

<script>
    import Article from "./components/article"
    export default {
        name: "industryResearch",
        components:{Article},
        created(){
            let activeMenuEvent = document.createEvent('CustomEvent')
            activeMenuEvent.initCustomEvent('changeActiveMenu',false,false,'industry')
            window.dispatchEvent(activeMenuEvent)
        }
    }
</script>

<style scoped>

</style>
