<template>
    <van-nav-bar title="注册" left-text="返回" left-arrow @click-left="onClickLeft"/>
    <van-form ref="registerRef" @submit="onSubmit">
        <van-cell-group inset>
            <van-field v-model="userInfo.username"
                       placeholder="请输入用户名" type="text"
                       :rules="[{required: true, message:'用户名不能为空'},
                       {validator:checkUNmmeRepied,message:'用户名已占用'},
                       {validator:nameInRules,message: '该用户名已被占用'}]" />
            <van-field
                    v-model="userInfo.passwd"
                    type="password"
                    placeholder="8~30位大小字母、数字、特称字符"
                    :rules="[{ required: true, message: '请填写密码' },
                    {pattern:pwdPattern,message: '密码必须为8~30位大小字母、数字、特称字符!'}]"
            />
            <van-field
                    v-model="userInfo.checkPasswd"
                    type="password"
                    name="checkPasswd"
                    placeholder="再次输入密码"
                    :rules="[{ required: true, message: '请填写确认密码' },
                    {validator:validatePass2,message:'两次输入密码不一致'}]"
            />
            <van-field v-model="userInfo.mobile" name="mobile" type="tel" placeholder="建议使用常用手机"
                       :rules="[{ required: true, message:'请输入新手机号'},
                       {pattern,message:'请输入正确的手机号'},
                       {validator:checkMobileRepied,message:validatePhoneMsg }]" @update:model-value="checkPhone"/>
            <van-field
                    v-model="userInfo.captcha"
                    center
                    clearable
                    name="captcha"
                    placeholder="请输入图片验证码"
                    :rules="[{ required: true, message: '请填写验证码'}]"
            >
                <template #right-icon>
                    <van-image :src="captchaUrl" @click="refresh"/>
                </template>
            </van-field>
            <van-field
                    v-model="userInfo.shortMsg"
                    center
                    clearable
                    placeholder="请输入短信验证码"
                    :rules="[{required:true,message:'短信验证码不能为空'}]"
            >
                <template #button>
                    <van-button size="small" type="primary" :disabled="smsBtnDis" @click="sendMsg" >{{smsBtnContent}}</van-button>
                </template>
            </van-field>
            <van-field name="agree" :rules="[{required:true,message:'请阅读并同意用户协议和隐私政策'}]">
                <template #input>
                    <van-checkbox icon-size="16px" v-model="agree" shape="square" style="margin-right: 2px;"/> 已阅读并同意<a href="javascript:;" @click="openfile">《用户协议与隐私政策》</a>
                </template>
            </van-field>
        </van-cell-group>
        <span style="font-size: 0.3rem;float:left;padding:0.3rem;color:red">注：请勾选用户协议和隐私政策。</span>
        <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit" >
                注册
            </van-button>
        </div>
    </van-form>
</template>

<script>
    import {ref,computed} from 'vue';
    import {showNotify,closeToast, showLoadingToast} from 'vant';
    import { getCaptcha,sendmsg,register,checkUNameRepied,validateNameInRules,checkPhoneRepied } from '@/api/user';
    import {useRouter} from 'vue-router';
    export default {
        name: "m_register",
        setup(){
            const router = useRouter();
            const pattern = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            const pwdPattern = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,30}$/;
            const checkUNmmeRepied = (val)=>
                new Promise((resolve) => {
                    setTimeout(() => {
                        checkUNameRepied({userName:val}).then(res =>{
                            if(res.status===200 ) {
                                resolve(res.data.code === 200001)
                            }
                        });
                    }, 1000);
                });
            const nameInRules = (val) =>
                new Promise((resolve) => {
                    setTimeout(() => {
                        validateNameInRules({'content':val}).then(res =>{
                            let ret = JSON.parse(res.data);
                            if(ret.code === "200" ) {
                                resolve(ret.flag !== "1")
                            }
                        });
                    }, 1000);
                });
            const validatePass2 = (val) => val === userInfo.value.passwd;
            const userInfo = ref({
                username: '',
                passwd: '',
                checkPasswd: '',
                mobile: '',
                captcha: '',
                hashkey: '',
                shortMsg: '',
                agree: false,
                requestType:'register'
            });
            const captchaUrl = ref('');
            const refresh = () =>{
                getCaptcha().then(res => {
                    userInfo.value.hashkey=res.data.data.hashkey;
                    captchaUrl.value = res.data.data.image_url;
                }).catch(() => {
                    // 登录失败
                    showNotify({message:'获取验证码失败', type:'warning'})
                    // 关闭 loading
                    // loginLoading.value = false
                })
            };
            const phoneRepied = ref(true);
            const checkPhone = async () =>{
                if(!userInfo.value.mobile){
                    phoneRepied.value = true;
                    return;
                }
                let val = userInfo.value.mobile;
                var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if (!myreg.test(val)) {
                    phoneRepied.value = true;
                }else{
                    await checkPhoneRepied({mobile:val}).then(res =>{
                        if(res.status===200 && res.data.code === 400001){
                            phoneRepied.value = true;
                        }else{
                            phoneRepied.value = false;
                        }
                    });
                }
            };
            const checkMobileRepied = (val)=>
                new Promise((resolve) => {
                    showLoadingToast('手机号验证...');
                    setTimeout(() => {
                        checkPhoneRepied({mobile:val}).then(res => {
                            closeToast();
                            if (res.status === 200 && res.data.code === 200001) {
                                resolve(true);
                            }else{
                                checkPhoneRetMsg.value = res.data.message;
                                resolve(false);
                            }
                        });
                    }, 1000);
                });
            const checkPhoneRetMsg = ref('');
            const validatePhoneMsg = () =>{
                return checkPhoneRetMsg;
            };
            const smsBtnDis = computed(() => userInfo.value.captcha.length<=0 || phoneRepied.value);
            const smsBtnContent = ref("获取验证码");
            const count = ref(0);
            const timer = ref(0);
            const sendMsg = () =>{
                sendmsg(userInfo.value).then(res =>{
                    console.log(res)
                    if(res.status===200 && res.data.code===200001){
                        smsBtnDis.value = true;
                        const TIME_COUNT = ref(60);
                        count.value = TIME_COUNT.value;
                        timer.value = window.setInterval(() => {
                            if (count.value > 0 && count.value <= TIME_COUNT.value){
                                // 倒计时时不可点击
                                smsBtnDis.value = true;
                                // 计时秒数
                                count.value--;
                                // 更新按钮的文字内容
                                smsBtnContent.value = count.value + 's后重新获取';
                            } else {
                                // 倒计时完，可点击
                                smsBtnDis.value = false;
                                // 更新按钮文字内容
                                smsBtnContent.value = '获取短信';
                                // 清空定时器!!!
                                clearInterval(timer);
                                timer.value = null;
                            }
                        }, 1000)
                    }else{
                        showNotify({type:'warning', message:res.data.message});
                    }
                });
            };
            const onSubmit = () =>{
                register(userInfo.value).then(res =>{
                    if(res.status == 200){
                        if (res.data.code == 200001){
                            showNotify({message:"注册成功", type:'success'});
                            router.push('/m_login');
                        }else{
                            showNotify({message:res.data.message, type:'warning'});
                        }
                    }
                }).catch(err =>{
                    showNotify({message:err, type:'error'});
                })
            };
            const onClickLeft = () => history.back();
            const agree = ref(false);
            const openfile = () =>{
                window.open('/北京市互联网金融消费者投诉平台用户协议与隐私政策.pdf','用户协议与隐私政策');
            };
            return {openfile,agree,validatePass2,pwdPattern,phoneRepied,checkPhone,validatePhoneMsg,checkPhoneRetMsg,checkMobileRepied,nameInRules,checkUNmmeRepied,pattern,onClickLeft,onSubmit,userInfo,refresh,captchaUrl,smsBtnDis,smsBtnContent,count,timer,sendMsg};
        },
        mounted(){
            this.refresh();
        }
    }
</script>

<style scoped>
    .van-form{
        height: 100vh;
    }
    .van-cell-group--inset{
        margin: 0 14px;
    }
</style>
