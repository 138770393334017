<template>
    <div class="complaint-container">
        <div class="subnav">
            <a href="/" title="首页">首页</a>
            <span >> 我要投诉</span>
        </div>

        <div class="complaint-body">
            <div class="complaint-main" v-if="complaint_panel_show">
                <el-steps align-center :active="active" space="200px">
                    <el-step title="投诉企业"/>
                    <el-step title="投诉详情"></el-step>
                    <el-step title="完成"></el-step>
                </el-steps>
                    <div class="mystep1" v-show="step1show">
                    <el-form label-position="right" label-width="110px"
                             :model="stepOneData"
                             ref="stepOneDataRef"
                             :rules="stepOneRules">
                        <el-form-item label="投诉对象" prop="ts_companyTitle">
                            <el-input v-model="stepOneData.ts_companyTitle" size="large" placeholder="请选择投诉对象" @click="toSearchCompany">
                                <template #suffix>
                                    <el-icon class="el-input__icon"><Search/></el-icon>
                                </template>
                            </el-input>
                            <div class="ts-search" v-show="searchBoxShow">
                                <el-input size="large" ref="searchBoxRef" v-model.trim="tmpCompanyName" placeholder="输入关键词搜索" @input="handleSearch">
                                    <template #append>
                                        <span @click="searchBoxShow = false">取消</span>
                                    </template>
                                </el-input>
                                <div class="search-result" v-show="resultShow">
                                    <li v-for="item in searchResult" :key="item" @click="selectItem(item)">
                                        {{item.title}}
                                    </li>
                                    <div class="nodata" v-show="tmpCompanyName">
                                        <div class="nodata-desc">
                                            <a>可直接添加“{{inputCompanyName}}”</a>
                                        </div>
                                        <div class="nodataBtn">
                                            <a style="cursor: pointer" @click="addCompanyName"><el-icon color="#409EFC"><Plus/></el-icon>添加</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-form-item>

                        <el-form-item label="投诉问题" prop="ts_issue">
                            <el-input v-model="stepOneData.ts_issue" size="large" placeholder="请选择问题类型" @click="showIssues">
                                <template #suffix>
                                    <el-icon class="el-input__icon"><Plus/></el-icon>
                                </template>
                            </el-input>
                            <el-dialog custom-class="issue-dialog" v-model="issueDialogVisible" title="选择投诉问题类型">
                                <div class="issue-item">
                                    <a href="javascript:;" class="item" v-for="issue in issueResult" :key="issue" @click="addSelected($event,issue,'issue')">{{issue.issue}}</a>
                                    <a href="javascript:;" v-for="issue in defineIssues" :key="issue" :class="{item:true,'item-selected': stepOneData.selectedDefineIssues.findIndex(item => item === issue) > -1  }" @click="handleDefineSelected($event,issue,'issue')">{{issue}}</a>
                                    <a class="item" @click="defineIssueVisible = true">自定义＋</a>
                                </div>
                                <template #footer>
                                  <span class="dialog-footer">
                                    <el-button @click="issueDialogVisible = false">取消</el-button>
                                    <el-button type="primary" @click="chooseConfirm('issue')">确认</el-button>
                                  </span>
                                </template>
                            </el-dialog>
                            <el-dialog v-model="defineIssueVisible" title="自定义投诉问题">
                                <el-input label="投诉问题：" v-model="newIssue" placeholder="请输入不超过10个汉字的问题描述"></el-input>
                                <template #footer>
                                    <span class="dialog-footer">
                                        <el-button @click="defineIssueVisible = false">取消</el-button>
                                    <el-button type="primary" @click="handleAddIssue">确认</el-button>
                                    </span>
                                </template>
                            </el-dialog>
                        </el-form-item>
                        <el-form-item label="投诉要求" prop="ts_appeal">
                            <el-input v-model="stepOneData.ts_appeal"  size="large" placeholder="请选择诉求类型" @click="showAppeal">
                                <template #suffix>
                                    <el-icon class="el-input__icon"><Plus/></el-icon>
                                </template>
                            </el-input>
                            <el-dialog custom-class="appeal-dialog" v-model="appealDialogVisible" title="选择投诉诉求类型">
                                <div class="appeal-item">
                                    <a href="javascript:;" class="item" v-for="appeal in appealResult" :key="appeal" @click="addSelected($event,appeal,'appeal')">{{appeal.appeal}}</a>
                                    <a href="javascript:;" v-for="appeal in defineAppeals" :key="appeal" :class="{item:true,'item-selected': stepOneData.selectedDefineAppeal.findIndex(item => item === appeal) > -1  }" @click="handleDefineSelected($event,appeal,'appeal')">{{appeal}}</a>
                                    <a class="item" @click="defineAppealVisible = true">自定义＋</a>
                                </div>
                                <template #footer>
                                    <span class="dialog-footer">
                                    <el-button @click="appealDialogVisible = false">取消</el-button>
                                    <el-button type="primary" @click="chooseConfirm('appeal')">确认</el-button>
                                    </span>
                                </template>
                            </el-dialog>
                            <el-dialog v-model="defineAppealVisible" title="自定义投诉要求">
                                <el-input label="投诉问题：" v-model="newAppeal" placeholder="请输入不超过10个汉字的诉求描述"></el-input>
                                <template #footer>
                                    <span class="dialog-footer">
                                        <el-button @click="defineAppealVisible = false">取消</el-button>
                                    <el-button type="primary" @click="handleAddAppeal">确认</el-button>
                                    </span>
                                </template>
                            </el-dialog>
                        </el-form-item>
                        <el-form-item label="涉诉金额" prop="ts_amount">
                            <el-col :span="12">
                                <el-input v-model="stepOneData.ts_amount"  size="large">
                                    <template #append><span style="width: 20px">元</span></template>
                                </el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="涉诉单号(保密)">
                            <el-input v-model="stepOneData.ts_order_no" size="large" placeholder="订单号、账号、手机号、银行卡等">
                                <template #suffix>
                                    <el-icon class="el-input__icon"><CloseBold /></el-icon>
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="mystep2" v-show="step2show">
                    <el-form label-position="right" label-width="110px"
                    ref="stepTwoDataRef" :model="stepTwoData" :rules="stepTwoRules"
                    >
                        <el-form-item label="投诉标题" prop="tsTitle">
                            <el-input v-model="stepTwoData.tsTitle" size="large" maxlength="40" show-word-limit placeholder="概括核心问题，最好包含商家名以便为您解决（最多40个字）">
                                <template #suffix>
                                    <el-icon class="el-input__icon"><Edit/></el-icon>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="投诉详情" prop="tsContent">
                            <el-input v-model="stepTwoData.tsContent" type="textarea" rows="7" maxlength="1000" show-word-limit size="large" placeholder="请您依据实际情况详细描述投诉事由（不少于20字），切勿填写自己的隐私信息，禁止发布违反法律、行政法规及不文明内容。捏造事实、诬陷诽谤他人、泄露他人隐私的投诉单将被驳回。" >
                                <template #suffix>
                                    <el-icon class="el-input__icon"><Plus/></el-icon>
                                </template>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-space >
                                <el-alert type="info" style="text-align: start" show-icon :closable="false">
                                    <p style="line-height: normal">1、图片最多上传5张，单张图片大小不超过1M，支持jpg、png、jpeg格式。</p>
                                    <p style="line-height: normal">2、若上传图片中含有身份证号、姓名、手机号、银行卡号、家庭住址等个人隐私信息的，请设置为隐藏或用马赛克遮挡。</p>
                                    <p style="line-height: normal">3、如果图片文件大于5M，可在投诉完成后，通过邮件以附件形式发送到：tstjzx@bjifia.com.cn。请在邮件中标明投诉标号。</p>
                                </el-alert>
                            </el-space>
                        </el-form-item>

                        <el-form-item label="图片" prop="image">
                            <el-upload
                                    v-model:file-list="fileList"
                                    action="#"
                                    accept="image/jpeg,image/png"
                                    list-type="picture-card"
                                    :auto-upload="false"
                                    :limit="5"
                                    :on-change="handleChange"
                            >
                                <el-icon><Plus /></el-icon>
                            </el-upload>

                            <el-dialog v-model="dialogVisible">
                                <img w-full :src="dialogImageUrl" alt="Preview Image" />
                            </el-dialog>
                        </el-form-item>
                        <el-form-item label="隐藏图片">
                            <el-checkbox v-model="stepTwoData.hidePic"></el-checkbox>
                        </el-form-item>
                        <el-form-item label="匿名投诉" prop="anon">
                            <el-checkbox v-model="stepTwoData.anon"></el-checkbox>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="mystep2" v-show="step3show">
                    <p>您的投诉已提交成功，待审核成功后商家会与您联系协商。</p>
                </div>
                <el-button @click="pre" style="margin-top: 10px" v-if="active >0 && active<2">上一步</el-button>
                <el-button @click="submit" style="margin-top: 10px" v-if="active === 1">提交</el-button>
                <el-button @click="next" style="margin-top: 10px" v-if="active <1">下一步</el-button>
            </div>
        </div>
    </div>
    <el-dialog v-model="loginDialogVisible" title="登录" width="400" show-close="false" align-center>
        <pcLoginComponent @loginEnd="loginEnd" redirect="p_index"/>
    </el-dialog>
    <el-dialog v-model="importantTipsVisible" title="重要提示" width="400" show-close="false" align-center>
        登录失效，请点击"确定"按钮重新登录！
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="importantTipsVisible = false">取消</el-button>
            <el-button type="primary" @click="toNext">
              确定
            </el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script>
    import {reactive,ref} from 'vue';
    import {ElMessage} from 'element-plus';
    import { companyInfo,getIssueAppeal,complaint} from '@/api/complaint';
    // import uploadCop from './components/uploadCop.vue'
    import pcLoginComponent from "@/views/login/pcLoginComponent";
    export default {
        name: "testComplaint",
        components:{pcLoginComponent},
        data(){
            return{
                loginDialogVisible: false,
                importantTipsVisible: false,
                token : sessionStorage.token || localStorage.token,
                active : ref(0),
                stepOneDataRef: ref(null),
                stepTwoDataRef: ref(null),
                searchBoxRef: ref(null),
                complaint_panel_show: ref(true),
                // notice_show: ref(true),
                step1show: ref(true),
                step2show: ref(false),
                step3show: ref(false),
                ts_company : ref(),
                searchBoxShow:false,
                issueDialogVisible: false,
                appealDialogVisible: false,
                loading: false,
                tmpCompanyName: '',
                inputCompanyName:'',
                fileList: [],
                dialogVisible: false,
                dialogImageUrl: '',
                defineIssueVisible: false,
                defineIssues:[],
                fileType: ["jpg","jpeg","png","JPG","JPEG","PNG"],
                fileLimit: 5,
                fileSize:1,
                totleFilesSize:5,
                defineAppeals:[],
                selectedDefineAppeal:[],
                newIssue: '',
                newIssueId: 200000,
                defineAppealVisible: false,
                newAppeal: '',
                newAppealId: 200000,
                stepOneData:{
                    ts_companyId:'',
                    ts_companyTitle:'',
                    ts_issue:'',
                    selectedIssue:[],
                    issueIdex:[],
                    ts_appeal:'',
                    selectedAppeal: [],
                    appealIdex:[],
                    selectedDefineIssues:[],
                    selectedDefineAppeal:[],
                    ts_amount:0,
                    ts_order_no:''
                },
                stepOneRules:{
                    ts_companyTitle:[
                        {required: true, message: '请选择投诉对象'}
                    ],
                    ts_issue:[
                        {required: true, message: '请选择投诉问题'}
                    ],
                    ts_appeal : [
                        {required: true, message: '请选择投诉要求'}
                    ],
                    ts_amount : [
                        {
                            validator: (rule, value, callback) => {
                                if(value <= 0){
                                    callback(new Error('涉诉金额必须大于0'))
                                }else{
                                    callback()
                                }
                            },trigger: 'blur',required: true
                        }
                    ]
                },
                stepTwoData:{
                    tsTitle:'',
                    tsContent:'',
                    hidePic:0,
                    anon:0
                },
                stepTwoRules:{
                    tsTitle : [
                        {
                            validator:(rule,value,callback)=>{
                                if(!value){
                                    callback(new Error('请输入投诉标题'))
                                }else{
                                    callback()
                                }
                            },trigger: 'blur'
                        }
                    ],
                    tsContent:[
                        {
                            validator:(rule,value,callback)=>{
                                if(!value){
                                    callback(new Error('请输入投诉内容'))
                                }else{
                                    callback()
                                }
                            },trigger: 'blur'
                        }
                    ]
                },
                resultShow: true,
                searchResult:[
                ],
                issueResult:[
                ],
                appealResult:[
                ]
            }
        },
        setup(){
            let agreeBtn = reactive({
                text: "同意(1)",
                loading: false,
                disabled: true,
                duration: 1,
                timer: null
            })
            agreeBtn.timer && clearInterval(agreeBtn.timer)
            agreeBtn.timer = setInterval(()=>{
                const tmp = agreeBtn.duration --;
                agreeBtn.text = `同意(${tmp})`;
                if (tmp <= 0){
                    clearInterval(agreeBtn.timer)
                    agreeBtn.disabled = false
                }
            },1000)
            return{agreeBtn}
        },
        methods:{
            handleChange(file){
                if(file.status !== "ready") return;
                if(file.type != "" || file.type != null || file.type != undefined){
                    let fileExt = file.name.replace(/.+\./,"")
                    if(!this.fileType.includes(fileExt)){
                        ElMessage({message:"上传文件格式不正确!",type:'warning'})
                        // fileList = fileList.slice(-1)
                        this.fileList = this.fileList.slice(0,this.fileList.length-1)
                        return false;
                    }
                    let isLt1M = file.size/1024/1024<=1;
                    if(!isLt1M){
                        ElMessage({message:"上传文件大小不能超过1M!",type:'warning'})
                        // fileList = fileList.slice(-1)
                        this.fileList = this.fileList.slice(0,this.fileList.length-1)
                        return false;
                    }
                }
            },
            toNext(){
                this.importantTipsVisible = false;
                this.loginDialogVisible = true;
            },
            loginEnd(){
                this.loginDialogVisible = false;
            },
            toSearchCompany(){
                this.searchBoxShow = true
                this.$refs["searchBoxRef"].focus()
            },
            showIssues(){
                if(!this.stepOneData.ts_companyTitle){
                    ElMessage({message:"请选择投诉对象",type:'warning'})
                    return
                }
                this.issueDialogVisible = true
            },
            showAppeal(){
                if(!this.stepOneData.ts_companyTitle){
                    ElMessage({message:"请选择投诉对象",type:'warning'})
                    return
                }
                this.appealDialogVisible = true
            },
            handleAddIssue(){
                this.defineIssues.push(this.newIssue)
                this.stepOneData.selectedDefineIssues.push(this.newIssue)
                this.stepOneData.selectedIssue.push(this.newIssue)
                this.newIssue = ''
                this.defineIssueVisible = false
            },
            handleAddAppeal(){
                // this.newAppealId += 1
                // let newItem = {appealId:this.newAppealId,appeal:this.newAppeal}
                // this.appealResult.push(newItem)
                this.defineAppeals.push(this.newAppeal)
                this.stepOneData.selectedDefineAppeal.push(this.newAppeal)
                this.stepOneData.selectedAppeal.push(this.newAppeal)
                this.newAppeal = ''
                this.defineAppealVisible = false
            },
            addCompanyName(){
                this.stepOneData.ts_companyTitle = this.tmpCompanyName;
                this.searchBoxShow =false;
                this.getIssuesAndAppeal('')
            },
            show_complaint(){
                this.notice_show = false;
                this.complaint_panel_show = true;
                this.step1show = true;
            },
            next(){
                this.$refs["stepOneDataRef"].validate(valid =>{
                    if (valid) {
                        this.step1show = false;
                        this.step2show = true;
                        this.active++;
                    }
                })
            },
            pre(){
                this.step1show = true;
                this.step2show = false;
                this.active--
            },
            submit(){
                this.$refs["stepTwoDataRef"].validate(valid =>{
                    if(valid){
                        let complaintData = {}
                        complaintData['info']=this.stepOneData
                        complaintData['content']=this.stepTwoData
                        let formData = new FormData();
                        this.fileList.forEach(file =>{
                            //multipartFiles 后台接收名字
                            formData.append("multipartFiles",file.raw)
                        })
                        formData.append('info',JSON.stringify(this.stepOneData))
                        formData.append('content',JSON.stringify(this.stepTwoData))
                        console.log(formData.getAll('multipartFiles'))
                        complaint(formData).then(res =>{
                            console.log(res)
                            if(res.status === 200 && res.data.code === 200001){
                                this.step2show = false;
                                this.step3show = true;
                                this.active++
                            }
                        }).catch(error =>{
                            if(error.response.status === 403 || error.response.status === 401){
                                // ElMessage({message:"登录超时，请重新登录！",type:'warning'})
                                // this.$router.push('/p_login')
                                this.importantTipsVisible = true;
                            }else{
                                ElMessage({type:'error',message:error.message})
                            }

                        })
                    }

                })

            },
            selectItem(obj){
                this.stepOneData.ts_companyId = obj.companyId;
                this.stepOneData.ts_companyTitle = obj.title;
                this.searchBoxShow =false;
                this.getIssuesAndAppeal(obj.companyId);
            },
            getIssuesAndAppeal(dataId){
                getIssueAppeal({'companyId':dataId}).then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        this.issueResult = res.data.data.issues;
                        this.appealResult = res.data.data.appeals;
                        console.log(this.issueResult )
                    }
                }).catch(error =>{
                    ElMessage({message:error.message,type:'warning'})
                })
            },
            getCompanyInfo(value){
                if(value){
                    companyInfo({'companyName':value}).then(res => {
                        console.log(res)
                        if(res.status === 200 && res.data.code === 200001){
                            this.searchResult = res.data.data
                        }
                    }).catch(error =>{
                        if(error.response.status === 403 || error.response.status === 401){
                            // ElMessage({message:"登录超时，请重新登录！",type:'warning'})
                            // this.$router.push('/p_login')
                            this.importantTipsVisible = true;
                        }else{
                            ElMessage({message:error.message,type:'warning'})
                        }

                    })
                }

            },
            handleSearch(value) {
                let timeout;

                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                if(value.trim().length === 0 ) {
                    this.searchResult = []
                    return
                }
                timeout = setTimeout(this.getCompanyInfo(value), 300);
                this.inputCompanyName = value
            },
            handleDefineSelected($event,item,type){
                if(type === 'issue'){
                    let arrIndex = this.stepOneData.selectedDefineIssues.indexOf(item)
                    if(arrIndex > -1){
                        $event.currentTarget.className = 'item'
                        this.stepOneData.selectedDefineIssues.splice(arrIndex,1)
                        this.stepOneData.selectedIssue.splice(arrIndex,1)
                    }else{
                        $event.currentTarget.className = 'item item-selected'
                        this.stepOneData.selectedDefineIssues.push(item)
                        this.stepOneData.selectedIssue.push(item)
                    }
                }else{
                    let arrIndex = this.stepOneData.selectedDefineAppeal.indexOf(item)
                    if(arrIndex > -1){
                        $event.currentTarget.className = 'item'
                        this.stepOneData.selectedDefineAppeal.splice(arrIndex,1)
                        this.stepOneData.selectedAppeal.splice(arrIndex,1)
                    }else{
                        $event.currentTarget.className = 'item item-selected'
                        this.stepOneData.selectedDefineAppeal.push(item)
                        this.stepOneData.selectedAppeal.push(item)
                    }
                }
            },
            addSelected($event,item,type) {
                if(type === 'issue'){
                    let arrIndex = this.stepOneData.issueIdex.indexOf(item.issueId)
                    if(arrIndex > -1){
                        $event.currentTarget.className = 'item'
                        this.stepOneData.issueIdex.splice(arrIndex,1)
                        this.stepOneData.selectedIssue.splice(arrIndex,1)
                    }else{
                        $event.currentTarget.className = 'item item-selected'
                        this.stepOneData.selectedIssue.push(item.issue)
                        this.stepOneData.issueIdex.push(item.issueId)
                    }
                }else{
                    let arrIndex = this.stepOneData.appealIdex.indexOf(item.appealId)
                    if(arrIndex > -1){
                        $event.currentTarget.className = 'item'
                        this.stepOneData.appealIdex.splice(arrIndex,1)
                        this.stepOneData.selectedAppeal.splice(arrIndex,1)
                    }else{
                        $event.currentTarget.className = 'item item-selected'
                        this.stepOneData.appealIdex.push(item.appealId)
                        this.stepOneData.selectedAppeal.push(item.appeal)
                    }
                }
            },
            chooseConfirm(type){
                if(type === 'issue'){
                    this.issueDialogVisible = false;
                    this.stepOneData.ts_issue = this.stepOneData.selectedIssue.join(',')
                }else{
                    this.appealDialogVisible = false;
                    this.stepOneData.ts_appeal = this.stepOneData.selectedAppeal.join(',')
                }

            },

            getUploadFiles(){
                return this.$refs.uploadCop.getFileList()
            }
        }
    }
</script>

<style scoped>
    .complaint-container{
        width: 100%;
        margin: 80px auto;
        min-height: 700px;
    }
    .complaint-body{
        padding: 10px 10px;
        background: #ffffff;
        margin-top: 20px;
    }
    .complaint-container .subnav{
        line-height: 20px;
        font-weight: 500;
        border-bottom: 3px solid var(--el-color-primary-light-5);
        /*margin-bottom: 20px;*/
        font-size: 18px;
        display: flex;
        padding: 10px;
    }
    .complaint-container .subnav a{
        color: #2c3e50;
    }
    .complaint-main{
        /*width: 700px;*/
        /*margin: 80px auto;*/
        /*padding: 10px 10px;*/
        /*background: #ffffff;*/
        /*min-height: 700px;*/
    }
    .complaint-main :deep(.el-steps){
        /*margin-top: 40px;*/
        padding: 10px 10px 10px 10px;
        align-items: center;
        justify-content: center;
    }
    .complaint-main .el-form{
        margin-top: 40px;
        padding: 10px 40px;
        align-items: center;
        justify-content: center;
    }
    .complaint-main :deep(.el-step__head.is-process) {
        color: var(--el-color-primary);
        border-color: var(--el-color-primary);
    }
    .complaint-main :deep(.el-step__title.is-process) {
        font-weight: 700;
        color: var(--el-color-primary);
    }
    .complaint-main :deep(.el-step__head.is-finish) {
        color: var(--el-color-primary-light-5);
        border-color: var(--el-color-primary-light-5);
    }
    .complaint-main :deep(.el-step__title.is-finish) {
        color: var(--el-color-primary-light-5);
    }
    .ts-search{
        position: absolute;
        z-index: 10;
        left: -1px;
        top: -1px;
        /*display: none;*/
        width: 510px;
        border: solid 1px #d6d6d6;
        background-color: #fff;
        box-shadow: 0 3px 3px rgb(128 128 128 / 30%);
    }
    .ts-search .search-result .nodata{
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #409EFF;
    }
    .nodata-desc{
        padding-left: 10px;
        color: #5D5D5D;
    }
    .nodataBtn{
        padding-right: 10px;
    }
    :deep(.issue-dialog){
        border: solid 1px #d6d6d6;
        background-color: #f8f8f8;
        box-shadow: 0 3px 3px rgb(128 128 128 / 30%);
        width:600px;
    }
    :deep(.issue-dialog .issue-item){
        width: 500px;
        overflow-y: auto;
    }
    .issue-item .item{
        display: inline-block;
        margin: 0 0 16px 16px;
        font-size: 14px;
        border: 1px solid #DBDBDB;
        border-radius: 4px;
        padding: 10px 14px;
        color: #5c5c5c;
        background-color: #fff;
    }

    :deep(.appeal-dialog){
        border: solid 1px #d6d6d6;
        background-color: #f8f8f8;
        box-shadow: 0 3px 3px rgb(128 128 128 / 30%);
        width: 600px;
    }
    :deep(.appeal-dialog .appeal-item){
        width: 500px;
        overflow-y: auto;
    }
    .appeal-item .item{
        display: inline-block;
        margin: 0 0 16px 16px;
        font-size: 14px;
        border: 1px solid #DBDBDB;
        border-radius: 4px;
        padding: 10px 14px;
        color: #5c5c5c;
        background-color: #fff;
    }
    .issue-item .item-selected{
        background-color: var(--el-color-primary-light-5);
        color: #ffffff;
    }
    .appeal-item .item-selected{
        background-color: var(--el-color-primary-light-5);
        color: #ffffff;
    }
    li{
        list-style: none;
        border-top: 1px solid #d6d6d6;
        padding-left: 10px;
        text-align: left;
    }
    a{
        text-decoration: none;
    }
    .el-space .el-alert{
        align-items: flex-start;
    }
    .el-space .el-alert p{
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
</style>
