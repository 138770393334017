<template>
    <el-container class="right-side">
        <div class="tousu_btn" v-if="userInfo.category <= 1">
            <el-button @click="to_tousu"><el-icon size="20" style="margin-right: 8px;"><Edit/></el-icon>我要投诉</el-button>
        </div>
        <div class="tousu_btn" v-if="userInfo.category === 3">
            <el-button @click="to_work_order"><el-icon size="20" style="margin-right: 8px;"><Edit/></el-icon>提交工单</el-button>
        </div>
        <div class="tousu_list">
            <el-button @click="to_mytousu"><el-icon size="20" style="margin-right: 8px;"><List/></el-icon>我的投诉单</el-button>
        </div>
<!--        <div class="static-data">-->
<!--            <p class="item top">-->
<!--                <span class="s1">24小时有效投诉</span>-->
<!--                <span class="s2">{{count24h}}</span>-->
<!--            </p>-->
<!--            <p class="item">-->
<!--                <span class="s1">累计有效投诉</span>-->
<!--                <span class="s2">{{totalCount}}</span>-->
<!--            </p>-->
<!--        </div>-->
        <div class="bg-color"/>
        <div class="partner-list">
            <div class="partner">
                <h2>相关机构</h2>
                <ul>
                    <li v-for="item in partners" :key="item">
                        <a :href="item.url" target="_blank"><img :src="item.imgURL" :alt="item.title"></a>
                    </li>
                </ul>
            </div>
            <div class="partner">
                <h2>合作媒体</h2>
                <ul>
                    <li v-for="item in medias" :key="item">
                        <a :href="item.url" target="_blank"><img :src="item.imgURL" :alt="item.title"></a>
                    </li>
                </ul>
            </div>
            <div class="partner">
                <h2>合作律所</h2>
                <ul>
                    <li v-for="item in laws" :key="item">
                        <a :href="item.url" target="_blank"><img :src="item.imgURL" :alt="item.title"></a>
                    </li>
                </ul>
            </div>
        </div>
    </el-container>
    <el-dialog v-model="centerDialogVisible" class="xieyi" title=" 用户投诉须知 " width="70%" center>
<!--        <el-row center><h2>北京市互联网金融机构投诉平台用户协议</h2></el-row>-->
        <el-row :gutter='20'><h5>发布投诉前，请您仔细阅读以下内容，点击“同意”即视为接受以下条款。北京市互联网金融消费者投诉平台将保留追究法律责任的权利：</h5></el-row>
        <el-row :gutter='20'><h4>一、关于我们</h4></el-row>
        <el-row :gutter='20'><h5>北京市互联网金融消费者投诉平台（以下简称“本平台”）是北京市互联网金融行业协会下设金融消费者服务平台，主要受理互联网金融相关投诉。本平台旨在为金融消费者与金融机构搭建理性沟通、在线调解的平台。依靠平台不断扩充的企业会员库，金融消费者的诉求可以得到快速的反馈和专业的指导。</h5></el-row>
        <el-row :gutter='20'><h4>二、投诉须知</h4></el-row>
        <el-row :gutter='20'><h5>投诉人须了解并全部同意下列要求之后，才可以提交投诉：</h5></el-row>
        <el-row :gutter='20'><h5>1、为了建立公平健康的投诉环境，请依法、据实发布投诉内容，不要填写虚假投诉信息。不得捏造事实、诬陷诽谤他人、泄露他人隐私。</h5></el-row>
        <el-row :gutter='20'><h5>2、所有网络用户，不得复制、发布、传播含有下列内容的信息：危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一；煽动民族仇恨、民族歧视，破坏民族团结；宣扬邪教和封建迷信；散布谣言，扰乱社会秩序，破坏社会稳定；散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪；其他含有法律、行政法规禁止的内容。</h5></el-row>
        <el-row :gutter='20'><h5>3、本平台将携手各方尽可能的解决投诉问题。但平台无权强制被投诉方一定要处理您的投诉问题，且不能向您承诺一定可以解决您的投诉。</h5></el-row>
        <el-row :gutter='20'><h5>4、填写投诉信息时，请注意严谨措辞，不要填写不文明用语。用词不当平台可能不会通过审核。</h5></el-row>
        <el-row :gutter='20'><h5>5、请填写真实联系方式。便于被投诉方及时与您取得联系，平台及时向您告知投诉进展。</h5></el-row>
        <el-row :gutter='20'><h5>6、请完整地填写投诉信息，投诉内容力求详尽，并尽可能的提供有效证据；保证投诉内容的真实性，不捏造事实、制造假证、不泄露他人隐私；同时也请注意保护自己的隐私。</h5></el-row>
        <el-row :gutter='20'><h5>7、请勿重复发帖，有补充的投诉信息可在原投诉后面追加内容，本平台和被投诉方将及时跟进。</h5></el-row>
        <el-row :gutter='20'><h5>8、已经发布的投诉无法删除、撤销。投诉方不得利用“删除、撤销投诉”作为条件要求被投诉方解决投诉问题。被投诉方不得以“删除、撤销投诉”为前提条件解决投诉方所投诉问题。一经发现有类似行为，平台将对投诉双方做出严肃处理。</h5></el-row>
        <el-row :gutter='20'><h5>9、本平台保留删除恶意投诉的权利，并会将恶意投诉的用户拉入黑名单。</h5></el-row>
        <el-row :gutter='20'><h5>10、您可以通过北京市互联网金融行业协会官方网站（https://www.bjifia.com.cn）“投诉入口”来登录本平台查询您的投诉解决进度。（注意：被投诉机构在平台答复投诉处理结果后，请您7天内进行反馈。超过7天未有反馈，平台默认投诉人同意被投诉机构处理结果。）</h5></el-row>
        <el-row :gutter='20'><h5>11、为了更高效的解决投诉及提升消费者维权意识，投诉即视为授权本平台使用该投诉内容。</h5></el-row>
        <el-row :gutter='20'><h4>三、联系我们</h4></el-row>
        <el-row :gutter='20'><h5>投诉过程中如有任何问题，均可联系本平台：</h5></el-row>
        <el-row :gutter='20'><h5>1、热线电话：4006619609（工作日9:00-17:00）</h5></el-row>
        <el-row :gutter='20'><h5>2、电子邮箱：biz@bjifia.com.cn</h5></el-row>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="centerDialogVisible = false">不同意</el-button>
        <el-button type="primary" @click="toComplaint">同意</el-button>
      </span>
        </template>
    </el-dialog>
    <el-dialog v-model="loginDialogVisible1" title="登录" width="400" show-close="false" align-center @closed="clearLoginChildComponent('loginComponentRef1')">
        <pcLoginComponent ref="loginComponentRef1" @loginEnd="loginEnd" redirect="p_complaint" />
    </el-dialog>
    <el-dialog v-model="loginDialogVisible2" title="登录" width="400" show-close="false" align-center @closed="clearLoginChildComponent('loginComponentRef2')">
        <pcLoginComponent ref="loginComponentRef2" @loginEnd="loginEnd" redirect="p_myComplaint" />
    </el-dialog>
    <el-dialog v-model="loginDialogVisible3" title="登录" width="400" show-close="false" align-center @closed="clearLoginChildComponent('loginComponentRef3')">
        <pcLoginComponent ref="loginComponentRef3" @loginEnd="loginEnd" redirect="p_workOrder" />
    </el-dialog>
    <el-dialog v-model="importantTipsVisible" title="重要提示" width="400" show-close="false" align-center>
        登录失效，请点击"确定"按钮重新登录！
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="importantTipsVisible = false">取消</el-button>
            <el-button type="primary" @click="toNext">
              确定
            </el-button>
          </span>
        </template>
    </el-dialog>
</template>

<script>
    import webStorage from '@/utils/webStorage.js'
    import {ElMessage} from 'element-plus'
    import {partnerList} from '@/api/partner'
    import {staticDate} from "@/api/rightAside";
    import pcLoginComponent from "@/views/login/pcLoginComponent";
    export default {
        name: "RightSiler",
        components: {pcLoginComponent},
        data(){
            return{
                token: '',
                partners:[],
                medias:[],
                laws:[],
                count24h:0,
                totalCount:0,
                centerDialogVisible:false,
                userInfo:{
                    category:1,
                    status:0
                },
                loginDialogVisible1: false,
                loginDialogVisible2: false,
                loginDialogVisible3: false,
                importantTipsVisible: false,
                redirectPath: 'p_complaint',
            }
        },
        created(){
            let curUser = JSON.parse(webStorage.getItem('localStorage','user'));
            this.token = webStorage.getItem('localStorage','token');
            if(curUser){
                this.userInfo.category = curUser.category;
                this.userInfo.status = curUser.status;
            }
            window.addEventListener('setItem',()=>{
                this.token = webStorage.getItem('localStorage','token')
            });
            window.addEventListener('removeItem',()=>{
                let rmUser = JSON.parse(webStorage.getItem('localStorage','user'));
                if(!rmUser){
                    this.userInfo.category = 0;
                    this.token = '';
                }

            });
        },
        mounted(){
            this.partnerList();
            this.staticDate();
        },
        methods:{
            clearLoginChildComponent(varRef){
                this.$refs[varRef].$refs['ruleFormUPRef'].resetFields();
                this.$refs[varRef].$refs['ruleFormUPRef'].clearValidate();
                this.$refs[varRef].$refs['ruleFormMRef'].resetFields();
                this.$refs[varRef].$refs['ruleFormMRef'].clearValidate();
            },
            toNext(){
                this.importantTipsVisible = false;
                if(this.redirectPath ===''){
                    this.loginDialogVisible1 = true;
                }else{
                    this.loginDialogVisible2 = true;
                }
            },
            rmCache(){
                webStorage.removeItem('localStorage','user');
                webStorage.removeItem('localStorage','token');
                webStorage.removeItem('localStorage','username');
                webStorage.removeItem('localStorage','uCompany');
            },
            to_tousu(){
                if(this.userInfo.status === 3){
                    ElMessage({type:'warning',message:'您的帐号由于****已被禁言，不可以发起投诉！'});
                    return
                }
                if(this.token){
                    if(webStorage.getItem('localStorage','token')){
                        this.centerDialogVisible = true;
                    }else{
                        this.rmCache();
                        this.redirectPath = 'p_complaint';
                        this.importantTipsVisible = true;
                    }
                }else{
                    this.loginDialogVisible1 = true;
                }
                // if(webStorage.getItem('localStorage','token')){
                //     this.centerDialogVisible = true;
                // }else{
                //     this.redirectPath = '/p_complaint';
                //     this.importantTipsVisible = true;
                //     // this.loginDialogVisible1 = true;
                // }
            },
            to_work_order(){
                if(this.token){
                    if(webStorage.getItem('localStorage','token')){
                        this.$router.push("/p_workOrder")
                    }else{
                        this.rmCache();
                        this.redirectPath = 'p_workOrder';
                        this.importantTipsVisible = true;
                    }
                }else{
                    this.loginDialogVisible3 = true;
                }
            },
            toComplaint(){
                this.centerDialogVisible = false;
                this.$router.push("/p_complaint");
            },
            to_mytousu(){
                if(this.token){
                    if(webStorage.getItem('localStorage','token')){
                        this.$router.push("/p_myComplaint");
                    }else{
                        this.rmCache();
                        this.redirectPath = 'p_myComplaint';
                        this.importantTipsVisible = true;
                    }
                }else{
                    this.loginDialogVisible2 = true;
                }
            },
            staticDate(){
                staticDate().then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        this.count24h = res.data.data.count24h;
                        this.totalCount = res.data.data.totalCount;
                    }else{
                        ElMessage({message:res.data.message,type:'warning'})
                    }
                })
            },
            partnerList(){
                partnerList().then(res =>{
                    if(res.status === 200 && res.data.code === 200001){
                        let rdata = res.data.data
                        rdata.forEach(d =>{
                            if(d['category'] === 1){
                                this.partners = this.partners.concat(d)
                            }else if(d['category'] === 3){
                                this.medias = this.medias.concat(d)
                            }else{
                                this.laws = this.laws.concat(d)
                            }

                        })
                    }else{
                        ElMessage({message:res.data.message,type:'warning'})
                    }

                })
            },
            loginEnd(val){
                // console.log('redirect:'+val);
                if(val === 'p_complaint'){
                    this.loginDialogVisible1 = false;
                    this.centerDialogVisible = true;
                }else if(val === 'p_myComplaint'){
                    this.loginDialogVisible2 = false;
                    this.$router.push("/p_myComplaint");
                }else if(val == 'p_workOrder'){
                    this.loginDialogVisible3 = false;
                    this.$router.push("/p_workOrder");
                }
            },
        }
    }
</script>

<style scoped>
    .right-side{
        margin: 80px 0px;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
    }
    .right-side div{
        margin: 20px;
    }
    .right-side .tousu_btn{
        height: 60px;
    }

    .right-side .tousu_btn button{
        background: var(--el-color-primary);
        color: #ffffff;
        font-size: 25px;
        font-weight: bold;
    }
    .right-side .tousu_btn button:hover{
        background: var(--el-color-primary-dark-2);
        color: #03111f;
    }
    .right-side .tousu_list{
        height: 60px;
    }
    .right-side .tousu_list button{
        border: 1px solid var(--el-color-primary);
        font-size: 25px;
        font-width: bold;
        color: var(--el-color-primary);
    }
    .right-side div button {
        width: 100%;
        height: 100%;
    }
    .static-data{

        background: #212121;
        border-radius: 5px;
        height: 141px;
        display: grid;
    }
    .static-data p{
        display: block;
        margin-block-start: 0;
        margin-block-end: 0;
    }
    .static-data .top{
        border-bottom: 1px dashed #979797;
        margin-right: 20px;
    }
    .static-data .item{
        overflow: hidden;
        margin-left: 20px;
    }
    .static-data .item span{
        float: left;
    }
    .static-data .item .s1 {
        font-size: 16px;
        color: #D6D6D6;
        width: 120px;
        text-align: justify;
        line-height: 18px;
        margin: 26px 10px 8px 0;
    }
    .static-data .item .s2 {
        font-size: 30px;
        /*color: #fbaf17;*/
        color: var(--el-color-primary);
        line-height: 36px;
        margin-top: 17px;
    }
    .bg-color{
        /*width: 100%;*/
        margin: 0 !important;
        height: 10px;
        background-color: #f8f8f8;
    }
    .partner-list .partner:not(:last-child){
        border-bottom: 1px dashed #D6D6D6;
    }
    .partner-list{
        margin: 0!important;
        display: flex;
        flex-direction: column;
    }
    .partner-list .partner{
        margin: 0 20px;
        padding-bottom: 20px;
    }
    .partner-list .partner h2{
        font-size: 18px;
        color: #212121;
        line-height: 22px;
        padding-bottom: 18px;
    }
    .partner-list .partnerTopApp{
        border: 1px solid #D6D6D6;
        border-radius: 5px;
        overflow: hidden;
        padding: 5px;
        margin-bottom: 20px;
    }
    .partner-list .partnerTopApp img{
        width: 100%;
    }
    .partner-list .partner ul{
        display: block;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
    }
    .partner-list .partner ul li{
        list-style-type: none;
        border: 1px solid #D6D6D6;
        border-radius: 5px;
        float: left;
        width: 125px;
        height: 60px;
        margin: 5px 5px;
        display: flex;
        align-items: flex-end;
    }
    .partner-list .partner ul li a{
        width: 100%;
    }
    .partner-list .partner ul li img{
        width: 100%;
    }
    .xieyi .el-dialog__title{
        font-size: medium;
    }
    .xieyi .el-row h5{
        margin-block-start: 0;
        margin-block-end:1em;
    }
</style>
