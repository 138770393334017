import {createRouter,createWebHashHistory} from 'vue-router';
import m_index from "@/views/mobile/index/m_index";
import m_home from "@/views/mobile/components/m_home";
import m_login from "@/views/mobile/login/m_login";
import m_register from "@/views/mobile/register/m_register";
import m_user from "@/views/mobile/user/m_user";
import m_userInfo from "@/views/mobile/userInfo/m_userInfo";
import m_stepOne from "@/views/mobile/changeMobile/m_stepOne";
import m_stepTwo from "@/views/mobile/changeMobile/m_stepTwo";
import m_userComplaint from "@/views/mobile/complaint/m_userComplaint";
import m_complaintInfo from "@/views/mobile/complaint/m_complaintInfo";
import m_article from "@/views/mobile/article/m_article";
import m_articleShow from "@/views/mobile/article/m_articleShow";
import m_changePassword from "@/views/mobile/changePWD/m_changePassword";
import m_forgetPwd from "@/views/mobile/forgetPwd/m_forgetPwd";
import webStorage from "@/utils/webStorage";

const m_router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            title:"北互金投诉",
            name:"mobile",
            path: "/",
            component: m_home,
            meta: {type : 'mobile'},
            children:[
                {
                    name:"m_index",
                    path:'/m_index',
                    component: m_index,
                    meta: {type : 'mobile' }
                },
                {
                    name:"m_user",
                    path:"/m_user",
                    component: m_user,
                    meta: {type : 'mobile'}
                },
                {
                    name:"m_userInfo",
                    path:"/m_userInfo",
                    component: m_userInfo,
                    meta: {type : 'mobile'}
                },
                {
                    name:"m_stepOne",
                    path:"/m_stepOne",
                    component: m_stepOne,
                    meta: {type : 'mobile'}
                },
                {
                    name:"m_stepTwo",
                    path:"/m_stepTwo",
                    component: m_stepTwo,
                    meta: {type : 'mobile'}
                },
                {
                    name:'m_complaint',
                    path:'/m_complaint',
                    component: m_userComplaint,
                    meta: {type : 'mobile'}
                },
                {
                    name:'m_article',
                    path:'/m_article',
                    component: m_article,
                    meta: {type:'mobile'}
                },
                {
                    name: 'm_articleShow',
                    path:'/m_articleShow',
                    component: m_articleShow,
                    meta: {type:'mobile'}
                },{
                    name:'m_complaintInfo',
                    path:'/m_complaintInfo',
                    component: m_complaintInfo,
                    meta: {type: 'mobile'}
                },{
                    name:'m_changePassword',
                    path:'/m_changePassword',
                    component: m_changePassword,
                    meta: {type: 'mobile'}
                },
            ]
        },
        {
            title: "",
            name: "m_login",
            path: "/m_login",
            component: m_login,
            meta: { type: "mobile"},
        },
        {
            title: "",
            name: "m_register",
            path: "/m_register",
            component: m_register,
            meta: { type: "mobile"},
        },
        {
            title: "",
            name: "m_forgetPwd",
            path: "/m_forgetPwd",
            component: m_forgetPwd,
            meta: { type: "mobile"},
        }
    ]
});

m_router.beforeEach((to, from, next) => {
    if(/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(navigator.userAgent) && to.meta.type !=='mobile'){
        const routers = m_router.options.routes.filter(v => v.name === 'mobile')[0].children;
        const next_route = routers.filter(v => v.name.split('_')[1] === to.path.split('_')[1])[0];
        if(next_route){
            const path = next_route.path;
            if(path){
                next(path);
            }else{
                next('/m_index')
            }
        }else{
            next('/m_index')
        }
    }
    let needLogin = ['/m_complaint','/m_setting','/m_myComplaint','/m_user','/m_userInfo','/m_stepOne','/m_stepTwo']
    if (needLogin.findIndex((v)=>(v===to.path)) !== -1) {
        const token = webStorage.getItem('localStorage','token')
        if (token) {
            // 已登录，放行
            next()
        } else {
            next({path:'/m_login',query:{redirect:to.path}})
        }
    }else if(to.path === '/') {
        next('/m_index')
    }else{
        next()
    }

})
export default m_router
