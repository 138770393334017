<template>
    <div class="article-info">
        <div class="art-tit">
            <h1 class="title">{{article.title}}</h1>
            <h3 class="" v-if="article.short_desc">{{article.short_desc}}</h3>
            <div class="gmt">{{article.publish_date}}</div>
        </div>
        <div class="art-body">
            <div class="art-content" v-html="article.content"></div>
        </div>
    </div>
</template>

<script>
    import {articleShow} from '@/api/article'
    export default {
        name: "articleShow",
        data(){
            return{
                articleId: '',
                article:{
                    article_id:'',
                    title:'',
                    short_desc:'',
                    content:'',
                    cover:'',
                    media:'',
                    publish_date:'',
                    read_num:0,
                    vote_num:0,
                    comm_num:0,
                    share_num:0
                }
            }
        },
        created(){
            this.articleId = this.$route.query.articleId
            articleShow({'articleId':this.articleId}).then(res =>{

                let activeIndex = 'index'
                if(res.data.data.cate_id === 0){
                    activeIndex = 'hotNews'
                }else if(res.data.data.cate_id === 1){
                    activeIndex = 'industry'
                }else if(res.data.data.cate_id === 2){
                    activeIndex = 'investor'
                }
                let activeMenuEvent = document.createEvent('CustomEvent')
                activeMenuEvent.initCustomEvent('changeActiveMenu',false,false,activeIndex)
                window.dispatchEvent(activeMenuEvent)
                window.setInterval(() => {
                    this.article = res.data.data
                },100)
            })
        }
    }
</script>

<style scoped>
    .article-info{
        width: 700px;
        margin: 80px auto;
        padding: 10px 20px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .art-tit{
        width: 100%;
        border-bottom: 2px solid #d6d6d6;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .art-tit .title{
        font-size: 18px;
        color: #212121;
    }
    .art-tit .gmt{
        font-size: 12px;
        color: #212121;
        margin-bottom: 10px;
    }
    .article-info .art-body .art-content{
        padding: 30px 0 20px;
        max-width: 700px;
        /*background-color:#f3f3f3;*/
    }
    .article-info .art-body .art-content :deep(p){
        line-height: 30px;
        font-size: 16px;
        color: #4D4F53;
        text-indent: 2em;
        text-align: left;
        word-break: break-all;
        max-width: 700px;
        /*margin-bottom: 20px;*/
        /*padding-top: 20px;*/
        /*float: left;*/

    }
    .article-info .art-body .art-content :deep(img){
        margin: 30px auto;
        max-width: 630px;
        display: block;
    }
</style>
